
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import changeCase from 'change-case';

  @Component
  export default class InvestmentsTabs extends Vue {
    @Prop({ default: '' })
    uid!: string;

    @Prop({ default: [] })
    tabs!: { title: string, routeName: string, length?: number, badge?: boolean }[];

    /**
     * Get slug.
     */
    slug(text: string): string {
      return changeCase.paramCase(text);
    }

    /**
     * Return if tab is active.
     */
    isActive(tabRouteName: string): boolean {
      return this.routeName.startsWith(tabRouteName);
    }

    /**
     * Returns current path.
     */
    get routeName(): string {
      return this.$route.name || '';
    }
  }
