import { render, staticRenderFns } from "./Questionnaire.html?vue&type=template&id=32815b14&scoped=true&external"
import script from "./Questionnaire.ts?vue&type=script&lang=ts&external"
export * from "./Questionnaire.ts?vue&type=script&lang=ts&external"
import style0 from "./Questionnaire.scss?vue&type=style&index=0&id=32815b14&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32815b14",
  null
  
)

export default component.exports