var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"navs"}},[_vm._m(0),_vm._m(1),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Small nav tabs ")]),_vm._m(2),_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(4)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Horizontal overscroll ")]),_c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Creates a horizontally scrollable variation of the nav component. Items do not get stacked if they don't fit the viewport. Instead, they stay on the same line and become scrollable in the x axes. ")]),_c('div',{staticClass:"card"},[_vm._m(5),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header mt-md-6"},[_c('div',{staticClass:"header-body"},[_c('h1',{staticClass:"header-title"},[_vm._v(" Navs ")]),_c('p',{staticClass:"header-subtitle"},[_vm._v(" Please read the "),_c('a',{attrs:{"target":"_blank","href":"http://getbootstrap.com/docs/4.1/components/navs/"}},[_vm._v("official Bootstrap documentation")]),_vm._v(" for a full list of options. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"nav nav-tabs mb-4"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"#!"}},[_vm._v("Active")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v("Link")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v("Link")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link disabled",attrs:{"href":"#!"}},[_vm._v("Disabled")])])]),_c('ul',{staticClass:"nav nav-pills"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"#!"}},[_vm._v("Active")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v("Link")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v("Link")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link disabled",attrs:{"href":"#!"}},[_vm._v("Disabled")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Creates a small variation of the nav component – this is especially useful for putting in "),_c('code',[_vm._v(".card-header")]),_vm._v("s to act as tabs. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"nav nav-tabs nav-tabs-sm"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"#!"}},[_vm._v(" Active ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v(" Link ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v(" Link ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <ul class=\"nav nav-tabs nav-tabs-sm\">"),_c('br'),_vm._v("   <li class=\"nav-item\">"),_c('br'),_vm._v("     <a href=\"#\" class=\"nav-link active\">"),_c('br'),_vm._v("       Active"),_c('br'),_vm._v("     </a>"),_c('br'),_vm._v("   </li>"),_c('br'),_vm._v("   <li class=\"nav-item\">"),_c('br'),_vm._v("     <a href=\"#\" class=\"nav-link\">"),_c('br'),_vm._v("       Link"),_c('br'),_vm._v("     </a>"),_c('br'),_vm._v("   </li>"),_c('br'),_vm._v("   <li class=\"nav-item\">"),_c('br'),_vm._v("     <a href=\"#\" class=\"nav-link\">"),_c('br'),_vm._v("       Link"),_c('br'),_vm._v("     </a>"),_c('br'),_vm._v("   </li>"),_c('br'),_vm._v(" </ul> ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('ul',{staticClass:"nav nav-tabs nav-tabs-sm nav-overflow"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"href":"#!"}},[_vm._v(" Active ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v(" Link ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v(" Link ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <ul class=\"nav nav-tabs nav-tabs-sm nav-overflow\">"),_c('br'),_vm._v("   <li class=\"nav-item\">"),_c('br'),_vm._v("     <a href=\"#\" class=\"nav-link active\">"),_c('br'),_vm._v("       Active"),_c('br'),_vm._v("     </a>"),_c('br'),_vm._v("   </li>"),_c('br'),_vm._v("   <li class=\"nav-item\">"),_c('br'),_vm._v("     <a href=\"#\" class=\"nav-link\">"),_c('br'),_vm._v("       Link"),_c('br'),_vm._v("     </a>"),_c('br'),_vm._v("   </li>"),_c('br'),_vm._v("   <li class=\"nav-item\">"),_c('br'),_vm._v("     <a href=\"#\" class=\"nav-link\">"),_c('br'),_vm._v("       Link"),_c('br'),_vm._v("     </a>"),_c('br'),_vm._v("   </li>"),_c('br'),_vm._v(" </ul> ")])
}]

export { render, staticRenderFns }