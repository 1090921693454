
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Action } from 'vuex-class';
  import Modal from '@/components/common/Modal.vue';

  @Component({
    components: {
      Modal,
    },
  })
  export default class ChangesRequestedModal extends Vue {
    @Prop() userId!: string;
    @Prop() amountChangeRequests!: number;
  }
