
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import to from 'await-to-js';
  import axios from 'axios';
  // @ts-ignore
  import VueUploadMultipleImage from 'vue-upload-multiple-image';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { ModelSelect } from 'vue-search-select';
  import ShortUniqueId from 'short-unique-id';
  import { bloqifyFirestore, bloqifyStorage } from '@/boot/firebase';
  import { convertUTCToLocalDate, convertLocalDateToUTC } from '@/filters/date';
  import { Asset, AssetType } from '@/models/assets/Asset';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormSelect from '@/components/common/form-elements/FormSelect.vue';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import FormEditor from '@/components/common/form-elements/FormEditor.vue';
  import singleDocumentQuery from '@/mixins/singleDocumentQuery';
  import { PayNLKMSActions, RetrieveRequest, UpdateRequest } from '@/models/paynl';
  import { Vertebra } from '@/store/utils/skeleton';

  type SelectOptions = { value: string, text: string };
  type FileNames = 'images' | 'floorPlanImages';
  type ProjectName = { en: string, nl: string };

  @Component({
    components: {
      VueUploadMultipleImage,
      FormDatePicker,
      FormEditor,
      FormInput,
      FormSelect,
      ValidationObserver,
      ValidationProvider,
      FormInvalidMessage,
      ModelSelect,
    },
    mixins: [
      singleDocumentQuery({
        ref: bloqifyFirestore.collection('assets'),
        stateSlice: 'boundAsset',
        idName: 'assetId',
      }),
    ],
  })
  export default class CreateAssets extends Vue {
    FormIcons = FormIcons;

    name: ProjectName = {
      nl: '',
      en: '',
    };
    street: string = '';
    houseNumber: string = '';
    postalCode: string = '';
    city: string = '';
    country: string = '';
    investmentCase: any = '';
    propertyDetails: any = '';
    totalValueEuro: number | null = null;
    euroMin: number | null = null;
    sharePrice: number | null = null;
    emissionCost: number | null = null;
    premium: boolean = false;
    reservation: boolean = false;
    published: boolean = false;
    preInvestment: boolean = false;
    dividendsFormat: { contents: [string, number | null | string] }[] = [{ contents: ['', null] }];
    images: any[] = [];
    floorPlanImages: any[] = [];
    prospectus: any[] = [];
    brochure: any[] = [];
    location: { lat: number, lng: number } = { lat: 0, lng: 0 };
    mapOptions = {
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      disableDefaultUi: true,
    };
    imagesLoading = {
      images: false,
      floorPlanImages: false,
    };
    subscriptionDiscountTable: { sharesAmount: string, sharePrice: string }[] = [];
    startDateTime: Date | null = null;
    endDateTime: Date | null = null;
    returnsAfterEnd: number | null = null;
    fixedDividends: boolean | null = null;
    otherChanges: boolean = false;
    saveButtonTitle: string = '';
    assetTypes = [
      { value: AssetType.Gras, text: 'Gras' },
      { value: AssetType.Tree, text: 'Tree' },
      { value: AssetType.Bamboo, text: 'Bamboo' },
    ];

    selectedAssetType: SelectOptions = {
      value: this.assetTypes[0].value,
      text: this.assetTypes[0].text,
    };

    assetType: string = '';

    checkoutDescription = {
      nl: '',
      en: '',
    };

    checkoutPaymentOptions = {
      subscription: true,
      oneOff: true,
      gift: true,
    };

    checkoutLinks = {
      termsAndConditions: '',
      bondConditions: '',
      avgPolicy: '',
    };

    activeCampaignTriggerId = '';
    activeCampaignSubsTriggerId = '';
    activeCampaignGiftTriggerId = '';
    activeCampaignRedeemTriggerId = '';

    maxSharesPerInvestorEnabled: boolean = false;
    maxSharesPerInvestor: number | null = null;
    duration: number | null = null;
    interestRate: number | null = null;
    co2Compensation: number | null = null;

    apiToken = '';
    salesLocation = '';
    secret = '';
    debitApiToken = '';
    debitSecret = '';
    apiTokenHidden = true;
    salesLocationHidden = true;
    secretHidden = true;
    debitApiTokenHidden = true;
    debitSecretHidden = true;
    apiKeysRequestsQueue = {
      generic: new Set<string>(),
      salesLocation: new Set<string>(),
      apiToken: new Set<string>(),
      secret: new Set<string>(),
      debitApiToken: new Set<string>(),
      debitSecret: new Set<string>(),
    };

    @Action createAsset!: Function;
    @Action updateAsset!: Function;
    @Action handlePublishAssetById!: Function;
    @Action handlePreInvestmentAssetById!: Function;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action getPayNLKeys!: (retrieveRequest: RetrieveRequest) => Promise<void>;
    @Action setPayNLKeys!: (updateRequest: UpdateRequest) => Promise<void>;

    @StateClass('boundAsset') asset!: State['boundAsset'];
    @StateClass('asset') operationalAsset!: State['asset'];
    @StateClass('payNLKMS') payNLKMS!: State['payNLKMS'];

    @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

    @Watch('operationalAsset.error')
    onNewAssetError(newError?: Error): void {
      if (newError) {
        this.addToastMessage({
          text: newError.message,
          type: 'danger',
        });
      }
    }

    @Watch('operationalAsset.status')
    async onOperationalAssetStatusChange(newStatus: DataContainerStatus): Promise<void> {
      if ((this.operationalAsset?.operation === 'createAsset' || this.operationalAsset?.operation === 'updateAsset')
        && newStatus === DataContainerStatus.Success) {
        // Redirect if we were in create page
        if (this.$route.fullPath !== `/create-modify-asset/${this.operationalAsset.payload.id}`) {
          this.$router.push(`/create-modify-asset/${this.operationalAsset.payload.id}`);
        }

        this.addToastMessage({
          text: 'Fund correctly saved.',
          type: 'success',
        });
        // Resetting form validation
        this.otherChanges = false;
        this.form.reset();
      }
    }

    @Watch('payNLKMS', { immediate: true, deep: true })
    onNewPayNLKMS(newPayNLKMS: Vertebra): void {
      if (newPayNLKMS && newPayNLKMS.status !== DataContainerStatus.Initial) {
        // Format of the hash: operationName!-idempotentKey!-inputId?
        const hash = newPayNLKMS.operation!.slice(newPayNLKMS.operation!.indexOf('-') + 1);
        // If there are more than 1 '-' in the operation, it means that it comes from an input
        const comesFromInput = (hash.match(new RegExp('-', 'g')) || []).length > 0;
        // Pick the idempotent key from the hash
        const idempotentKey = hash.slice(0, comesFromInput ? hash.indexOf('-') : hash.length);
        // Pick the input id from the hash
        const inputId = comesFromInput ? hash.slice(hash.indexOf('-') + 1) : null;
        // Check if the operation is a get or a set
        const isGetOperation = newPayNLKMS.operation!.startsWith('getPayNLKeys');

        // Avoid any logic if the operation is not the one we are waiting for
        // In a practical example: going to another page while the request is still pending (or not)
        if (!this.apiKeysRequestsQueue[inputId || 'generic'].has(idempotentKey)) {
          return;
        }

        const removeElementFromQueue = (): void => {
          // Remove the element from the queue
          // Workaround to fix Vue's lack of reactivity on nested objects (Set in this case)
          const tempSet = new Set(this.apiKeysRequestsQueue[inputId || 'generic']);

          tempSet.delete(idempotentKey);
          this.apiKeysRequestsQueue = {
            ...this.apiKeysRequestsQueue,
            [inputId || 'generic']: tempSet,
          };
        };

        if (!isGetOperation) {
          if (newPayNLKMS.status === DataContainerStatus.Success) {
            this.addToastMessage({
              text: 'API key correctly set.',
              type: 'success',
            });
            removeElementFromQueue();
            return;
          }

          if (newPayNLKMS.status === DataContainerStatus.Error) {
            this.addToastMessage({
              text: 'Could not set API key.',
              type: 'danger',
            });
            removeElementFromQueue();
            return;
          }
        }

        if (isGetOperation) {
          if (newPayNLKMS.status === DataContainerStatus.Error) {
            this.addToastMessage({
              text: 'Could not retrieve API keys data.',
              type: 'danger',
            });
            removeElementFromQueue();
            return;
          }

          if (newPayNLKMS.status === DataContainerStatus.Success) {
            const { payload } = newPayNLKMS;
            const {
              apiToken,
              salesLocation,
              secret,
              debitApiToken,
              debitSecret,
            } = payload;

            this.apiToken = apiToken;
            this.salesLocation = salesLocation;
            this.secret = secret;
            this.debitApiToken = debitApiToken;
            this.debitSecret = debitSecret;

            removeElementFromQueue();
          }
        }
      }
    }

    @Watch('asset')
    async onAssetStatusChange(newAsset: Asset): Promise<void> {
      if (newAsset) {
        // PayNL API KEYS LOGIC
        this.handlePayNLKeysFromView(
          {
            assetId: newAsset.id!,
            action: PayNLKMSActions.Retrieve,
            options: {
              apiToken: true,
              salesLocation: true,
              secret: true,
              ...newAsset.checkoutPaymentOptions.subscription && { debitApiToken: true, debitSecret: true },
            },
          },
        );
        // End PayNL API KEYS LOGIC

        const imgArrayNames = ['images', 'floorPlanImages'];
        const fileArrayNames = ['prospectus', 'brochure'];
        const allArrayNames = [...imgArrayNames, ...fileArrayNames];

        const asset = newAsset;

        // Here we are setting the received data into the form fields
        Object.keys(asset).forEach((key): void => {
          if (!allArrayNames.some((arrayName): boolean => arrayName === key)) {
            // Assigining dynamically all the asset properties to the form
            if (key !== 'investmentCase' && key !== 'propertyDetails' && key !== 'dividendsFormat'
              && key !== 'startDateTime' && key !== 'endDateTime' && key !== 'type') {
              this[key] = asset[key];
            }
          } else {
            // Resetting file arrays
            this[key] = [];
          }
        });

        ['startDateTime', 'endDateTime'].forEach((dateKey): void => {
          // From unix format to Date format
          this[dateKey] = (asset[dateKey] && convertUTCToLocalDate(asset[dateKey])) || null;
        });

        // To avoid mutations at editing
        this.dividendsFormat = [
          ...asset.dividendsFormat.map(
            (contentObject): {
              contents: [string, number];
            } => ({ ...contentObject }),
          ),
        ];

        // Editor content
        this.investmentCase = asset.investmentCase;
        this.propertyDetails = asset.propertyDetails;
        this.assetType = asset.type;

        if (asset.maxSharesPerInvestor) {
          this.maxSharesPerInvestorEnabled = true;
        }

        // Images
        imgArrayNames.forEach(async (key): Promise<void> => {
          let imgLoadedCount = 0;

          await Promise.all(asset[key].map(async (img): Promise<void> => {
            // Loader ON
            this.imagesLoading[key] = true;

            const storageRef = bloqifyStorage.ref().child(img);
            const [getDownloadUrlError, fileUrl] = await to(storageRef.getDownloadURL());
            if (getDownloadUrlError) {
              this.addToastMessage({
                text: getDownloadUrlError.message || 'There was an error retrieving one of images.',
                type: 'danger',
              });
              throw getDownloadUrlError;
            }

            const [getMetadataError, metadata] = await to(storageRef.getMetadata());
            if (getMetadataError) {
              this.addToastMessage({
                text: getMetadataError.message || 'There was an error retrieving one of images.',
                type: 'danger',
              });
              throw getMetadataError;
            }

            const { contentType, name: fileName } = metadata;

            const [getFileError, response] = await to(axios.get(
              fileUrl,
              {
                responseType: 'arraybuffer',
              },
            ));
            if (getFileError) {
              this.addToastMessage({
                text: getFileError.message || 'There was an error retrieving one of images.',
                type: 'danger',
              });
              throw getFileError;
            }

            const responseBlob = response!.data as Blob;
            const reader = new FileReader();

            reader.readAsDataURL(new Blob([responseBlob], { type: contentType }));
            reader.onload = (e) => {
              const length = this[key].length;
              // This object type is the one required by the component used for images
              this[key].push({
                name: fileName,
                path: reader.result,
                highlight: !length ? 1 : 0,
                default: !length ? 1 : 0,
                file: new File([responseBlob], fileName, { type: contentType }),
              });

              imgLoadedCount++;

              if (imgLoadedCount === asset[key].length) {
                // Loader OFF
                this.imagesLoading[key] = false;
              }
            };
          }));
        });

        // Files (pdfs)
        fileArrayNames.forEach(async (key): Promise<void> => {
          await Promise.all(asset[key].map(async (pdf): Promise<void> => {
            const contentType = 'application/pdf';
            const storageRef = bloqifyStorage.ref().child(pdf);
            const [getError, fileUrl] = await to(storageRef.getDownloadURL());
            if (getError) {
              this.addToastMessage({
                text: getError.message || 'There was an error retrieving one of docs.',
                type: 'danger',
              });
              throw getError;
            }

            const [getMetadataError, metadata] = await to(storageRef.getMetadata());
            if (getMetadataError) {
              this.addToastMessage({
                text: getMetadataError.message || 'There was an error retrieving one of docs.',
                type: 'danger',
              });
              throw getMetadataError;
            }

            const { name: fileName } = metadata;

            const [getFileError, response] = await to(axios.get(
              fileUrl,
              {
                responseType: 'arraybuffer',
              },
            ));
            if (getFileError) {
              this.addToastMessage({
                text: getFileError.message || 'There was an error retrieving one of docs.',
                type: 'danger',
              });
              throw getFileError;
            }

            this[key].push({ name: fileName, file: new File([response!.data], fileName, { type: contentType }) });
          }));
        });
      }
    }

    @Watch('addressBuilt')
    async onAddressChange(newAddress: string, oldAddress: string): Promise<void> {
      if (this.city && this.city.length > 2) {
        // @ts-ignore
        const [mapsApiError, mapsApi] = await to(this.$gmapApiPromiseLazy());
        if (mapsApiError) {
          return;
        }
        // @ts-ignore
        const geocoder = new mapsApi.maps.Geocoder();
        geocoder.geocode({ address: newAddress }, (results, status): void => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            this.location = {
              lat: location.lat instanceof Function ? location.lat() : location.lat,
              lng: location.lng instanceof Function ? location.lng() : location.lng,
            };
          }
        });
      }
    }

    @Watch('premium') onPremiumChange(newPremium: boolean) {
      // reset dividend data
      this.dividendsFormat = [{ contents: ['', 0] }];
      this.returnsAfterEnd = null;
      this.fixedDividends = null;
    }

    get loadingAsset(): boolean {
      return this.operationalAsset?.status === DataContainerStatus.Processing;
    }

    get loadingPayNLKMS(): boolean {
      return this.payNLKMS?.status === DataContainerStatus.Processing;
    }

    get URL(): Function {
      // @ts-ignore
      return window.URL || window.webkitURL;
    }

    get addressBuilt(): string {
      return `${this.street} ${this.houseNumber} ${this.city} ${this.country}`;
    }

    get assetId(): string | undefined {
      return this.$route.params.assetId;
    }

    get lastUpdate(): number | null {
      if (this.assetId && this.asset) {
        const timestamp = convertUTCToLocalDate(this.asset.updatedDateTime || this.asset.createdDateTime)!;
        return timestamp.getTime();
      }

      return null;
    }

    // Handle save button loading spinner
    get saveLoading(): boolean {
      if (!this.loadingAsset) {
        return false;
      }

      return (this.operationalAsset?.operation === 'updateAsset' || this.operationalAsset?.operation === 'createAsset');
    }

    get publishing(): boolean {
      return this.operationalAsset?.operation === 'handlePublishAssetById' && this.operationalAsset.status === DataContainerStatus.Processing;
    }

    get togglingPreInvestment(): boolean {
      return this.operationalAsset?.operation === 'handlePreInvestmentAssetById' && this.operationalAsset.status === DataContainerStatus.Processing;
    }

    /**
     * Calls the specfic PayNL endpoint depending on the action.
     * It also handles the queue so that a new idempotent action is added to it.
     */
    handlePayNLKeysFromView(requestOptions: RetrieveRequest | UpdateRequest, inputId?: string): void {
      // @ts-ignore
      const uniqueId = (new ShortUniqueId()).randomUUID();

      // Workaround to fix Vue's lack of reactivity on nested objects (Set in this case)
      this.apiKeysRequestsQueue = {
        ...this.apiKeysRequestsQueue,
        [inputId || 'generic']: this.apiKeysRequestsQueue[inputId || 'generic'].add(uniqueId),
      };

      const finalRequestObject = {
        ...requestOptions,
        idempotentKey: `${uniqueId}${(inputId && `-${inputId}`) || ''}`,
      };

      if (finalRequestObject.action === PayNLKMSActions.Retrieve) {
        this.getPayNLKeys(finalRequestObject);
      } else {
        this.setPayNLKeys(finalRequestObject);
      }
    }

    /*
    * Checks if the user should be able to save the draft, that requires
    * the name to be defined
    * all errors present should only be due to them missing (i.e. in violation of being required)
    * if it is already published there can't be any invalid entries
    * */
    isAbleToSaveDraft(): boolean {
      if (this.name.en.length === 0 || this.name.nl.length === 0) {
        this.saveButtonTitle = 'Enter a fund name to save draft';
        return false;
      }

      // Make reservation type asset publishable with only a name
      if (this.reservation) {
        return true;
      }

      if (!this.form) {
        return false;
      }

      if (this.published) {
        return this.form.flags.valid;
      }

      if (this.form.errors) {
        const isOnlyRequiredViolated = Object.values(this.form.fields).every(
          (field) => {
            if (field.failed) {
              return Object.keys(field.failedRules).every(((failedRuleKey) => failedRuleKey === 'required'));
            }
            return true;
          },
        );
        this.saveButtonTitle = isOnlyRequiredViolated ? '' : 'Invalid input';
        return isOnlyRequiredViolated;
      }
      return true;
    }

    inputFilter(newFile, oldFile, prevent): void {
      // Preventing a non-pdf file to be inserted
      if (newFile && !oldFile && !/\.(pdf)$/i.test(newFile.name)) {
        this.addToastMessage({
          text: 'Only pdf files allowed.',
          type: 'danger',
        });
        prevent();
      } else {
        this.otherChanges = true;
      }
    }

    removeFile(position: number, fileType: string) {
      this[fileType].splice(position, 1);
      this.otherChanges = true;
    }

    changeDividend(event, type, index): void {
      const targetValue = event;
      const year = this.dividendsFormat[index].contents[0];
      const dividends = this.dividendsFormat[index].contents[1];
      if (type === 'year') {
        this.dividendsFormat[index].contents = [targetValue, dividends];
      } else {
        this.dividendsFormat[index].contents = [year, targetValue];
      }
    }

    removeDividendsRow(row: number): void {
      this.dividendsFormat.splice(row, 1);
      this.otherChanges = true;
    }

    addDividendsRow(): void {
      this.dividendsFormat.push({ contents: ['', 0] });
    }

    removeDiscountRow(row: number): void {
      this.subscriptionDiscountTable.splice(row, 1);
      this.otherChanges = true;
    }

    addDiscountRow(): void {
      this.subscriptionDiscountTable.push({ sharePrice: '', sharesAmount: '' });
    }

    markPrimary(index: number, fileList: any[], type: FileNames): void {
      this[type] = fileList;
      this.otherChanges = true;
    }

    uploadOrEditImage(formData: FormData, index: number, fileList: any[], type: FileNames): void {
      const file = formData.get('file') as File;
      if (file) {
        // Limiting size by 10MB
        if (file.size < 10000000) {
          this[type] = fileList;
          this[type][index].file = formData.get('file');
          this.otherChanges = true;
        } else {
          fileList.pop();
        }
      }
    }

    beforeRemove(index: number, removeFn: Function, fileList: any[], type: FileNames): void {
      removeFn();
      this[type].splice(index, 1);
      this.otherChanges = true;
    }

    async submitAsset(): Promise<void> {
      await this.form.validate();
      if (this.isAbleToSaveDraft()) {
        const formattedDividendsFormat = this.dividendsFormat.map((div) => ({
          contents: [
            div.contents[0],
            Number(div.contents[1]),
          ],
        }));

        const formAsset = {
          ...this.assetId && { id: this.assetId },
          name: { ...Object.entries(this.name as ProjectName)
          .reduce((accum, [lang, name]): ProjectName => { accum[lang] = name.trim(); return accum; }, {} as ProjectName) },
          street: this.street.trim(),
          houseNumber: this.houseNumber.trim(),
          postalCode: this.postalCode.trim(),
          city: this.city.trim(),
          country: this.country.trim(),
          dividendsFormat: formattedDividendsFormat,
          investmentCase: this.investmentCase,
          propertyDetails: this.propertyDetails,
          checkoutDescription: this.checkoutDescription,
          checkoutPaymentOptions: this.checkoutPaymentOptions,
          checkoutLinks: this.checkoutLinks,
          totalValueEuro: Number(this.totalValueEuro),
          euroMin: Number(this.euroMin),
          sharePrice: Number(this.sharePrice),
          emissionCost: Number(this.emissionCost),
          premium: this.premium,
          reservation: this.reservation,
          published: this.published,
          preInvestment: this.preInvestment,
          images: this.images.map((item): any => item.file),
          floorPlanImages: this.floorPlanImages.map((item): any => item.file),
          prospectus: this.prospectus.map((item): any => item.file),
          brochure: this.brochure.map((item): any => item.file),
          startDateTime: this.startDateTime && convertLocalDateToUTC(this.startDateTime, true)?.getTime(),
          endDateTime: this.endDateTime && convertLocalDateToUTC(this.endDateTime, true)?.getTime(),
          returnsAfterEnd: Number(this.returnsAfterEnd),
          maxSharesPerInvestor: this.maxSharesPerInvestorEnabled ? Number(this.maxSharesPerInvestor) : null,
          duration: Number(this.duration),
          interestRate: Number(this.interestRate),
          co2Compensation: Number(this.co2Compensation),
          type: this.assetType.trim().toLowerCase(),
          activeCampaignTriggerId: this.activeCampaignTriggerId,
          activeCampaignSubsTriggerId: this.activeCampaignSubsTriggerId,
          activeCampaignGiftTriggerId: this.activeCampaignGiftTriggerId,
          activeCampaignRedeemTriggerId: this.activeCampaignRedeemTriggerId,
          subscriptionDiscountTable: this.subscriptionDiscountTable.map((discount): Asset['subscriptionDiscountTable'][0] => ({
            sharesAmount: Number(discount.sharesAmount),
            sharePrice: Number(discount.sharePrice),
          })),
          ...(this.fixedDividends !== null && { fixedDividends: this.fixedDividends }),
        };

        if (!this.assetId) {
          this.createAsset({ asset: formAsset });
        } else {
          this.updateAsset({ asset: formAsset });
        }
      }
    }

    async publish(): Promise<void> {
      if (!this.assetId) {
        this.addToastMessage({
          text: 'Please, save the asset first.',
          type: 'danger',
        });

        // Setting back to false with some delay due to the visual effect not being applied if insta change twice
        setTimeout(() => {
          this.published = false;
        }, 200);
        return;
      }
      if (this.published) {
        this.addToastMessage({
          text: 'The asset was published!',
          type: 'success',
        });
      } else {
        this.addToastMessage({
          text: 'The asset was unpublished!',
          type: 'success',
        });
      }

      this.handlePublishAssetById({ assetId: this.assetId, published: this.published });
    }

    async togglePreInvestment(): Promise<void> {
      if (!this.assetId) {
        this.addToastMessage({
          text: 'Please, save the asset first.',
          type: 'danger',
        });

        // Setting back to false with some delay due to the visual effect not being applied if insta change twice
        setTimeout(() => {
          this.preInvestment = false;
        }, 200);
        return;
      }
      if (this.preInvestment) {
        this.addToastMessage({
          text: 'The asset was marked as pre-investment!',
          type: 'success',
        });
      } else {
        this.addToastMessage({
          text: 'The asset was unmarked as pre-investment!',
          type: 'success',
        });
      }

      this.handlePreInvestmentAssetById({ assetId: this.assetId, preInvestment: this.preInvestment });
    }
  }
