
  import { Component, Vue } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';
  import changeCase from 'change-case';
  import Tabs from '@/components/common/Tabs.vue';
  import { CurrentManager } from '@/models/manager/CurrentManager';
  import { ManagerRole } from '@/models/manager/Manager';

  @Component({
    components: {
      Tabs,
    },
  })

  export default class Settings extends Vue {
    @Getter getCurrentManagerRole!: ManagerRole;
    @Getter getQuestionnaireUsed!: boolean;

    /**
     * Get slug.
     *
     * @param text
     */
    slug(text: string): string {
      return changeCase.paramCase(text);
    }

    get tabs(): {title: string, routeName: string}[] {
      const defaultTabs = [
        { title: 'General', routeName: 'settings-general' },
        { title: 'Security', routeName: 'settings-security' },
        { title: 'Messaging', routeName: 'settings-messaging' },
        ...this.getQuestionnaireUsed ? [{ title: 'Questionnaire', routeName: 'settings-questionnaire' }] : [],
      ];

      if (this.isUserSuperAdmin) {
        defaultTabs.push(
          { title: 'Restore Backup', routeName: 'settings-restore-backup' },
        );
      }

      return defaultTabs;
    }

    get isUserSuperAdmin(): boolean {
      if (this.getCurrentManagerRole) {
        return this.getCurrentManagerRole === ManagerRole.Superadmin;
      }
      return false;
    }
  }
