
  import Chart from 'chart.js';
  import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
  import { Line } from 'vue-chartjs';

  @Component
  export default class LineChart extends Mixins(Line) {
    @Prop({ default: () => {} })
    data!: Chart.ChartData

    @Prop({ default: () => {} })
    options!: Chart.ChartOptions

    mounted(): void {
      this.renderChart(this.data, this.options);

      // @ts-ignore
      this.$refs.canvas.className = `${this.$refs.canvas.className} chart-canvas`;
    }

    @Watch('data')
    onNewData(newData: Chart.ChartData): void {
      this.renderChart(this.data, this.options);
    }

    @Watch('options')
    onNewOptions(newOptions: Chart.ChartOptions): void {
      this.renderChart(this.data, this.options);
    }
  }
