import { Module } from 'vuex';
import to from 'await-to-js';
import ShortUniqueId from 'short-unique-id';
import { times } from 'lodash';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase, bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { Investor } from '@/models/users/User';
import { PaymentStatus, PaymentProvider, Investment, Payment, PaymentType } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import { assetChecks } from './asset';
import { ceilNumber } from '../utils/numbers';
import { multipleTransactionAction } from '../utils/firebase';

const SET_PAYMENT = 'SET_PAYMENT';

// Payload types
export type CreatePaymentPayload = {
  /** € */
  amount: number,
  assetId: string,
  investorId: string,
  paymentDateTime: number,
  endDateTime?: number,
  type?: PaymentType,
  insertedSharesAmount: number,
}

export type UpdatePaymentPayload = CreatePaymentPayload & {
  investmentId: string,
  paymentId: string,
  insertedSharesAmount: number,
}

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_PAYMENT](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    /**
     * Refund makes a payment status change to refunded but the trees are softly deleted.
     */
    async refundPayment(
      { commit, dispatch },
      { investmentId, paymentId, paynlTransactionId, assetId }: { investmentId, paymentId: string, paynlTransactionId: string, assetId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'refundPayment' });

      // Call the refund payment function
      const [subscriptionPaynlError] = await to(
        bloqifyFunctions.httpsCallable('refundPaynlTransaction')({
          orderId: paynlTransactionId,
          assetId,
        }),
      );
      if (subscriptionPaynlError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: subscriptionPaynlError, operation: 'refundPayment' });
      }

      // Reuse the delete payment action with refund = true
      const [deletePaymentError] = await to(dispatch('deletePayment', { investmentId, paymentId, refund: true }));
      if (deletePaymentError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: deletePaymentError, operation: 'refundPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'refundPayment' });
    },
    async createPayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        insertedSharesAmount,
        type,
      }: CreatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'createPayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          // Collection references
          const insertedAssetId = bloqifyFirestore.collection('assets').doc(assetId);
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          let investmentRef = investmentsRef.doc();
          let paymentRef = investmentRef.collection('payments').doc();

          const [readAssetError, readAssetSuccess] = await to(transaction.get(insertedAssetId));
          if (readAssetError || !readAssetSuccess?.exists) {
            throw readAssetError || Error('Asset not found.');
          }

          const asset = readAssetSuccess!.data() as Asset;

          if (!assetChecks(asset)) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }

          const investor = readInvestorSuccess!.data() as Investor;

          const {
            sharesAvailable, emissionCost, startDateTime,
          } = asset;

          if (insertedSharesAmount > sharesAvailable) {
            throw Error('The asset does not have this many available shares.');
          }

          const [investmentsError, investments] = await to(
            bloqifyFunctions.httpsCallable('getInvestments')({
              investorId,
              assetId,
            }),
          );
          if (investmentsError) {
            throw investmentsError;
          }

          const { found: foundInvestments, investmentId } = investments!.data;
          let firstInvestment = false;

          const [error] = await to(
            bloqifyFunctions.httpsCallable('logData')({
              source: 'createPayment',
              data: {
                amount,
                assetId,
                investorId,
                paymentDateTime,
                insertedSharesAmount,
                type,
                foundInvestments,
              },
             }),
          );

          if (foundInvestments) {
            investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
            const [getPaymentsError, getPaymentsSuccess] = await to(
              investmentRef.collection('payments').get(),
            );
            if (getPaymentsError) {
              throw getPaymentsError;
            }
            if (getPaymentsSuccess!.empty) {
              throw Error('There was an error retrieving previous investments information.');
            }
            firstInvestment = !getPaymentsSuccess!.docs.some((snap): boolean => snap.get('providerData.status') === PaymentStatus.Paid);

            // Reset paymentRef
            paymentRef = investmentRef.collection('payments').doc();
          }

          if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime) < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          // Adding the emission cost on top of the euro amount
          const amountWithEmissionCost = new BigNumber(100).plus(emissionCost).dividedBy(100).times(amount)
          .toNumber();

          // Create trees
          const treesRef = bloqifyFirestore.collection('trees');
          // @ts-ignore
          const treeOrderId = new ShortUniqueId({ length: 4, dictionary: 'number' }).randomUUID();

          const treeDocs = times(insertedSharesAmount, (index: number): firebase.firestore.DocumentReference => {
            // @ts-ignore
            const serial = new ShortUniqueId({ length: 13, dictionary: 'alpha' }).randomUUID();
            const customId = `${treeOrderId}-${index + 1}-${serial}`;
            return treesRef.doc(customId);
          });

          const status = type !== PaymentType.StartSubscription ? PaymentStatus.Paid : PaymentStatus.Open;
          const isPaid = status === PaymentStatus.Paid;

          if (isPaid) {
            // Creating trees depending on firestore 500 writes limitations
            if (treeDocs.length > 450) {
              const [createTreeError] = await to(Promise.all(treeDocs.map(
                async (treeDoc): Promise<void> => treeDoc.set({
                  investor: investorRef,
                  investment: investmentRef,
                  payment: paymentRef,
                  asset: insertedAssetId,
                  createdDateTime: dateNow,
                  updatedDateTime: dateNow,
                  deleted: false,
                }),
              )));
              if (createTreeError) {
                throw Error('There was an error creating the trees');
              }
            } else {
              treeDocs.forEach(async (treeDoc): Promise<void> => {
                transaction.set(
                  treeDoc,
                  {
                    investor: investorRef,
                    investment: investmentRef,
                    payment: paymentRef,
                    asset: insertedAssetId,
                    createdDateTime: dateNow,
                    updatedDateTime: dateNow,
                    deleted: false,
                  },
                );
              });
            }
          }

          const newPayment: Payment = {
            asset: insertedAssetId,
            investment: investmentRef,
            investor: investorRef,
            // @ts-ignore
            createdDateTime: dateNow,
            ...isPaid && { paymentDateTime: paymentDate },
            id: paymentRef.id,
            provider: PaymentProvider.Custom,
            // @ts-ignore
            updatedDateTime: dateNow,
            deleted: false,
            // dividendsFormat: newDividensFormat,
            trees: treeDocs,
            type,
            providerData: {
              id: paymentRef.id,
              amount: {
                currency: 'EUR',
                value: ceilNumber(amountWithEmissionCost, 2),
              },
              metadata: {
                uid: investorId,
                euroAmount: Number(amount),
                sharesAmount: insertedSharesAmount,
                investmentId: investmentRef.id,
                assetId: insertedAssetId.id,
                paymentId: paymentRef.id,
              },
              status,
            },
            ...(type === PaymentType.StartSubscription) && {
              subscriptionId: paymentRef.id,
              orderId: paymentRef.id,
            },
          };

          // Setting new investment or updating old one
          if (!foundInvestments) {
            const investment: Omit<Investment, 'id'> = {
              asset: insertedAssetId,
              // @ts-ignore
              createdDateTime: dateNow,
              // @ts-ignore
              updatedDateTime: dateNow,
              investor: investorRef,
              paidEuroTotal: isPaid ? amount : 0,
              boughtSharesTotal: isPaid ? insertedSharesAmount : 0,
            };

            transaction.set(investmentRef, investment);
          } else {
            transaction.update(
              investmentRef,
              {
                ...isPaid && {
                  paidEuroTotal: firebase.firestore.FieldValue.increment(amount),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(insertedSharesAmount),
                },
                updatedDateTime: dateNow,
              },
            );
          }

          transaction.set(paymentRef, newPayment);
          if (isPaid) {
            transaction.update(
              insertedAssetId,
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(-insertedSharesAmount),
                updatedDateTime: dateNow,
              },
            );
            transaction.update(
              bloqifyFirestore.collection('settings').doc('counts'),
              {
                paidPayments: firebase.firestore.FieldValue.increment(1),
                treesCounter: firebase.firestore.FieldValue.increment(treeDocs.length),
                updatedDateTime: dateNow,
              },
            );
          }

          // ToDo: email sending when other types than OneOff?
          const [sendEmailError] = await to(bloqifyFunctions.httpsCallable('sendSharesEmail')({ lang: 'nl', investor, asset }));
          if (sendEmailError) {
            console.error(sendEmailError);
          }

          // Return the target
          return { investmentId: investmentRef.id, paymentId: paymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'createPayment' });
    },
    async updatePayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        investmentId: sourceInvestmentId,
        paymentId: sourcePaymentId,
        type,
        insertedSharesAmount,
      }: UpdatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'updatePayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          // Collection references
          const assetsRef = bloqifyFirestore.collection('assets');
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorsRef = bloqifyFirestore.collection('investors');

          const investmentRef = investmentsRef.doc(sourceInvestmentId);
          const investorRef = investorsRef.doc(investorId);
          const paymentRef = investmentRef.collection('payments').doc(sourcePaymentId);
          const insertedAssetRef = assetsRef.doc(assetId);

          // References that will be modified depending on the case for the transaction update/set zone
          let newPaymentRef = paymentRef;
          let newInvestmentRef = investmentRef;

          const [readSourceInvestmentError, sourceInvestmentSuccess] = await to(transaction.get(investmentRef));
          if (readSourceInvestmentError) {
            throw readSourceInvestmentError;
          } else if (!sourceInvestmentSuccess!.exists) {
            throw Error('Investment not found.');
          }

          const sourceInvestment = sourceInvestmentSuccess!.data() as Investment;
          const oldAssetId = sourceInvestment.asset.id;

          const [readSourceInvestorError, sourceInvestorSuccess] = await to(transaction.get(investorRef));
          if (readSourceInvestorError) {
            throw readSourceInvestorError;
          } else if (!sourceInvestorSuccess!.exists) {
            throw Error('Investor not found.');
          }

          const [readSourcePaymentError, sourcePaymentSuccess] = await to(transaction.get(paymentRef));
          if (readSourcePaymentError) {
            throw readSourcePaymentError;
          } else if (!sourcePaymentSuccess?.exists) {
            throw Error('Payment not found.');
          }

          // When payment of type gift we shouldn't modify it
          if (sourcePaymentSuccess!.get('type') === PaymentType.Gift || sourcePaymentSuccess!.get('type') === PaymentType.GiftPurchase
            || sourcePaymentSuccess!.get('type') === PaymentType.GiftRedeem) {
            throw Error('Payment of type Gift is currently not modifiable.');
          }

          const [reatedSourceAssetError, targetAssetSuccess] = await to(transaction.get(insertedAssetRef));
          if (reatedSourceAssetError) {
            throw reatedSourceAssetError;
          } else if (!targetAssetSuccess?.exists) {
            throw Error('Asset not found.');
          }

          const sourcePayment = sourcePaymentSuccess!.data() as Payment;
          if (sourcePayment.deleted || sourcePayment.ended) {
            throw Error('Cannot modify an ended or deleted payment.');
          }
          if (sourcePayment.providerData.status !== PaymentStatus.Paid) {
            throw Error('Cannot modify a payment which is not paid.');
          }

          const differentAsset = oldAssetId !== assetId;
          const differentInvestor = sourceInvestment.investor.id !== investorId;
          let targetInvestment: undefined | { id: string, data: Investment };

          const [error] = await to(
            bloqifyFunctions.httpsCallable('logData')({
              source: 'updatePayment',
              data: {
                amount,
                assetId,
                investorId,
                paymentDateTime,
                sourceInvestmentId,
                sourcePaymentId,
                type,
                insertedSharesAmount,
                differentAsset,
                differentInvestor,
              },
             }),
          );

          const [targetAssetError, sourceAssetSuccess] = await to(transaction.get(assetsRef.doc(oldAssetId)));
          if (targetAssetError) {
            throw targetAssetError;
          } else if (!sourceAssetSuccess?.exists) {
            throw Error('Old asset not found.');
          }

          // Different investment means we have to check if there is an investment already there
          if (differentInvestor || differentAsset) {
            const [readTargetInvestmentError, targetInvestmentSuccess] = await to(
              investmentsRef.where('asset', '==', insertedAssetRef).where('investor', '==', investorsRef.doc(investorId)).get(),
            );
            if (readTargetInvestmentError) {
              throw readTargetInvestmentError;
            }

            // Assigning the target investment
            if (!targetInvestmentSuccess!.empty) {
              targetInvestment = {
                id: targetInvestmentSuccess!.docs[0].id,
                data: targetInvestmentSuccess!.docs[0].data() as Investment,
              };
            }

            // Soft delete old payment
            transaction.update(
              sourcePaymentSuccess!.ref,
              {
                deleted: true,
              },
            );
          }

          const assetData: undefined | Asset = differentAsset ? targetAssetSuccess!.data() as Asset : sourceAssetSuccess!.data() as Asset;

          // Check if asset have all fields correct
          if ((assetData && !assetChecks(assetData))) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          const {
            sharesAvailable, emissionCost, startDateTime,
          } = assetData;

          // If there was an old payment we might need these values to restore the correct amounts
          const oldPaymentAmount = Number(sourcePayment.providerData.metadata.euroAmount);

          const amountIsDifferent = oldPaymentAmount !== amount;

          // Calculate the difference of the new amount and the old one (can be negative)
          const sourcePaymentSharesAmount = sourcePayment.providerData.metadata.sharesAmount;
          /** Shares */
          const sharesDiff = insertedSharesAmount - sourcePaymentSharesAmount;
          const amountDiff = amount - oldPaymentAmount;

          // Check if the selected Asset has enough available shares
          if (sharesDiff > 0 && sharesAvailable < sharesDiff) {
            throw Error('The asset does not have this many available shares.');
          }

          if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime) < startDateTime) {
            throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
          }

          // if needed let's update the investment and payment refs to new ones
          if (differentInvestor) {
            if (targetInvestment) {
              newInvestmentRef = investmentsRef.doc(targetInvestment.id);
              newPaymentRef = newInvestmentRef.collection('payments').doc();
            } else {
              newInvestmentRef = investmentsRef.doc();
              newPaymentRef = newInvestmentRef.collection('payments').doc();
            }
          }

          const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          const newPayment: Payment = {
            ...sourcePayment,
            asset: insertedAssetRef,
            paymentDateTime: paymentDate,
            // dividendsFormat: !fixedDividends ? dividendsFormat! : [years.toString(), returnsAfterEnd!],
            // @ts-ignore
            updatedDateTime: dateNow,
            // ...(paymentEndDateTime && { endDateTime: firebase.firestore.Timestamp.fromMillis(paymentEndDateTime) }),
            ...(type && { type }),
          };

          if ((differentInvestor || differentAsset) && newPayment.trees) {
            // We have different investor so we need to update the current trees
            newPayment.trees.forEach(async (treeDoc): Promise<void> => {
              transaction.update(
                treeDoc,
                {
                  investor: investorRef,
                  investment: newInvestmentRef,
                  payment: newPaymentRef,
                  asset: insertedAssetRef,
                },
              );
            });
          }

          if (amountIsDifferent || sharesDiff !== 0) {
            // Adding the emission cost on top of the euro amount
            const amountWithEmissionCost = new BigNumber(100).plus(emissionCost).dividedBy(100).times(amount)
            .toNumber();

            newPayment.providerData.amount.value = ceilNumber(amountWithEmissionCost, 2);
            newPayment.providerData.metadata.euroAmount = amount;
            newPayment.providerData.metadata.sharesAmount = insertedSharesAmount;

            if (sharesDiff > 0) {
              // Create and add trees
              const treesRef = bloqifyFirestore.collection('trees');
              // @ts-ignore
              const treeOrderId = new ShortUniqueId({ length: 4, dictionary: 'number' }).randomUUID();

              const newTrees = times(sharesDiff, (index: number): firebase.firestore.DocumentReference => {
                // @ts-ignore
                const serial = new ShortUniqueId({ length: 13, dictionary: 'alpha' }).randomUUID();
                const customId = `${treeOrderId}-${(index + sourcePayment?.trees!.length) + 1}-${serial}`;
                return treesRef.doc(customId);
              });

              // Create actual tree docs
              newTrees.forEach(async (treeDoc): Promise<void> => {
                transaction.set(
                  treeDoc,
                  {
                    investor: investorRef,
                    investment: newInvestmentRef,
                    payment: paymentRef,
                    asset: insertedAssetRef,
                    createdDateTime: dateNow,
                    updatedDateTime: dateNow,
                    deleted: false,
                  },
                );
              });
              // New trees merged
              newPayment.trees = (newPayment.trees as firebase.firestore.DocumentReference[]).concat(newTrees);
            } else {
              // Soft delete trees
              const positiveSharesDiff = Math.abs(sharesDiff);
              // Get index of last valid tree
              const treesLastIndex = newPayment!.trees!.length - 1;
              for (let index = 0; index < positiveSharesDiff; index++) {
                transaction.update(
                  // @ts-ignore
                  newPayment!.trees[treesLastIndex - index],
                  {
                    deleted: true,
                    updatedDateTime: dateNow,
                  },
                );
              }
              // remove soft deleted trees from the payment trees array
              newPayment!.trees!.splice(treesLastIndex - positiveSharesDiff, positiveSharesDiff);
            }
          }

          if (differentAsset) {
            // Restore source asset
            transaction.update(
              sourceAssetSuccess!.ref,
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(sourcePaymentSharesAmount),
                updatedDateTime: dateNow,
              },
            );
          }

          // update Asset values
          transaction.update(
            insertedAssetRef,
            {
              sharesAvailable: firebase.firestore.FieldValue.increment(
                differentAsset
                ? -insertedSharesAmount
                : -sharesDiff,
              ),
              updatedDateTime: dateNow,
            },
          );

          /**
           * TRANSACTION UPDATE / SET ZONE
           */
          if (differentInvestor) {
            if (targetInvestment) {
              // Using existant investment of new investor on the asset
              // Update target investment
              transaction.update(
                newInvestmentRef,
                {
                  paidEuroTotal: firebase.firestore.FieldValue.increment(amount),
                  boughtSharesTotal: firebase.firestore.FieldValue.increment(insertedSharesAmount),
                  updatedDateTime: dateNow,
                  paidPayments: firebase.firestore.FieldValue.increment(newPayment.providerData.status === PaymentStatus.Paid ? 1 : 0),
                  openPayments: firebase.firestore.FieldValue.increment(newPayment.providerData.status === PaymentStatus.Open ? 1 : 0),
                },
              );

              // Create target payment
              transaction.set(
                newPaymentRef,
                {
                  ...newPayment,
                  investor: investorRef,
                  investment: newInvestmentRef,
                  createdDateTime: dateNow,
                  id: newPaymentRef.id,
                } as Payment,
              );
            } else {
              // Create new investment of the new investor on the asset
              transaction.set(
                newInvestmentRef,
                {
                  paidEuroTotal: amount,
                  boughtSharesTotal: insertedSharesAmount,
                  asset: insertedAssetRef,
                  investor: investorRef,
                  createdDateTime: sourceInvestment.createdDateTime,
                  updatedDateTime: dateNow,
                  paidPayments: newPayment.providerData.status === PaymentStatus.Paid ? 1 : 0,
                  openPayments: newPayment.providerData.status === PaymentStatus.Open ? 1 : 0,
                },
              );

              // Create new investment payment
              transaction.set(
                newPaymentRef,
                {
                  ...newPayment,
                  investor: investorRef,
                  asset: insertedAssetRef,
                  investment: newInvestmentRef,
                  id: newPaymentRef.id,
                } as Payment,
              );
            }

            transaction.update(
              investmentRef,
              {
                paidEuroTotal: firebase.firestore.FieldValue.increment(-oldPaymentAmount),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(-sourcePaymentSharesAmount),
                paidPayments: firebase.firestore.FieldValue.increment(-1),
                updatedDateTime: dateNow,
              },
            );
          } else {
            // Update target investment
            transaction.update(
              investmentRef,
              {
                asset: insertedAssetRef,
                paidEuroTotal: firebase.firestore.FieldValue.increment(amountDiff),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesDiff),
                updatedDateTime: dateNow,
              },
            );

            // Update target Payment
            transaction.set(
              paymentRef,
              {
                ...newPayment,
              } as Payment,
            );
          }

          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            {
              treesCounter: firebase.firestore.FieldValue.increment(sharesDiff),
              updatedDateTime: dateNow,
            },
          );

          // Return the target
          return { investmentId: newInvestmentRef.id, paymentId: newPaymentRef.id };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updatePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'updatePayment' });
    },
    async deletePayment(
      { commit },
      { investmentId, paymentId, refund }:
        { investmentId: string, paymentId: string, refund?: boolean },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deletePayment' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const assetId = readInvestmentSuccess.get('asset').id as string;
        const insertedAssetId = bloqifyFirestore.collection('assets').doc(assetId);

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const payment = readPaymentSuccess.data() as Payment;

        if (payment.deleted) {
          throw Error('This payment was already deleted.');
        }

        if (payment.providerData.status === PaymentStatus.Open || payment.providerData.status === PaymentStatus.Pending) {
          throw Error('Cannot close an open or pending payment.');
        }

        const timeNow = firebase.firestore.FieldValue.serverTimestamp();

        // Only restore the share fields if the payment was already paid (and not deleted yet). Otherwise the paymentWebHook already took care of it
        if (payment.providerData.status === PaymentStatus.Paid) {
          const amount = payment.providerData.metadata.euroAmount;
          const sharesAmount = payment.providerData.metadata.sharesAmount;
          const paidByUserNoGift = payment.type! === PaymentType.OneOff
            || payment.type! === PaymentType.Subscription || payment.type! === PaymentType.StartSubscription;
          const giftPurchase = payment.type! === PaymentType.GiftPurchase;
          const giftRedeem = payment.type! === PaymentType.GiftRedeem;

          // Bug in ESLINT which needs to be updated in order to fix it
          // https://www.designcise.com/web/tutorial/how-to-fix-no-unused-expressions-eslint-error-when-using-optional-chaining#update-es-lint
          // eslint-disable-next-line babel/no-unused-expressions
          payment.trees?.forEach((treeRef): void => {
            transaction.update(
              treeRef,
              {
                deleted: true,
                updatedDateTime: timeNow,
              },
            );
          });

          // Update asset (only necessary if there were trees in the payment)
          if (payment.trees?.length) {
            transaction.update(
              insertedAssetId,
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount),
                updatedDateTime: timeNow,
              },
            );
          }

          // Update investment with new totals
          transaction.update(
            investmentRef,
            {
              ...paidByUserNoGift && {
                paidEuroTotal: firebase.firestore.FieldValue.increment(-amount),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              },
              ...giftPurchase && {
                giftEuroTotal: firebase.firestore.FieldValue.increment(-amount),
                giftSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              },
              ...giftRedeem && {
                receivedEuroTotal: firebase.firestore.FieldValue.increment(-amount),
                receivedSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              },
              updatedDateTime: timeNow,
            },
          );

          // Update counts
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            {
              ...(paidByUserNoGift || giftPurchase) && { paidPayments: firebase.firestore.FieldValue.increment(-1) },
              ...payment.trees?.length && { treesCounter: firebase.firestore.FieldValue.increment(payment.trees.length) },
            },
          );

          // Set status and exit
          if (refund) {
            transaction.update(paymentRef, { 'providerData.status': PaymentStatus.Refund, updatedDateTime: timeNow });
            return;
          }
        }

        // Removing payment
        transaction.update(paymentRef, { deleted: true, updatedDateTime: timeNow });
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deletePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'deletePayment' });
    },
    async markPaymentAsPaid(
      { commit },
      { investmentId, paymentId }:
      { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'markPaymentAsPaid' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);

        const [readInvestment, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestment || !readInvestmentSuccess?.exists) {
          throw readInvestment || Error('Error getting the investment.');
        }

        const [readPayment, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPayment || !readPaymentSuccess?.exists) {
          throw readPayment || Error('Error getting the payment.');
        }

        const isDeleted = readPaymentSuccess!.get('deleted') as boolean;
        if (isDeleted) {
          throw Error('This payment cannot be modified.');
        }

        const paymentStatus = readPaymentSuccess!.get('providerData.status') as PaymentStatus;
        const sharesAmount = readPaymentSuccess!.get('providerData.metadata.sharesAmount') as number;
        const euroAmount = readPaymentSuccess!.get('providerData.metadata.euroAmount') as number;
        const assetRef = readPaymentSuccess!.get('asset') as firebase.firestore.DocumentReference;
        const investorRef = readPaymentSuccess!.get('investor') as firebase.firestore.DocumentReference;
        const paymentType = readPaymentSuccess!.get('type') as PaymentType;
        const isPaid = paymentStatus === PaymentStatus.Paid;

        // Success
        if (isPaid) {
          throw Error('This payment is already paid.');
        }

        if (paymentStatus === PaymentStatus.Open || paymentStatus === PaymentStatus.Pending) {
          throw Error('Cannot change status of an open payment to paid.');
        }

        if (paymentType === PaymentType.StartSubscription || paymentType === PaymentType.Gift) {
          throw Error('Cannot change status of this type of payment.');
        }

        const dateNow = firebase.firestore.FieldValue.serverTimestamp();

        if (paymentStatus === PaymentStatus.Refund || paymentStatus === PaymentStatus.Cancel || paymentStatus === PaymentStatus.Expired
          || paymentStatus === PaymentStatus.Declined || paymentStatus === PaymentStatus.Failed) {
          // Create trees if needed
          let treeDocs: firebase.firestore.DocumentReference[] = [];
          if (paymentType === PaymentType.GiftRedeem || paymentType === PaymentType.OneOff || paymentType === PaymentType.Subscription) {
            const treesRef = bloqifyFirestore.collection('trees');
            // @ts-ignore
            const treeOrderId = new ShortUniqueId({ length: 4, dictionary: 'number' }).randomUUID();

            treeDocs = times(sharesAmount, (index: number): firebase.firestore.DocumentReference => {
              // @ts-ignore
              const serial = new ShortUniqueId({ length: 13, dictionary: 'alpha' }).randomUUID();
              const customId = `${treeOrderId}-${index + 1}-${serial}`;
              return treesRef.doc(customId);
            });

            const treesMap = new Map();
            treeDocs.forEach((treeDoc): void => {
              treesMap.set(
                [treeDoc, 'set'],
                {
                  asset: assetRef,
                  investor: investorRef,
                  payment: paymentRef,
                  deleted: false,
                  createdDateTime: dateNow,
                  updatedDateTime: dateNow,
                },
              );
            });

            // eslint-disable-next-line no-useless-catch
            try {
              await multipleTransactionAction(transaction, treesMap, 0, 3);
            } catch (e) {
              throw e;
            }

            // Update asset
            transaction.update(
              assetRef,
              {
                sharesAvailable: firebase.firestore.FieldValue.increment(-sharesAmount),
                updatedDateTime: dateNow,
              },
            );
          }

          let giftCodeRef: firebase.firestore.DocumentReference | undefined;

          if (paymentType === PaymentType.GiftPurchase) {
            giftCodeRef = bloqifyFirestore.collection('gifts').doc();
            const expirationDate = firebase.firestore.Timestamp.fromDate(moment(moment.utc()).clone().add(2, 'years').toDate());
            console.log(expirationDate.toDate());
            transaction.set(
              giftCodeRef,
              {
                amount: euroAmount,
                // @ts-ignore
                code: new ShortUniqueId({ length: 13, dictionary: 'alpha' }).randomUUID(),
                deleted: false,
                currency: 'EUR',
                treeAmount: sharesAmount,
                paymentDate: dateNow,
                expirationDate,
                orderInvestor: investorRef,
                orderInvestment: investmentRef,
                orderPayment: paymentRef,
                asset: assetRef,
                createdDateTime: dateNow,
                updatedDateTime: dateNow,
              },
            );
          }

          transaction.update(
            paymentRef,
            {
              ...paymentType === PaymentType.GiftPurchase && {
                gift: giftCodeRef!,
              },
              ...treeDocs.length && {
                trees: treeDocs,
              },
              paymentDateTime: dateNow,
              'providerData.status': PaymentStatus.Paid,
              updatedDateTime: dateNow,
            },
          );

          // Update investment
          transaction.update(
            investmentRef,
            {
              ...(paymentType === PaymentType.OneOff || paymentType === PaymentType.Subscription) && {
                paidEuroTotal: firebase.firestore.FieldValue.increment(euroAmount),
                boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesAmount),
              },
              ...paymentType === PaymentType.GiftPurchase && {
                giftEuroTotal: firebase.firestore.FieldValue.increment(euroAmount),
                giftSharesTotal: firebase.firestore.FieldValue.increment(sharesAmount),
              },
              ...paymentType === PaymentType.GiftRedeem && {
                receivedEuroTotal: firebase.firestore.FieldValue.increment(euroAmount),
                receivedSharesTotal: firebase.firestore.FieldValue.increment(sharesAmount),
              },
              updatedDateTime: dateNow,
            },
          );

          // Update counters
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            {
              ...(paymentType === PaymentType.OneOff || paymentType === PaymentType.Subscription || paymentType === PaymentType.GiftPurchase) && {
                paidPayments: firebase.firestore.FieldValue.increment(1),
              },
              ...(paymentType === PaymentType.OneOff || paymentType === PaymentType.Subscription || paymentType === PaymentType.GiftRedeem) && {
                treesCounter: firebase.firestore.FieldValue.increment(sharesAmount),
              },
              updatedDateTime: dateNow,
            },
          );
        }
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'markPaymentAsPaid' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'markPaymentAsPaid' });
    },
  },
  getters: {},
};
