
  import { ValidationProvider } from 'vee-validate';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

  @Component({
    components: {
      ValidationProvider,
      FormInvalidMessage,
    },
  })

  export default class FormTextArea extends Vue {
    @Prop({ required: true }) name!: string;
    @Prop() placeholder: string | undefined;
    @Prop() rules!: string;
    @Prop() label!: string;
    @Prop({ default: '' }) value!: string;
    @Prop({ default: 4 }) rows: number | undefined;
    @Prop({ default: true }) bails!: boolean;
  }
