
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import { getName } from 'i18n-iso-countries';
  import to from 'await-to-js';
  import { Investor, isInvestor, KYCMethods, User, UserStatus } from '@/models/users/User';
  import UserDetailsSlice from '@/components/user/UserDetailsSlice.vue';
  import { IdentificationRequestStatus, UserGender } from '@/models/identification-requests/IdentificationRequest';
  import { bloqifyFirestore, bloqifyStorage, bloqify } from '@/boot/firebase';
  import { State } from '@/models/State';
  import { GetUserIdentificationStatus } from '@/store/modules/user';
  import { Pescheckv3PescheckDocument, PeschecScreeningStatus, PescheckDatasets, PescheckDatasetsValue, UserData }
    from '@/models/identification-requests/pescheck';
  import singleDocumentQuery from '@/mixins/singleDocumentQuery';
  import ModifyStatusModal from '@/components/users/ModifyStatusModal.vue';
  import Modal from '@/components/common/Modal.vue';
  import Lightbox from '@/components/common/Lightbox.vue';
  import { capitalize } from '@/filters/string';
  import { DataContainerStatus } from '@/models/Common';
  import PesCheckModalPep from '@/components/users/PesCheckModalPep.vue';
  import PesCheckModalScreening from '@/components/users/PesCheckModalScreening.vue';
  import moment from 'moment';

  @Component({
    components: {
      UserDetailsSlice,
      Lightbox,
      ModifyStatusModal,
      Modal,
      PesCheckModalPep,
      PesCheckModalScreening,
    },
    mixins: [
      singleDocumentQuery({
        ref: bloqifyFirestore.collection('investors'),
        stateSlice: 'boundUser',
        idName: 'userId',
        singleFetch: false,
      }),
    ],
  })
  export default class UserDetails extends Vue {
    @StateClass('boundUser') boundUser!: State['boundUser'];
    @StateClass('pescheck') statePescheck!: State['pescheck'];

    @Action handleIdentificationRequest!: Function;
    @Action deleteIdentificationRequest!: Function;
    @Getter getUserIdentificationStatus!: Function;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action bindFirestoreReferences!: Function;
    @Getter getPendingChangeRequests!: State['dataChangeRequests'];
    @Action approveSensitiveDataChange!: Function;
    @Action rejectSensitiveDataChange!: Function;
    @Action requestPescheck!: Function;
    @Action pescheckScreeningReport!: Function;

    @Getter getQuestionnaireUsed!: boolean;

    @Prop({ default: false, required: false }) approvalMode!: boolean;

    @Ref('statusToggle') readonly statusToggle!: HTMLInputElement;

    UserStatus = UserStatus;
    KYCMethods = KYCMethods;

    capitalize = capitalize;

    private _UserEnabled?: UserStatus;
    showModifyStatusModal: boolean = false;

    files: { path: string, url: string, type: 'image' | 'download' }[] = [];
    liteboxImages: string[] = [];
    showLightbox = false;
    currentDocumentUrl = ''

    imgLoading = false;

    showPesCheckModalPep: boolean = false;
    showPesCheckModalScreening: boolean = false;

    approvalProcessing = false;

    requestTypeNames: any = {
      bankAccount: 'Bank Account',
      name: 'Name',
    };

    mounted(): void {
      if (this.userId) {
        if (this.userIRstatus === 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}/approval`) {
          this.$router.replace(`/user-details/${this.userId}/approval`);
        } else if (this.userIRstatus !== 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}`) {
          this.$router.replace(`/user-details/${this.userId}`);
        }
      }
    }

    @Watch('userIRstatus')
    onNewIRstatus(newuserIRstatus: GetUserIdentificationStatus): void {
       if (this.userId) {
        if (newuserIRstatus === 'initial' && this.$router.currentRoute.path !== `/user-details/${this.userId}/approval`) {
          this.$router.replace(`/user-details/${this.userId}/approval`);
        } else if ((newuserIRstatus === 'approved' || newuserIRstatus === 'rejected')
          && this.$router.currentRoute.path !== `/user-details/${this.userId}`) {
          this.$router.replace(`/user-details/${this.userId}`);
        }
      }
    }

    @Watch('statePescheck.status')
    onPescheckStatusChange(newPescheckStatus: DataContainerStatus): void {
      if (this.statePescheck?.operation === 'requestPescheck') {
        if (newPescheckStatus === DataContainerStatus.Success) {
          this.addToastMessage({
            text: 'The request has been suscessfully sent to get reviewed. You will receive the new result shortly.',
            type: 'success',
          });
        } else if (newPescheckStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: 'Pescheck reviewing request failed. Please contact support.',
            type: 'danger',
          });
        }
      }
      if (this.statePescheck?.operation === 'pescheckScreeningReport') {
        if (newPescheckStatus === DataContainerStatus.Success) {
          // Redirect from user details page
          window.open(this.statePescheck?.payload.report, '_blank');
          this.addToastMessage({
            text: 'Downloading pescheck report successful.',
            type: 'success',
          });
        } else if (newPescheckStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: 'Downloading pescheck report failed. Please contact support.',
            type: 'danger',
          });
        }
      }
    }

     get isProcessingDownload(): boolean {
      return this.statePescheck?.status === DataContainerStatus.Processing && this.statePescheck?.operation === 'pescheckScreeningReport';
    }

    get pescheckScreeningID(): string | undefined {
      return this.pescheck?.finalResult?.screening_id;
    }

    downloadReport(): void {
      this.pescheckScreeningReport(this.pescheckScreeningID);
    }

    handleModal(modal: 'showPesCheckModalPep' | 'showPesCheckModalScreening', status: boolean): void {
      this[modal] = status;
    }

    get userId(): string {
      return this.$route.params.userId;
    }

    get user(): User | Investor | null {
      return this.boundUser ?? null;
    }

    get pescheck(): Pescheckv3PescheckDocument | undefined {
      return (this.user?.pescheck as Pescheckv3PescheckDocument);
    }

    get usedBankAccounts(): User['usedBankAccounts'] {
      return this.user?.usedBankAccounts?.slice().sort((a, b) => b.lastUsed.toMillis() - a.lastUsed.toMillis());
    }

    get lastUsedBankAccount(): string | undefined {
      return this.usedBankAccounts && this.usedBankAccounts[0]?.value;
    }

    get pendingNameChangeRequest(): any {
      return this.getPendingChangeRequests.find(
        ({ investor, type }: any) => investor.id === this.userId && type === 'name',
      );
    }

    get pendingBankAccountChangeRequest(): any {
      return this.getPendingChangeRequests.find(
        ({ investor, type }: any) => investor.id === this.userId && type === 'bankAccount',
      );
    }

    get stateOfLoad(): 'loaded' | 'loading' {
      if (this.boundUser) {
        return 'loaded';
      }
      return 'loading';
    }

    get userGender(): string | null {
      return (this.user && 'gender' in this.user) ? (this.user.gender === UserGender.Male ? 'Male' : 'Female') : null;
    }

    get userName(): string | null {
      return (this.user && 'name' in this.user) ? `${this.user.name} ${this.user.surname || ''}` : null;
    }

    get userCustomId(): string {
      return (this.user && 'customId' in this.user) ? `${this.user.customId} ` : '';
    }

    get userType(): 'IDIN' | 'Business' | 'Private' | undefined {
      if (this.user && isInvestor(this.user)) {
        if (this.user.kycMethod === KYCMethods.Idin) {
          return 'IDIN';
        }
        if (this.user.kycMethod === KYCMethods.Business) {
          return 'Business';
        }
        return 'Private';
      }
      return undefined; // this will also be the case when the approval was done via an upgrade
    }

    // gives the identification request status maybe this should be a getter in vuex
    get userIRstatus(): GetUserIdentificationStatus {
      return this.getUserIdentificationStatus(this.user);
    }

    get userIRstatusTitle(): string {
      switch (this.userIRstatus) {
        case IdentificationRequestStatus.Initial:
          return 'Pending';
        case IdentificationRequestStatus.Approved:
          return 'Approved';
        case IdentificationRequestStatus.Rejected:
          return 'Rejected';
        case 'none':
          return 'None';
        case 'error':
        default:
          return 'Error';
      }
    }

    get userEnabled(): boolean {
      if (!this.user) {
        return false;
      }
      if (!this._UserEnabled) {
        return this.user.status === UserStatus.Enabled;
      }
      return this._UserEnabled === UserStatus.Enabled;
    }

    set userEnabled(newValue: boolean) {
      this._UserEnabled = newValue ? UserStatus.Enabled : UserStatus.Disabled;
      this.showModifyStatusModal = true;
    }

    getCountryName(iso: string): string {
      return getName(iso, 'en');
    }

    get datasets(): PescheckDatasetsValue[] | undefined {
      return (this.user?.pescheck as Pescheckv3PescheckDocument).finalResult?.watchlist.results.matches.map((match): any => match.datasets).flat();
    }

    get pepData(): boolean {
      if (this.datasets!.includes('PEP-CURRENT') || this.datasets!.includes('PEP-FORMER') || this.datasets!.includes('PEP-LINKED')
       || this.datasets!.includes('PEP')) {
          return true;
      }

      return false;
    }

    get screeningData(): boolean {
      let data = false;

      if (this.datasets) {
          this.datasets!.map((dataset) => {
              if (!dataset.includes('PEP')) {
                  data = true;
              }
              return data;
          });
      }
      if (data) {
          return true;
      }
      return false;
    }

    get isRequesting(): boolean {
      return this.statePescheck?.status === DataContainerStatus.Processing;
    }

    get pescheckData(): UserData | undefined {
      const userData = {
        email: (this.user as Investor).email,
        first_name: (this.user as Investor).name,
        last_name: (this.user as Investor).surname,
        watchlist_date_of_birth: moment((this.user as Investor).dateOfBirth.toDate()).format('YYYY-MM-DD'),
        watchlist_notes: (this.user?.pescheck as Pescheckv3PescheckDocument).initialRequest?.id,
      };

      return userData;
    }

    /* We need to determine which bank account to display. Depending on the most recent date, either the last used or the default bank account.
      But we don't have a specific timestamp when the default bank account was updated.
      So to determine this, the only option we have is to check for any updates on the user account in general.
      The default bank account is the dominant one. */
    get bankAccountToShow(): string | undefined {
      const lastUsedBankAccountDate = moment(this.usedBankAccounts && this.usedBankAccounts[0]?.lastUsed.toDate());
      const defaultBankAccountDate = moment((this.user as Investor).updatedDateTime.toDate());
      return lastUsedBankAccountDate.isAfter(defaultBankAccountDate) ? this.lastUsedBankAccount : this.user?.bankAccount;
    }

    get matchCount(): number | undefined {
      return this.datasets?.length;
    }

    get pescheckWarning(): any {
      return PeschecScreeningStatus.WARNING;
    }

    get pescheckCompleted(): any {
      return PeschecScreeningStatus.FINISHED;
    }

    get statusClass(): string {
      if (!((this.user?.pescheck as Pescheckv3PescheckDocument).finalResult?.status)) {
        return 'light';
      }
      switch ((this.user?.pescheck as Pescheckv3PescheckDocument).finalResult?.status as PeschecScreeningStatus) {
      case PeschecScreeningStatus.FINISHED:
        return 'success';
      case PeschecScreeningStatus.WARNING:
        return 'danger';
      case PeschecScreeningStatus.OPEN:
        return 'info';
      default:
        return 'warning';
      }
    }

    closeModal(para: 'success' | undefined): void {
      this.showModifyStatusModal = false;
      // Set the toggle back if the status change was cancelled
      if (para !== 'success') {
        const toInvertStatus = this._UserEnabled === UserStatus.Enabled;
        this._UserEnabled = !toInvertStatus ? UserStatus.Enabled : UserStatus.Disabled;
        this.statusToggle.checked = this.userEnabled;
      }
    }

    clickIR() {
      // todo we might want to display more information allow them to approve/reject the IR request in the future
    }

    /**
     * Retrieve files and show it with litebox or download if that's appropriate
     * @param path to a place in firebase storage
     */
    async openFile(path: string): Promise<void> {
      this.imgLoading = true;
      const found = this.files.find((image): boolean => image.path === path);
      let url = '';
      let type: 'image' | 'download' = 'download';

      if (!found) {
        const storageRef = bloqifyStorage.ref().child(path);
        const [getValuesError, getValues] = await to(Promise.all([storageRef.getDownloadURL(), storageRef.getMetadata()]));
        if (getValuesError || !getValues) {
          this.imgLoading = false;
          this.addToastMessage({
            text: getValuesError?.message || 'There was an error retrieving the passport.',
            type: 'danger',
          });
          return;
        }
        const [downloadUrl, metaData] = getValues;
        if (metaData.contentType.includes('image')) {
          type = 'image';
        }
        this.files.push({ path, url: downloadUrl, type });
        url = downloadUrl;
      } else {
        url = found.url;
        type = found.type;
      }

      this.imgLoading = false;

      // check the type of the file to open, if it is to download open it in a separate window
      if (type === 'download') {
        this.currentDocumentUrl = url;
        this.showLightbox = true;
        return;
      }

      this.liteboxImages = [url];
      this.showLightbox = true;
    }

    async handleRequest(action: 'approved' | 'rejected'): Promise<void> {
      this.approvalProcessing = true;
      await this.handleIdentificationRequest({ userId: this.userId, action });
      // after updating we need to get the new data
      this.bindFirestoreReferences([
        {
          name: 'boundUser',
          ref: bloqifyFirestore.collection('investors').doc(this.userId),
        },
      ]);
      this.approvalProcessing = false;
    }

    formatObjectValue(value: any): string {
      return `${value.name} ${value.surname}`;
    }
  }
