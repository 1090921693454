
  import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
  import { Action, Getter } from 'vuex-class';
  import BigNumber from 'bignumber.js';
  import to from 'await-to-js';
  import saveAs from 'file-saver';
  import * as XLSX from 'xlsx';
  import moment from 'moment';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import Modal from '@/components/common/Modal.vue';
  import { Asset, ExportRowType, ExportTableDataType } from '@/models/assets/Asset';
  import { capitalize } from '@/filters/string';

@Component({
  components: {
    Modal,
  },
})
  export default class UploadConfirmationModal extends Vue {
  @Prop() asset!: Asset;
  @Prop() type!: string;
  @Prop() newAssetId!: string;

  @Action bulkAddRepayment!: (payload: { assetId: string; uploadedFile: File; data: ExportTableDataType[] }) => void;
  @Action bulkAddEarning!: (payload: { assetId: string; uploadedFile: File; data: ExportTableDataType[] }) => void;
  @Action bulkAddCost!: (payload: { assetId: string; uploadedFile: File; data: ExportTableDataType[] }) => void;
  @Action bindFirestoreReferences!: (payload: { name: string; ref: unknown }[]) => Promise<void>;
  @Action unbindFirestoreReferences!: (payload: { name: string }[]) => void;
  @Action(addToastMessage) addToastMessage!: (payload: { text: string; type: string }) => void;

  @Getter getInvestorNameByCustomID!: (customID: string) => Promise<string>;

  newFiletotalAmount = 0;
  allowedExtensions = ['.xlsx'];
  acceptedMime = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  incorrectFile = false;
  tableData: ExportTableDataType[] = [];
  tableColumns = this.getColumns();
  newFile: { file: File }[] = [];
  tableOptions = {
    headings: this.getHeadings(),
    filterable: false,
    sortable: [],
    cellClasses: this.getCellClasses(),
    templates: {
      amount: (h: Function, row: ExportTableDataType) => +row.amount.toFixed(3),
    },
  };
  get upperCaseType(): string {
    if (capitalize(this.type) === 'Repayments') {
      return 'Aflossingen';
    }
    if (capitalize(this.type) === 'Earnings') {
      return 'Rentebetalingen';
    }
    return capitalize(this.type);
  }
  get lowerCaseType(): string {
    if (capitalize(this.type) === 'Repayments') {
      return 'aflossingen';
    }
    if (capitalize(this.type) === 'Earnings') {
      return 'rentebetalingen';
    }
    return this.type;
  }
  @Watch('newFile', { deep: true })
  async onNewFile(newFile): Promise<void> {
    if (newFile?.length > 0) {
      const workbook = XLSX.read(await (newFile[0].file as File).arrayBuffer());
      const rows: ExportRowType[] = XLSX.utils.sheet_to_json(workbook.Sheets.Data);

      if (rows.length === 0) {
        this.addToastMessage({
          text: 'The XLSX must the information in a sheet called "Data"',
          type: 'danger',
        });
        return;
      }

      const parsedTablePromise = Promise.all(
        rows.map(async (row): Promise<ExportTableDataType> => {
          this.incorrectFile = false;
          const hasErrors: string[] = [];
          const errors: string[] = [];
          if (this.asset.fundType === 'equity') {
            if (row.dividendType === undefined) {
              hasErrors.push('dividendType');
              errors.push('dividendType cant be empty');
              this.incorrectFile = true;
            }

            const equityDividendTypes = this.asset.dividendsFormat.map((df) => df.contents[0].toUpperCase());
            if (!equityDividendTypes.includes(String(row.dividendType).toUpperCase())) {
              hasErrors.push('dividendType');
              errors.push(
                `${row.dividendType} is invalid, these are the valid asset dividend types: ${equityDividendTypes.toString()}`,
              );
              this.incorrectFile = true;
            }
          }

          let transactionDate;
          if (typeof row.transactionDate === 'string') {
            transactionDate = row.transactionDate;
          } else if (typeof row.transactionDate === 'number') {
            transactionDate = moment((Number(row.transactionDate) - 25569) * 24 * 60 * 60 * 1000).format('DD/MM/YYYY');
          }
          const transactionDateMoment = moment(transactionDate, 'DD/MM/YYYY');

          let paymentDate;
          if (typeof row.paymentDate === 'string') {
            paymentDate = row.paymentDate;
          } else if (typeof row.paymentDate === 'number') {
            paymentDate = moment((Number(row.paymentDate) - 25569) * 24 * 60 * 60 * 1000).format('DD/MM/YYYY');
          }

          const paymentDateMoment = moment(paymentDate, 'DD/MM/YYYY');
          const amount = typeof row.amount === 'string' ? Number(row.amount.replace(',', '.')) : row.amount;
          const investorId = row.investorId;
          const investorName = await this.getInvestorNameByCustomID(investorId.toString());

          if (!transactionDate) {
            hasErrors.push('transactionDate');
            errors.push('transactionDate cant be empty');
            this.incorrectFile = true;
          }

          if (!paymentDate) {
            hasErrors.push('paymentDate');
            errors.push('paymentDate cant be empty');
            this.incorrectFile = true;
          }

          if (typeof transactionDate !== 'string') {
            hasErrors.push('transactionDate');
            errors.push("transactionDate should be text, you can use ' to ensure excel dates are text");
            this.incorrectFile = true;
          }

          if (typeof paymentDate !== 'string') {
            hasErrors.push('paymentDate');
            errors.push("paymentDate should be text, you can use ' to ensure excel dates are text");
            this.incorrectFile = true;
          }

          if (!transactionDateMoment.isValid()) {
            hasErrors.push('transactionDate');
            errors.push(`transactionDate ${transactionDate} doesnt has the correct format DD/MM/YYYY`);
            this.incorrectFile = true;
          }

          if (!paymentDateMoment.isValid()) {
            hasErrors.push('paymentDate');
            errors.push(`paymentDate ${paymentDate} doesnt has the correct format DD/MM/YYYY`);
            this.incorrectFile = true;
          }

          // Getter returns '-' if no investor present with that ID
          if (investorName === '-') {
            hasErrors.push('investorId');
            errors.push('investorId not found in the database');
            this.incorrectFile = true;
          }
          this.newFiletotalAmount = new BigNumber(this.newFiletotalAmount).plus(amount).toNumber();
          return {
            transactionDate,
            paymentDate,
            amount,
            investorName,
            ...(this.asset.fundType === 'equity' && { dividendType: row.dividendType }),
            investorId,
            ...(row.description && { description: row.description }),
            hasErrors,
            errors,
          };
        }),
      );

      const [parsingError, parsedTable] = await to(parsedTablePromise);
      if (parsingError) {
        this.addToastMessage({
          text: 'Error while processing the uploaded data.',
          type: 'danger',
        });
        throw new Error('Error processing the upload');
      }

      this.tableData = parsedTable as ExportTableDataType[];
    }
  }

  submitAssetFinancialsData(): void {
    switch (this.type) {
      case 'repayment':
        this.bulkAddRepayment({
          assetId: this.asset.id!,
          uploadedFile: this.newFile[0].file,
          data: this.tableData,
        });
        break;
      case 'earning':
        this.bulkAddEarning({
          assetId: this.asset.id!,
          uploadedFile: this.newFile[0].file,
          data: this.tableData,
        });
        break;
      case 'cost':
        this.bulkAddCost({
          assetId: this.asset.id!,
          uploadedFile: this.newFile[0].file,
          data: this.tableData,
        });
        break;
      default:
        break;
    }
  }

  downloadFormatFile(): void {
    const dataHeaders = [this.tableColumns.filter((header) => header !== 'investorName')];

    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('Data');
    workbook.Sheets.Data = XLSX.utils.aoa_to_sheet(dataHeaders);

    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([workbookOut], { type: 'application/octet-stream' });

    saveAs(blob, `${this.upperCaseType}-importFormat.xlsx`);
  }

  generateErrorsXLSX(): void {
    const etlFormatFinal: Record<string, unknown>[] = this.tableData.map(
      (obj: ExportTableDataType): Record<string, unknown> => ({
        ...this.getColumns().reduce((res, key): Record<string, unknown> => {
          if (obj[key] !== undefined) {
            res[key] = obj[key];
          }
          return res;
        }, {}),
        errors: obj.errors?.toString(),
      }),
    );

    const workbook = XLSX.utils.book_new();
    workbook.SheetNames.push('Errors');
    workbook.Sheets.Errors = XLSX.utils.json_to_sheet(etlFormatFinal);

    const workbookOut = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    const blob = new Blob([workbookOut], { type: 'application/octet-stream' });

    saveAs(blob, 'errors.xlsx');
  }

  displayErrors(event): void {
    event.row.errors.forEach((error) => {
      this.addToastMessage({
        text: error,
        type: 'warning',
      });
    });
  }

  getColumns(): string[] {
    return Object.keys(this.getHeadings());
  }

  getHeadings(): {
    transactionDate: string;
    paymentDate: string;
    amount: string;
    investorName: string;
    investorId: string;
    dividendType: string;
    description: string;
  } {
    return {
      transactionDate: 'Transaction date',
      paymentDate: 'Payment date',
      amount: 'Amount',
      investorName: 'Client name',
      investorId: 'Client id',
      dividendType: 'Type',
      description: 'Description',
    };
  }

  getCellClasses(): {
    transactionDate: { class: string; condition: (row: ExportTableDataType) => boolean }[];
    paymentDate: { class: string; condition: (row: ExportTableDataType) => boolean }[];
    amount: { class: string; condition: (row: ExportTableDataType) => boolean }[];
    investorName: { class: string; condition: (row: ExportTableDataType) => boolean }[];
    investorId: { class: string; condition: (row: ExportTableDataType) => boolean }[];
    dividendType: { class: string; condition: (row: ExportTableDataType) => boolean }[];
    description: { class: string; condition: (row: ExportTableDataType) => boolean }[];
  } {
    return Object.assign(
      {},
      ...this.getColumns().map((column) => {
        const returnValue = {};
        returnValue[`${column}`] = [
          {
            class: 'modalCellHasErrors',
            condition: (row): boolean => row.hasErrors && row.hasErrors.length !== 0 && row.hasErrors.includes(column),
          },
          {
            class: 'modalRowHasErrors',
            condition: (row): boolean => row.hasErrors && row.hasErrors.length !== 0 && !row.hasErrors.includes(column),
          },
        ];
        return returnValue;
      }),
    );
  }

  inputFilter(newFile, oldFile, prevent): void {
    // Preventing a non-pdf file to be inserted
    if (
      !oldFile
      && newFile?.name
      && !this.allowedExtensions.some((extension): boolean => newFile.name.endsWith(extension))
    ) {
      this.addToastMessage({
        text: 'Only XLSX files are allowed.',
        type: 'danger',
      });
      prevent();
    }
  }
  declineUpload(): void {
    this.newFiletotalAmount = 0;
    this.newFile = [];
    this.$emit('close');
  }
  }
