var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"buttons"}},[_vm._m(0),_vm._m(1),_vm._m(2),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" White ")]),_c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Creates a white variation of a button since Bootstrap's native \"light\" variant doesn't work well over light backgrounds. ")]),_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(4)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Rounded circle ")]),_vm._m(5),_c('div',{staticClass:"card"},[_vm._m(6),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(7)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Icon ")]),_vm._m(8),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('a',{staticClass:"btn btn-white",attrs:{"href":"#!"}},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'chevron-up']}})],1),_vm._v("   "),_c('a',{staticClass:"btn btn-primary",attrs:{"href":"#!"}},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'cog']}})],1),_vm._v("   "),_c('a',{staticClass:"btn btn-rounded-circle btn-white",attrs:{"href":"#!"}},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'sign-out-alt']}})],1),_vm._v("   "),_c('a',{staticClass:"btn btn-rounded-circle btn-primary",attrs:{"href":"#!"}},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'user']}})],1)]),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header mt-md-6"},[_c('div',{staticClass:"header-body"},[_c('h1',{staticClass:"header-title"},[_vm._v(" Buttons ")]),_c('p',{staticClass:"header-subtitle"},[_vm._v(" Use Bootstrap's custom button styles for actions in forms, dialogs, and more with support for multiple sizes, states, and more. Please read the "),_c('a',{attrs:{"target":"_blank","href":"https://getbootstrap.com/docs/4.0/components/buttons/"}},[_vm._v(" official Bootstrap documentation ")]),_vm._v(" for a full list of options. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('button',{staticClass:"btn btn-primary btn-lg",attrs:{"type":"button"}},[_vm._v(" Large button ")]),_vm._v("   "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v(" Base button ")]),_vm._v("   "),_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"}},[_vm._v(" Small button ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"}},[_vm._v(" Primary ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"}},[_vm._v(" Secondary ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-success",attrs:{"type":"button"}},[_vm._v(" Success ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"}},[_vm._v(" Danger ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"}},[_vm._v(" Warning ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"}},[_vm._v(" Info ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-light",attrs:{"type":"button"}},[_vm._v(" Light ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-dark",attrs:{"type":"button"}},[_vm._v(" Dark ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-link",attrs:{"type":"button"}},[_vm._v(" Link ")]),_vm._v("  "),_c('hr'),_c('button',{staticClass:"btn btn-outline-primary",attrs:{"type":"button"}},[_vm._v(" Primary ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"}},[_vm._v(" Secondary ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"button"}},[_vm._v(" Success ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-danger",attrs:{"type":"button"}},[_vm._v(" Danger ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-warning",attrs:{"type":"button"}},[_vm._v(" Warning ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-info",attrs:{"type":"button"}},[_vm._v(" Info ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-light",attrs:{"type":"button"}},[_vm._v(" Light ")]),_vm._v("  "),_c('button',{staticClass:"btn btn-outline-dark",attrs:{"type":"button"}},[_vm._v(" Dark ")]),_vm._v("  ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('a',{staticClass:"btn btn-white",attrs:{"href":"#!"}},[_vm._v(" Button white ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <a href=\"#\" class=\"btn btn-white\">"),_c('br'),_vm._v("   Button"),_c('br'),_vm._v(" </a> ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Useful for single character or emoji buttons, you can turn any button into a circle using the "),_c('code',[_vm._v(".btn-rounded-circle")]),_vm._v(" modifier. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('a',{staticClass:"btn btn-sm btn-rounded-circle btn-white",attrs:{"href":"#!"}},[_vm._v(" + ")]),_vm._v("   "),_c('a',{staticClass:"btn btn-rounded-circle btn-white",attrs:{"href":"#!"}},[_vm._v(" + ")]),_vm._v("   "),_c('a',{staticClass:"btn btn-lg btn-rounded-circle btn-white",attrs:{"href":"#!"}},[_vm._v(" + ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <a href=\"#\" class=\"btn btn-rounded-circle btn-white\">"),_c('br'),_vm._v("   +"),_c('br'),_vm._v("</a> ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-4"},[_vm._v(" A combination of the standard button component and an "),_c('a',{attrs:{"href":"https://fontawesome.com/icons?d=gallery"}},[_vm._v("FontAwesome icon")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"html highlight bg-dark"},[_vm._v(" <a href=\"#!\" class=\"btn btn-white\">"),_c('br'),_vm._v("  <font-awesome-icon :icon=\"['fa', 'user']\" />"),_c('br'),_vm._v(" </a>"),_c('br'),_c('br'),_vm._v(" <a href=\"#!\" class=\"btn btn-primary\">"),_c('br'),_vm._v("  <font-awesome-icon :icon=\"['fa', 'user']\" />"),_c('br'),_vm._v(" </a>"),_c('br'),_c('br'),_vm._v(" <a href=\"#!\" class=\"btn btn-rounded-circle btn-white\">"),_c('br'),_vm._v("  <font-awesome-icon :icon=\"['fa', 'user']\" />"),_c('br'),_vm._v(" </a>"),_c('br'),_c('br'),_vm._v(" <a href=\"#!\" class=\"btn btn-rounded-circle btn-primary\">"),_c('br'),_vm._v("  <font-awesome-icon :icon=\"['fa', 'user']\" />"),_c('br'),_vm._v(" </a> ")])
}]

export { render, staticRenderFns }