
  import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import FileSaver from 'file-saver';
  import pick from 'lodash.pick';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  // @ts-ignore
  import json2csv from 'json2csv/dist/json2csv.umd';
  import { bloqifyFirestore, bloqifyFunctions, firebase } from '@/boot/firebase';
  import { Event } from 'vue-tables-2-premium';
  import { ManagerRole } from '@/models/manager/Manager';
  import { DataChangeRequest, Investor, User, UserStatus, UserTier } from '@/models/users/User';
  import { BusinessIdentification, IdentificationRequest, IdentificationRequestStatus, PrivateIdentification }
    from '@/models/identification-requests/IdentificationRequest';
  import DownloadPDFModal from '@/components/users/DownloadPDFModal.vue';
  import { Pescheckv3PescheckDocument, PeschecScreeningStatus, UserData } from '@/models/identification-requests/pescheck';
  import PesCheckModal, { PescheckDisplay } from '@/components/users/PesCheckModalPep.vue';
  import ModifyStatusModal from '@/components/users/ModifyStatusModal.vue';
  import ChangesRequestedModal from '@/components/users/ChangesRequestedModal.vue';
  import { GetUserIdentificationStatus } from '@/store/modules/user';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import moment from 'moment';
  import { firebaseAxiosInstance } from '@/boot/axios';

  type TierFilter = UserTier | 'all';

  interface UserDisplayTable extends Omit<| Investor, 'pescheck'> {
    id: any;
    pescheck: PescheckDisplay | undefined;
    userIRstatus: GetUserIdentificationStatus;
  }

  interface UserModified extends Omit<User, 'customId' | 'dateOfBirth' | 'createdDateTime' | 'updatedDateTime'> {
    id: string | undefined,
    customId: string,
    city: string,
    postalCode: string,
    country: string,
    address: string,
    tier: UserTier,
    status: UserStatus,
    pescheck: firebase.firestore.DocumentReference | Pescheckv3PescheckDocument | undefined,
    identificationRequest: firebase.firestore.DocumentReference | PrivateIdentification | BusinessIdentification | undefined,
    name: string,
    surname: string,
    telephone: string,
    email: string,
    dateOfBirth: string | firebase.firestore.Timestamp,
    createdDateTime: string | firebase.firestore.Timestamp,
    updatedDateTime: string | firebase.firestore.Timestamp,
  }

  function deepValues(object: object, fields?: string[], deep: boolean = false): string | string[] {
    const array: string[] = Object.values(fields?.length ? pick(object, fields) : object).map((value: any) => {
      if (Array.isArray(value)) {
        return value;
      }

      if (typeof value === 'object' && value !== null && value !== undefined) {
        return deepValues(value, [], true);
      }

      return value;
    });

    if (deep) {
      return array;
    }

    return array.flat().join(' ').toLowerCase();
  }

  @Component({
    components: {
      PesCheckModal,
      DownloadPDFModal,
      ModifyStatusModal,
      ChangesRequestedModal,
    },
  })
  export default class TabAllInvestors extends Vue {
    columns = [
      'customId',
      'name',
      'surname',
      'email',
      'tier',
      'userIRstatus',
      'pescheck',
      'createdDateTime',
      'changeRequests',
      'dropdown',
    ];
    combinedQueryFields = ['name', 'surname', 'email', 'tier', 'userIRstatus']
    options: any = {
      headings: {
        customId: 'id',
        name: 'Name',
        surname: 'surname',
        email: 'Email',
        tier: 'Tier',
        userIRstatus: 'Status',
        pescheck: 'PES',
        createdDateTime: 'Registered At',
        changeRequests: 'Request',
        dropdown: '',
      },
      filterable: [
        'customId',
        'name',
        'surname',
        'email',
        'tier',
        'userIRstatus',
        'pescheck',
        'createdDateTimeString',
        'changeRequests',
        'combinedValues',
      ],
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2-premium adds classes runtime without a space before
      columnsClasses: {
        customId: 'table__col--customId table__col--xs ',
        name: 'table__col--name table__col--s ',
        surname: 'table__col--surname table__col--s ',
        email: 'table__col--email table__col--l ',
        userIRstatus: 'table__col--userIRstatus table__col--s ',
        pescheck: 'table__col--pescheck table__col--m ',
        tier: 'table__col--tier table__col--xs ',
        createdDateTime: 'table__col--createdDateTime table__col--m ',
        changeRequests: 'table__col--changeRequests table__col--s ',
        dropdown: 'table__col--dropdown align-middle table__col--xs ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table,
      customFilters: [
        {
          name: 'tier',
          callback(row, query) {
            return row.tier === query;
          },
        },
      ],
      filterAlgorithm: {
        combinedValues(row: any, queryString: string) {
          const queryArray = queryString.toLowerCase().split(' ');
          return queryArray.every((queryWord) => row.combinedValues.includes(queryWord));
        },
      },
      customSorting: {
        userIRstatus(ascending) {
          return (a, b) => {
            if (a === b) {
              return 0;
            }
            const sortingOrder: Array<GetUserIdentificationStatus | UserStatus.Disabled> = [
              UserStatus.Disabled,
              IdentificationRequestStatus.Rejected,
              GetUserIdentificationStatus.Error,
              GetUserIdentificationStatus.None,
              IdentificationRequestStatus.Initial,
              IdentificationRequestStatus.Approved,
            ];
            const aToPass = a.status === UserStatus.Disabled ? UserStatus.Disabled : a.userIRstatus;
            const bToPass = b.status === UserStatus.Disabled ? UserStatus.Disabled : b.userIRstatus;
            if (ascending) {
              return sortingOrder.indexOf(aToPass) > sortingOrder.indexOf(bToPass) ? 1 : -1;
            }

            return sortingOrder.indexOf(aToPass) <= sortingOrder.indexOf(bToPass) ? 1 : -1;
          };
        },
        pescheck(ascending) {
          return (a, b) => {
            const aPes = a.pescheck?.status || 'none';
            const bPes = b.pescheck?.status || 'none';
            if (aPes === bPes) {
              return 0;
            }
            const sortingOrder = [
              PeschecScreeningStatus.WARNING,
              PeschecScreeningStatus.OPEN,
              PeschecScreeningStatus.FINISHED,
              'none',
            ];
            if (ascending) {
              return sortingOrder.indexOf(aPes) > sortingOrder.indexOf(bPes) ? 1 : -1;
            }

            return sortingOrder.indexOf(aPes) <= sortingOrder.indexOf(bPes) ? 1 : -1;
          };
        },
      },
    };
    showDownloadPDFModal: boolean = false;
    showModifyStatusModal: boolean = false;
    showPesCheckModal: boolean = false;
    showChangesRequestedModal: boolean = false;
    selectedUser: any = null;
    UserTier = UserTier;
    currentTierFilter: TierFilter = 'all';
    users: User[] | Investor[] | UserModified[] = [];
    loadingUsers = false;
    usersUnsubscribe: undefined | Function;
    generatingExcel = false;

    @Getter getUserIdentificationStatus!: Function;
    @Ref('investorTable') investorTable!: any;

    @Prop({ default: false }) loadingBindings!: boolean;

    @Action(addToastMessage) addToastMessage!: Function;
    @Action requestPescheck!: Function;

    @Getter getCurrentManagerRole!: ManagerRole;
    @Getter getIdentificationRequestFromInvestorsListByInvestorId!: (id: string) => IdentificationRequest | undefined;
    @Getter getPendingChangeRequests!: State['dataChangeRequests'];

    @StateClass('pescheck') statePescheck!: State['pescheck'];
    @StateClass boundUser!: State['boundUser'];

    mounted(): void {
      this.loadingUsers = true;

      // Loading users with a listener
      this.usersUnsubscribe = bloqifyFirestore.collection('investors')
          .orderBy('createdDateTime', 'desc')
          .onSnapshot(async (snapshot): Promise<void> => {
            try {
              this.users = await Promise.all(snapshot.docs.map(async (doc): Promise<UserModified> => {
                const defaultUser: User | Investor = {
                  ...doc.data() as Investor,
                  city: doc.get('city'),
                  postalCode: doc.get('postalCode'),
                  country: doc.get('country'),
                  streetAddress: doc.get('streetAddress'),
                  telephone: doc.get('telephone'),
                  houseNumber: doc.get('houseNumber'),
                  id: doc.id,
                };

                const identRequest = doc.get('identificationRequest') as firebase.firestore.DocumentReference | undefined;
                if (identRequest) {
                  (defaultUser as User).identificationRequest = (await identRequest.get())?.data() as IdentificationRequest;
                }

                const pescheck = doc.get('pescheck') as firebase.firestore.DocumentReference | undefined;
                if (pescheck) {
                  defaultUser.pescheck = (await pescheck.get())?.data() as Pescheckv3PescheckDocument;
                }

                // const referralCode = getInvestorReferralSuccess?.get('code') || '';
                const customId = `#${defaultUser.customId}` || '';
                const address = `${defaultUser.streetAddress} ${defaultUser.houseNumber}`;

                // Selecting field by field to avoid RangeError: Maximum call stack size exceeded.
                // Mostly like due to circular references provoked by the Firestore data.
                const finalUser: UserModified = {
                  id: defaultUser.id,
                  customId,
                  // @ts-ignore
                  name: defaultUser.name,
                  // @ts-ignore
                  surname: defaultUser.surname,
                  city: defaultUser.city,
                  postalCode: defaultUser.postalCode,
                  country: defaultUser.country,
                  address,
                  email: defaultUser.email,
                  telephone: defaultUser.telephone,
                  tier: defaultUser.tier,
                  status: defaultUser.status,
                  pescheck: defaultUser.pescheck,
                  dateOfBirth: defaultUser.dateOfBirth,
                  createdDateTime: defaultUser.createdDateTime,
                  updatedDateTime: defaultUser.updatedDateTime,
                  identificationRequest: (defaultUser as User).identificationRequest,
                };

                return finalUser;
              }));
            } catch (e) {
              console.error(e);
            }

            if (!snapshot.metadata.fromCache) {
              this.loadingUsers = false;
            }
          });
    }

    beforeDestroy(): void {
      this.usersUnsubscribe!();
    }

    @Watch('$route.query', { immediate: true, deep: true }) onRouteParamsChange(): void {
      if (this.$route.query.sorting) {
        this.options = {
          ...this.options,
          orderBy: {
            column: this.$route.query.sorting,
            ascending: false,
          },
        };
      }
    }

    @Watch('statePescheck.status')
    onPescheckStatusChange(newPescheckStatus: DataContainerStatus): void {
      if (newPescheckStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: 'The request has been suscessfully sent to get reviewed. You will receive the new result shortly.',
          type: 'success',
        });
      } else if (newPescheckStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: 'Pescheck reviewing request failed. Please contact support.',
          type: 'danger',
        });
      }
    }

    /**
     * Execute investor download.
     */
    async downloadInvestorsExcel(): Promise<void> {
      this.generatingExcel = true;
      const instance = await firebaseAxiosInstance();

      try {
        const res = await instance.get(
          'exportInvestors',
          {
            responseType: 'blob',
          },
        );

        FileSaver.saveAs(res.data, 'investors.xlsx');
      } catch (err) {
        // As the error will be of type Blob we need to parse it to text first
        // see https://developer.mozilla.org/en-US/docs/Web/API/Blob/text
        let errorText;

        try {
          // @ts-ignore
          errorText = await err.response.data.text();
        } catch (e) {
          errorText = 'There was a problem generating the Excel.';
        }

        this.addToastMessage({
          text: errorText,
          type: 'danger',
        });
      } finally {
        this.generatingExcel = false;
      }
    }

    handleModal(modal: 'PesCheckModal' | 'modify' | 'download' | 'changeRequest', status: boolean, investor: any): void {
      this.selectedUser = investor;
      switch (modal) {
      case 'modify':
        this.showModifyStatusModal = status;
        break;
      case 'download':
        this.showDownloadPDFModal = status;
        break;
      case 'PesCheckModal':
        this.showPesCheckModal = status;
        break;
      case 'changeRequest':
        this.showChangesRequestedModal = status;
        break;
      default:
        break;
      }
    }

    get transformedOptions(): any {
      return {
        ...this.options,
        ...(this.$route.query.page && {
          initialPage: Number(this.$route.query.page),
        }),
      };
    }

    onPagination(index: number): void {
      history.replaceState(null, '', `?page=${index}`);
    }

    get listUsers(): (UserDisplayTable | null)[] {
      return (this.users as Investor[]).map((investor: User | Investor): any | null => {
        const userIRstatus: GetUserIdentificationStatus = this.getUserIdentificationStatus(investor);

        let pescheck: PescheckDisplay | UserData = null;
        const isPescheckObject = (pescheck): pescheck is Pescheckv3PescheckDocument => !!pescheck.finalResult?.status;
        const pescheckOfUser = (investor as User).pescheck;
        if (pescheckOfUser && isPescheckObject(pescheckOfUser)) {
          pescheck = {
            status: pescheckOfUser.finalResult?.status,
            datasets: pescheckOfUser.finalResult?.watchlist.results.matches.map((match): any => match.datasets).flat(),
            email: investor.email,
            first_name: (investor as Investor).name,
            last_name: (investor as Investor).surname,
            watchlist_date_of_birth: moment((investor as Investor).dateOfBirth).format('YYYY-MM-DD'),
            watchlist_notes: pescheckOfUser.initialRequest?.id,
            isFalsePositivePep: pescheckOfUser.finalResult?.isFalsePositivePep,
            isFalsePositiveScreening: pescheckOfUser.finalResult?.isFalsePositiveScreening,
          };
        }

        // Filter data change requests for this user
        const changeRequests = this.getPendingChangeRequests.filter(
          (dataChangeRequest: DataChangeRequest) => (dataChangeRequest.investor as User).id === (investor as User).id,
        ).length || 0;

        // Create string for createdDateTime to be able to filter on it
        const createdDateTimeString: string = this.$options.filters?.transformDate(
          this.$options.filters?.convertUTCToLocalDate((investor as User).createdDateTime),
          'DD/MM/YYYY',
        );

        const user = {
          ...investor,
          pescheck,
          userIRstatus,
          id: (investor as UserDisplayTable).id,
          changeRequests,
          createdDateTimeString,
        };

        // Combines values from predefined fields into string for filtering
        const combinedValues = deepValues(user, this.combinedQueryFields) as string;

        return {
          ...user,
          combinedValues,
        };
      });
    }

    get pescheckWarning(): any {
      return PeschecScreeningStatus.WARNING;
    }

    get pescheckCompleted(): any {
      return PeschecScreeningStatus.FINISHED;
    }

    get user(): User | Investor | null {
      return this.boundUser ?? null;
    }

    /**
     * Returns whether current browser allows file downloads.
     */
    get fileDownloadIsSupported(): boolean {
      try {
        return !!new Blob();
      } catch (e) {
        return false;
      }
    }

    submitPescheckReview(pescheck: any): void {
      const userData = {
        email: pescheck.email,
        first_name: (pescheck as Investor).name,
        last_name: (pescheck as Investor).surname,
        watchlist_date_of_birth: moment((pescheck as Investor).dateOfBirth).format('YYYY-MM-DD'),
        watchlist_notes: pescheck.pescheck?.watchlist_notes,
      };

      this.requestPescheck({ pescheck: userData, investorId: pescheck.id });
    }

    /**
     * Allow changing investor status (enabled/disabled).
     */
    get allowChangingInvestorStatus(): boolean {
      return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
    }

    filterTableByTier(tier: TierFilter) {
      this.currentTierFilter = tier;
      if (tier === 'all') {
        Event.$emit('vue-tables.filter::tier', null);
        return;
      }
      Event.$emit('vue-tables.filter::tier', tier);
    }
  }
