
  import { Component, Prop, Vue } from 'vue-property-decorator';

  /* `Bails` needs to be set to false at the validationProvider/FormInput/FormTextArea component in order to display all error messages https://logaretm.github.io/vee-validate/api/validation-provider.html#props */
  @Component({
    inheritAttrs: false,
  })

  export default class FormInvalidMessage extends Vue {
    @Prop({ required: true }) errors!: [];
  }

