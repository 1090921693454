
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import moment from 'moment';
  import FileSaver from 'file-saver';
  import { firebase, bloqifyFirestore } from '@/boot/firebase';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { GetCollectionParams } from '@/store/actions';
  import { Tree, Investment, Payment } from '@/models/investments/Investment';
  import { timestampToDate } from '@/filters/date';
  import { Asset } from '@/models/assets/Asset';
  import { Investor } from '@/models/users/User';
  import EarningsTable from '@/components/investments/EarningsTable.vue';
  import { firebaseAxiosInstance } from '@/boot/axios';
  import InvestmentsTable from './InvestmentsTable.vue';
  import InvestmentPaymentsTable from './InvestmentPaymentsTable.vue';
  import PaymentActionModal from './PaymentActionModal.vue';
  import SubscriptionsCard from './SubscriptionsCard.vue';
  import ModifySubscriptionModal from './ModifySubscriptionModal.vue';
  import CancelSubscriptionModal from './CancelSubscriptionModal.vue';

  interface InvestmentInTable extends Omit<Investment, 'investor'> {
    investor: Pick<Investor, 'name' | 'surname' | 'id'>;
  }

  type View = 'investments' | 'payments' | 'earnings'

  @Component({
    components: {
      EarningsTable,
      InvestmentsTable,
      InvestmentPaymentsTable,
      PaymentActionModal,
      SubscriptionsCard,
      ModifySubscriptionModal,
      CancelSubscriptionModal,
    },
  })
  export default class InvestmentsAll extends Vue {
    moment = moment;
    totalEarnings = null;
    loadingInvestments = false;
    loadingPayments: Promise<any> = Promise.resolve();
    loadingFilters = false;
    investments: Investment[] = [];
    assets: Asset[] = [];
    assetsObject: { [key: string]: Asset } = {};
    investors: Investor[] = [];
    investorsObject: { [key: string]: Investor } = {};
    investmentsUnsubscribe: undefined | Function;
    assetsUnsubscribe: undefined | Function;
    investorsUnsubscribe: undefined | Function;
    openModifySubscription: boolean = false;
    openCancelSubscription: boolean = false;
    modifySubscriptionData = {
      modifySubDate: new Date(),
      bankAccount: '',
      mandateBankAccount: '',
      sharesAmount: 0,
      sharesPrice: 0,
    }

    isUpdating: boolean = false;
    isCancelling: boolean = false;
    generatingExcel = false;

    view: View = 'investments';
    showModal = false;
    investmentColumns = ['fund', 'name', 'surname', 'updatedDateTime', 'paidEuroTotal', 'showPayments', 'showEarnings'];
    investmentOptions = {
      headings: {
        fund: 'Fund',
        name: 'Name',
        surname: 'Surname',
        updatedDateTime: 'Last Update',
        paidEuroTotal: 'Total',
        showPayments: '',
        showEarnings: '',
      },
      filterable: ['fund', 'name', 'surname', 'paidEuroTotal'],
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2-premium adds classes runtime without a space before
      columnsClasses: {
        fund: 'table__col--fund align-middle table__col--l ',
        name: 'table__col--name align-middle table__col--s ',
        surname: 'table__col--surname align-middle table__col--s ',
        updatedDateTime: 'table__col--updatedDateTime align-middle table__col--s ',
        paidEuroTotal: 'table__col--paidEuroTotal text-right font-weight-bold align-middle table__col--xs ',
        showPayments: 'table__col--showPayments text-right font-weight-bold align-middle table__col--m ',
        showEarnings: 'table__col--showEarnings text-right font-weight-bold align-middle table__col--m ',
        customStatus: 'table__col--customStatus text-right font-weight-bold align-middle table__col--m ',
        dropdown: 'table__col--dropdown ',
      },
      orderBy: {
        ascending: false,
        column: 'updatedDateTime',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };

    paymentColumns = [
      'paymentDateTimeToShow', 'providerData.metadata.euroAmount', 'sharesAmount', 'type', 'orderId', 'code', 'referral', 'coupon', 'serials',
      'provider', 'providerData.status', 'updatedDateTime', 'customStatus', 'dropdown',
    ];
    paymentOptions = {
      headings: {
        paymentDateTimeToShow: 'Payment date',
        'providerData.metadata.euroAmount': 'Total (€)',
        sharesAmount: 'Shares',
        type: 'Type',
        orderId: 'Order ID',
        code: 'Gift code',
        referral: 'Referral code',
        coupon: 'Coupon code',
        serials: 'Serials',
        provider: 'Payment channel',
        'providerData.status': 'Status',
        // activityStatus: '',
        // endDateTime: 'End date',
        updatedDateTime: 'Last updated',
        customStatus: 'Custom status',
        dropdown: '',
      },
      filterable: false,
      sortable: ['paymentDateTimeToShow', 'providerData.metadata.euroAmount', 'sharesAmount', 'dividendsFormat', 'updatedDateTime'],
      orderBy: {
        ascending: false,
        column: 'paymentDateTimeToShow',
      },
      customSorting: {
        dividendsFormat(ascending) {
          return (a, b) => {
            const [yearA, intA] = a.dividendsFormat;
            const [yearB, intB] = b.dividendsFormat;
            const aBigger = (yearA > yearB) || (yearA === yearB && intA >= intB);
            if (ascending) {
              return aBigger ? 1 : -1;
            }
            // descending
            return !aBigger ? 1 : -1;
          };
        },
      },
      // columnsClasses strings need to have a space at the end
      // because vue-tables-2-premium adds classes runtime without a space before
      columnsClasses: {
        dropdown: 'table__col--dropdown align-middle ',
      },
      skin: 'table table-sm table-nowrap card-table table--fixed', // This will add CSS classes to the main table
    };

    investmentExtraInfoData: {
        investorId?: string,
        investorName?: string,
        assetId?: string,
        fundName?: string,
        totalInvested?: number
    } | null = null;
    selectedInvestment: { [key: string]: any } = {};
    selectedPayment: Payment | null = null;
    paymentAction: 'end' | 'remove' | 'markAsPaid' | 'refund' | 'cancel-subscription' | null = null;
    selectedPaymentFromSubscription: { [key: string]: any } = {};
    selectedSubscription: any[] = [];

    @Action setFilter!: (data: { collection: string, field: string, value: string | boolean }) => {};
    @Action resetFilters!: (data: { collection: string }) => {};
    @Action bindFirestoreReference!: Function;
    @Action unbindFirestoreReference!: Function;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action('cancelSubscription') cancelSubscriptionAction!: ({ mandateId, investmentId, assetId }:
        { mandateId: string, investmentId: string, assetId: string }) => Promise<void>;
    @Action('updateSubscription') updateSubscriptionAction!: (
      { mandateId, formData, investmentId, paymentId }:
        { mandateId: string, formData: any, investmentId: string, paymentId: string }
    ) => Promise<void>;

    @StateClass boundPayments!: State['boundPayments'];
    @StateClass gift!: State['gift'];
    @StateClass payment!: State['payment'];
    @StateClass filters!: State['filters'];
    @StateClass subscription!: State['subscription'];

    @Watch('payment.status')
    onNewPaymentRequestStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus !== oldStatus) {
        if (newStatus === DataContainerStatus.Success) {
          if (this.payment?.operation === 'deletePayment') {
            this.addToastMessage({
              text: 'Payment deleted',
              type: 'success',
            });
          }

          if (this.payment?.operation === 'refundPayment') {
            this.addToastMessage({
              text: 'Payment refunded',
              type: 'success',
            });
          }

          this.showModal = false;
        } else if (newStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: this.payment?.error?.message || 'Payment action error.',
            type: 'danger',
          });
        }
      }
    }

    @Watch('gift.status')
    onNewGiftRequestStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus !== oldStatus) {
        if (newStatus === DataContainerStatus.Success) {
          if (this.gift?.operation === 'deleteGift') {
            this.addToastMessage({
              text: 'Gift deleted',
              type: 'success',
            });
          }

          if (this.gift?.operation === 'refundGift') {
            this.addToastMessage({
              text: 'Gift refunded',
              type: 'success',
            });
          }

          this.showModal = false;
        } else if (newStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: this.gift?.error?.message || 'Gift action error.',
            type: 'danger',
          });
        }
      }
    }

    @Watch('subscription.status')
    onNewSubscriptionRequestStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus !== oldStatus) {
        if (newStatus === DataContainerStatus.Success) {
          if (this.subscription?.operation === 'cancelSubscription') {
            this.isCancelling = true;
            this.addToastMessage({
              text: 'Subscription canceled',
              type: 'success',
            });
          }

          if (this.subscription?.operation === 'updateSubscription') {
            this.isUpdating = true;
            this.addToastMessage({
              text: 'Subscription updated',
              type: 'success',
            });
          }

          this.isUpdating = false;
          this.isCancelling = false;
        } else if (newStatus === DataContainerStatus.Error) {
          this.addToastMessage({
            text: this.subscription?.error?.message || 'Subscription action error.',
            type: 'danger',
          });
        }
      }
    }

    get isLoading(): boolean {
      return this.gift?.status === DataContainerStatus.Processing || this.payment?.status === DataContainerStatus.Processing
        || this.subscription?.status === DataContainerStatus.Processing;
    }

    setTotalEarnings(totalEarnings): void {
      this.totalEarnings = totalEarnings;
    }

    removeSelectedEarnings(): void {
      if (this.totalEarnings) {
        this.totalEarnings = null;
      }
    }

    modifySubscription(paymentData: any, sub: [string, { status: string, payment: any}]): void {
      this.selectedPaymentFromSubscription = paymentData;
      this.selectedSubscription = sub;
      this.openModifySubscription = true;
    }

    closeSubscriptionModal(): void {
      this.openModifySubscription = false;
      this.openCancelSubscription = false;
      this.selectedPaymentFromSubscription = {};
      this.selectedSubscription = [];
    }

    cancelSubscription(paymentData: any, sub: [string, string]): void {
      this.selectedPaymentFromSubscription = paymentData;
      this.selectedSubscription = sub;
      this.openCancelSubscription = true;
    }

    mounted(): void {
      this.loadingInvestments = true;
      this.loadingFilters = true;

      // Loading investments
      this.investmentsUnsubscribe = InvestmentsAll.parseWheres(
        [
          ...this.investmentsQueryObject.where || [],
        ],
        bloqifyFirestore.collection('investments')
          .orderBy('updatedDateTime', 'desc'),
      ).onSnapshot((snapshot): void => {
        this.investments = snapshot.docs.map((doc): Investment => ({ ...doc.data() as Investment, id: doc.id }));

        // We want to deactivate the spinner only when all the data is there,
        // not only when the cache data loaded is there
        if (!snapshot.metadata.fromCache) {
          this.loadingInvestments = false;
        }
      });

      // Loading investors
      this.investorsUnsubscribe = bloqifyFirestore.collection('investors')
        .orderBy('surname', 'asc')
        .onSnapshot((snapshot): void => {
          this.investors = snapshot.docs.map((doc): Investor => {
            const investor: Investor = {
              ...doc.data() as Investor,
              id: doc.id,
            };
            // We use Vue set because then Vue is able to track the changes via reactivity.
            // When an object is not defined, Vue can't track what's going to be inserted.
            // This is probably fixed in later versions of Vue.
            // this.investorsObject[doc.id] = investor;
            Vue.set(this.investorsObject, doc.id, investor);
            return investor;
          });
          if (!snapshot.metadata.fromCache) {
            this.loadingFilters = false;
          }
        });

      // Load assets
      this.assetsUnsubscribe = bloqifyFirestore.collection('assets').where('deleted', '==', false)
        .onSnapshot((snapshot): void => {
          this.assets = snapshot.docs.map((doc): Asset => {
            const asset: Asset = {
              ...doc.data() as Asset,
              id: doc.id,
            };
            this.assetsObject[doc.id] = asset;
            return asset;
          });
          if (!snapshot.metadata.fromCache) {
            this.loadingFilters = false;
          }
        });
    }

    beforeDestroy(): void {
      if (!this.$route.fullPath.includes('investments')) {
        this.resetFilters({ collection: 'investments' });
        this.unbindFirestoreReference({ name: 'boundPayments' });
      }
      this.investmentsUnsubscribe!();
      this.investorsUnsubscribe!();
      this.assetsUnsubscribe!();
    }

    get investmentsQueryObject(): GetCollectionParams {
      const { byAsset, byInvestor } = this.filters.investments;
      const where: any[] = [];

      if (byAsset?.value) {
        where.push(['asset', '==', bloqifyFirestore.collection('assets').doc(byAsset.value)]);
      }
      if (byInvestor?.value) {
        where.push(['investor', '==', bloqifyFirestore.collection('investors').doc(byInvestor.value)]);
      }

      return { assetId: byAsset?.value, investorId: byInvestor?.value, where };
    }

    get assetOptions(): { value: string, text: string }[] {
      let assets = [...this.assets || []];
      if (this.filters.investments.byPublished) {
        assets = assets.filter((asset): boolean => asset.published);
      }
      return assets.map((asset): any => ({ value: asset.id, text: asset.name.en, published: asset.published }));
    }

    get selectedAssetName(): string {
      if (!this.filters.investments.byAsset) {
        return '';
      }

      const foundAsset = this.assetOptions.find((opt): boolean => opt.value === this.filters.investments.byAsset!.value);
      return foundAsset ? foundAsset.text : '';
    }

    get investorOptions(): { value: string, text: string }[] {
      return this.investors.map((investor): any => ({
        value: investor.id,
        text: `#${investor.customId} ${investor.name || ''} ${investor.surname || ''}`,
      }));
    }

    get selectedInvestorName(): string {
      if (!this.filters.investments.byInvestor) {
        return '';
      }

      const foundInvestor = this.investorOptions.find((opt): boolean => opt.value === this.filters.investments.byInvestor!.value);
      return foundInvestor ? foundInvestor.text : '';
    }

    get investmentData(): InvestmentInTable[] {
      if (!this.assets.length) {
        return [];
      }

      return this.investments.reduce((prev, inv): InvestmentInTable[] => {
        const { asset: assetRef, investor: investorRef } = inv;
        const asset = this.assetsObject[assetRef.id!];

        if (!asset) {
          return prev;
        }

        if (!investorRef.id) {
          return prev;
        }

        const investor = this.investorsObject[investorRef.id];
        if (!investor) { // this can happen when the timing is not right
          return prev;
        }
        const { id, name, surname } = investor;

        if ((this.filters.investments.byPublished && !asset.deleted && asset.published)
          || (!this.filters.investments.byPublished && !asset.deleted)
        ) {
          prev.push({
            ...inv,
            investor: { id, name, surname },
            // @ts-ignore
            asset,
          });
        }

        return prev;
      }, [] as InvestmentInTable[]);
    }

    get paymentData(): any {
      return (this.boundPayments as Payment[])
        .map((payment: Payment): any => ({
          ...payment,
          id: payment.id,
          investment: {
            ...payment.investment,
            id: payment.investment.id,
          },
          // @ts-ignore
          ...payment.trees && { trees: (payment.trees as Tree[]).map((t) => t.id) },
          paymentDateTimeToShow: this.getPaymentDate(payment),
          // calculated here sharesAmount and add it to the object so that sorting works
          sharesAmount: payment.providerData.metadata.sharesAmount,
        }));
    }

    /**
     * Returns whether current browser allows file downloads.
     */
    get fileDownloadIsSupported(): boolean {
      try {
        return !!new Blob();
      } catch (e) {
        return false;
      }
    }

    updateModifySubscriptionData(data: {
        modifySubDate: Date;
        bankAccount: string;
        mandateBankAccount: string,
        sharesAmount: number;
        sharesPrice: number;
    }): void {
      this.modifySubscriptionData = {
        ...data,
      };
    }

    inputInvestorFilter(selectedObject): void {
      selectedObject = selectedObject || false;
      this.selectFilter('byInvestor', selectedObject);
    }

    inputAssetFilter(selectedObject): void {
      selectedObject = selectedObject || false;
      this.selectFilter('byAsset', selectedObject);
    }

    // Hide text, show table and send request
    getFilteredInvestments(reQuery: boolean): void {
      this.selectedInvestment = {};
      this.removeSelectedEarnings();
      this.view = 'investments';

      if (reQuery) {
        this.investmentsUnsubscribe!();
        this.loadingInvestments = true;
        this.investmentsUnsubscribe = InvestmentsAll.parseWheres(
          [
            ...this.investmentsQueryObject.where || [],
          ],
          bloqifyFirestore.collection('investments')
            .orderBy('updatedDateTime', 'desc'),
        ).onSnapshot((snapshot): void => {
          this.investments = snapshot.docs.map((doc): Investment => ({ ...doc.data() as Investment, id: doc.id }));
          this.loadingInvestments = false;
        });
        return;
      }

      this.unbindFirestoreReference({ name: 'boundPayments' });
    }

    selectFilter(field: string, value: string | boolean): void {
      this.setFilter({ collection: 'investments', field, value });
    }

    getPaymentDate(payment: Payment): Date | string {
      return timestampToDate(payment.paymentDateTime as firebase.firestore.Timestamp) || 'n.a.';
    }

    /**
     * Get only subscripts which keys are real mandate-ids
     */
    get paymentsSubscriptions(): [string, any][] {
      const subObj = (this.paymentData as Payment[]).reduce((prev, payment): { [key: string]: { payment: any, status: string } } => {
        const status = payment.mandateId && (payment.investment as Investment).subscriptions?.[payment.mandateId] as (string | undefined);
        if (status && !prev[payment.mandateId!] && payment.mandateId!.startsWith('IO')) {
          prev[payment.mandateId!] = {
            payment,
            status,
          };
        }
        return prev;
      }, {} as { [key: string]: { payment: any, status: string } });
      return Object.entries(subObj);
    }

    get activeSubscriptions(): number {
      const subscriptions = this.selectedInvestment.subscriptions;
      return subscriptions ? Object.values(subscriptions).filter((status) => status === 'active').length : 0;
    }

    // Reset all fields and reload the list
    async resetAndReload(): Promise<void> {
      this.selectedInvestment = {};
      this.investmentsUnsubscribe!();
      this.loadingInvestments = true;
      this.investmentsUnsubscribe = bloqifyFirestore.collection('investments')
        .orderBy('updatedDateTime', 'desc')
        .onSnapshot((snapshot): void => {
          this.investments = snapshot.docs.map((doc): Investment => ({ ...doc.data() as Investment, id: doc.id }));
          this.loadingInvestments = false;
          this.resetFilters({ collection: 'investments' });
        });
    }

    showPayments(investment: { [key: string]: any }): void {
      this.selectedInvestment = { ...investment };
      // We don't want to lose the non-enumerable id coming from the bindings
      Object.defineProperty(this.selectedInvestment, 'id', { value: investment.id });
      this.loadingPayments = this.bindFirestoreReference({
        name: 'boundPayments',
        ref: bloqifyFirestore.collection('investments')
          .doc(investment.id).collection('payments')
          .where('deleted', '==', false)
          .orderBy('createdDateTime', 'desc'),
        options: {
          maxRefDepth: 1,
        },
      });
      this.view = 'payments';
    }

    @Watch('investments')
    investmentsExtraInfo(): void {
      this.investmentExtraInfoData = null;
      if (this.filters.investments.byInvestor) {
        this.investmentExtraInfoData = {
          investorName: this.filters.investments.byInvestor.text,
          investorId: this.filters.investments.byInvestor.value,
        };
      }
      if (this.filters.investments.byAsset) {
        this.investmentExtraInfoData = {
          ...this.investmentExtraInfoData,
          assetId: this.filters.investments.byAsset.value,
          fundName: this.filters.investments.byAsset.text,
        };
      }
      if (this.investmentExtraInfoData) {
        let totalInvested: number = 0;
        totalInvested = this.investments.reduce((sum, investment): number => (investment.paidEuroTotal ? sum + investment.paidEuroTotal : sum), 0);
        this.investmentExtraInfoData = {
          ...this.investmentExtraInfoData,
          totalInvested,
        };
      }
    }

    showEarnings(investment: { [key: string]: any }): void {
      this.view = 'earnings';
      this.selectedInvestment = { ...investment };
      // We don't want to lose the non-enumerable id coming from the bindings
      Object.defineProperty(this.selectedInvestment, 'id', { value: investment.id });
    }

    selectPaymentAction(paymentId: string, action: 'end' | 'remove' | 'markAsPaid' | 'refund' | 'cancel-subscription'): void {
      // find the actual member of payments in order to pass down the actual payment object, thus de-coupling it from changes in the table
      // the paymentId is selected from the payments table
      this.selectedPayment = this.paymentData.find((payment: Payment) => payment.id === paymentId);
      this.paymentAction = action;
      this.showModal = true;
    }

    /**
     * Function that adds a wheres into a single query/ref
     */
    static parseWheres(
      wheres: [string | firebase.firestore.FieldPath, firebase.firestore.WhereFilterOp, any][],
      ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData>,
    ): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> | firebase.firestore.Query<firebase.firestore.DocumentData> {
      wheres.forEach((where): void => {
        ref = ref.where(...where);
      });
      return ref;
    }

    /**
     * Execute fund investors download.
     */
    async exportAllInvestments(): Promise<void> {
      this.generatingExcel = true;
      const instance = await firebaseAxiosInstance();

      try {
        const res = await instance.get(
          'exportInvestments',
          {
            responseType: 'blob',
          },
        );

        FileSaver.saveAs(res.data, 'investments.xlsx');
      } catch (err) {
        // As the error will be of type Blob we need to parse it to text first
        // see https://developer.mozilla.org/en-US/docs/Web/API/Blob/text
        let errorText;

        try {
          // @ts-ignore
          errorText = await err.response.data.text();
        } catch (e) {
          errorText = 'There was a problem generating the Excel.';
        }

        this.addToastMessage({
          text: errorText,
          type: 'danger',
        });
      } finally {
        this.generatingExcel = false;
      }
    }
  }
