
  import { Component, Prop, Vue, Ref } from 'vue-property-decorator';

  @Component({})
  export default class Lightbox extends Vue {
    @Prop({ default: () => [] }) images!: string[];
    @Prop({ default: '' }) document!: string;

    @Ref('embed') readonly embed!: HTMLEmbedElement;
    @Ref('image') readonly image!: HTMLImageElement;

    isLoading = false
    currentImagesIndex = 0

    handleNext() {
      if (this.currentImagesIndex < (this.images.length - 1)) {
        this.currentImagesIndex += 1;
      }
    }

    handlePrevious() {
      if (this.currentImagesIndex > 0) {
        this.currentImagesIndex -= 1;
      }
    }

    mounted() {
      if (this.embed || this.image) {
        this.isLoading = true;

        (this.embed || this.image).addEventListener('load', (e) => {
          this.isLoading = false;
        });
      }
    }
  }
