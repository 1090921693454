
  import { bloqifyStorage } from '@/boot/firebase';
  import { Action, State as StateClass } from 'vuex-class';
  import { Component, Vue } from 'vue-property-decorator';
  import { ValidationObserver } from 'vee-validate';
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import FileSaver from 'file-saver';
  import { Asset } from '@/models/assets/Asset';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import { DataContainerStatus } from '@/models/Common';
  import { State } from '@/models/State';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import { AssetEarning } from '@/models/assets/Earnings';
  import EarningModal from '@/components/assets/EarningModal.vue';
  import UploadConfirmationModal from '@/components/assets/assetFinancials/UploadConfirmationModal.vue';

@Component({
  components: {
    ValidationObserver,
    FormInput,
    FormDatePicker,
    EarningModal,
    UploadConfirmationModal,
  },
})
  export default class Earning extends Vue {
  @StateClass('boundAsset') asset!: Asset;
  @StateClass('boundEarnings') boundEarnings!: AssetEarning[];
  @StateClass('earning') stateEarning!: State['earning'];

  @Action(addToastMessage) addToastMessage!: (payload: { text: string; type: string }) => void;
  @Action bindFirestoreReferences!: (payload: { name: string; ref: unknown }[]) => Promise<void>;
  @Action unbindFirestoreReferences!: (payload: { name: string }[]) => void;

  loadingPromise: Promise<void> = Promise.resolve();
  FormIcons = FormIcons;
  showEarningModal = false;
  selectedEarning: Earning | null = null;
  modeEarningModal: 'add' | 'delete' = 'add';
  showUploadConfirmationModal = false;
  isDownloadProcessing = false;

  get assetId(): string {
    return this.$route.params.assetId;
  }

  get defaultDate(): Date {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    return today;
  }

  earningColumns = ['earningDateTime', 'amount', 'description', 'download', 'delete'];
  earningOptions = {
    headings: {
      earningDateTime: 'Date',
      amount: 'Amount',
      description: 'Description',
      download: '',
      delete: '',
    },
    texts: {},
    sortable: ['earningDateTime', 'description', 'amount'],
    filterable: ['amount'],
    columnsClasses: {
      earningDateTime: 'list__filter--date align-middle',
      amount: 'list__filter--amount align-middle',
      description: 'list__filter--description align-middle',
      download: 'list__filter--download align-middle',
      delete: 'list__filter--delete align-middle',
    },
    skin: 'table table-sm table-nowrap card-table', // This will add CSS classes to the main table
    customSorting: {
      earningDateTime(ascending): (a: AssetEarning, b: AssetEarning) => number {
        return (a, b): number => {
          const aDate = a.earningDateTime.toDate().getTime();
          const bDate = b.earningDateTime.toDate().getTime();

          if (aDate === bDate) {
            return 0;
          }

          if (ascending) {
            return aDate < bDate ? 1 : -1;
          }

          return aDate < bDate ? -1 : 1;
        };
      },
    },
  };

  get earningData(): AssetEarning[] {
    return this.boundEarnings.map(({ id, ...rest }) => ({ id, ...rest }));
  }

  async downloadDocument(item: AssetEarning): Promise<void> {
    this.isDownloadProcessing = true;
    const storageRef = bloqifyStorage.ref().child(item.file!);
    const downloadUrl = await storageRef.getDownloadURL();
    FileSaver.saveAs(downloadUrl, storageRef.name);
    this.isDownloadProcessing = false;
  }

  get isProcessing(): boolean {
    return this.stateEarning?.status === DataContainerStatus.Processing;
  }

  async onDownloadEarning(item: { document: string }): Promise<void> {
    const storageRef = bloqifyStorage.ref();
    const downloadUrl = await storageRef.child(item.document).getDownloadURL();

    FileSaver.saveAs(downloadUrl, downloadUrl);
  }

  openEarningsModal(earning: Earning | null, mode: 'add' | 'delete') {
    this.showEarningModal = true;
    this.modeEarningModal = mode;
    this.selectedEarning = earning;
  }

  handleUploadConfirmationModal(): void {
    this.showUploadConfirmationModal = true;
  }

  get currentLang(): string {
    return this.$route.params.lang;
  }
  }
