
  import firebase from 'firebase/app';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import { Getter } from 'vuex-class';
  import { convertUTCToLocalDate } from '@/filters/date';
  import { Payment, PaymentProvider, PaymentStatus, PaymentType } from '@/models/investments/Investment';
  import { ManagerRole } from '@/models/manager/Manager';
  import SerialsModal from './SerialsModal.vue';

  @Component({
    components: {
      SerialsModal,
    },
  })
  export default class PaymentsTable extends Vue {
    @Prop() columns: any
    @Prop() data: any
    @Prop() options: any
    @Prop() selectedInvestment: any

    @Getter getCurrentManagerRole!: ManagerRole;

    selectedPayment: Payment | null = null;
    showModal = false;

    /**
     * Allow end payment.
     */
    get isAdmin(): boolean {
      return this.getCurrentManagerRole === ManagerRole.Superadmin || this.getCurrentManagerRole === ManagerRole.Admin;
    }

    isRemovable(payment: Payment): boolean {
      return payment.providerData.status !== PaymentStatus.Open && payment.providerData.status !== PaymentStatus.Pending;
    }

    isRefundable(payment: Payment): boolean {
      return payment.provider === PaymentProvider.PayNL && payment.providerData.status === PaymentStatus.Paid;
    }

    isModifiable(payment: Payment): boolean {
      return payment.providerData.status === PaymentStatus.Paid && payment.type === PaymentType.OneOff;
    }

    isMarkableAsPaid(payment: Payment): boolean {
      return payment.providerData.status === PaymentStatus.Cancel || payment.providerData.status === PaymentStatus.Expired
        || payment.providerData.status === PaymentStatus.Declined || payment.providerData.status === PaymentStatus.Refund;
    }

    isCancelable(payment: Payment): boolean {
      return payment.provider === PaymentProvider.PayNL && payment.providerData.status === PaymentStatus.Paid
        && payment.type === PaymentType.Subscription && !!payment.mandateId;
    }

    /**
     * Determines whether any action can be done on the given payment
     */
    isActionable(payment: Payment): boolean {
      return this.isRemovable(payment) || this.isModifiable(payment) || this.isRefundable(payment) || this.isMarkableAsPaid(payment);
    }

    isEndDatePassed(payment: Payment): boolean {
      if (!payment.ended) {
        return false;
      }

      // Transforming the ended Timestamp to milliseconds to compare it with the current date (also in ms)
      const endDate = convertUTCToLocalDate(payment.ended as firebase.firestore.Timestamp)!;
      return endDate.getTime() <= Date.now();
    }

    openSerialsModal(payment: Payment): void {
      this.selectedPayment = payment;
      this.showModal = true;
    }
  }
