
  import { Vue, Component, Prop } from 'vue-property-decorator';
  import Modal from '@/components/common/Modal.vue';
  import { Payment } from '@/models/investments/Investment';

  @Component({
    components: {
      Modal,
    },
  })
  export default class PaymentActionModal extends Vue {
    @Prop({ default: undefined }) serials!: Payment['trees'];
  }
