
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import FormFileInput from '@/components/common/form-elements/FormFileInput.vue';
  import FormFileInputLabel from '@/components/common/form-elements/FormFileInputLabel.vue';

  @Component({
    components: {
      FormFileInput,
      FormFileInputLabel,
    },
  })
  export default class UploadResource extends Vue {
    @Prop({ default: true }) value!: { file: any };
    @Prop() required!: boolean;
    @Prop({ default: false }) loading!: boolean;

    edit = false
  }
