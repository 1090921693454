
  import { ValidationProvider } from 'vee-validate';
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { getNames } from 'i18n-iso-countries';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
  import { FormIcons } from '@/components/common/form-elements/FormInput.vue';

  @Component({
    components: {
      FormInvalidMessage,
      ValidationProvider,
    },
    inheritAttrs: false,
  })

  export default class FormSelect extends Vue {
    @Prop({ required: true }) name!: string;
    @Prop() placeholder: string | undefined;
    @Prop() rules!: string;
    @Prop() label!: string;
    @Prop() value!: any;
    @Prop() options!: { value: any, text: string }[];
    @Prop({ default: '' }) icon!: FormIcons;
    @Prop({ default: true }) bails!: boolean;
    @Prop({ default: false }) hasCountriesAsOptions!: boolean
    @Prop({ default: 'code' }) countryValueType!: 'name' | 'code'

    get formattedOptions() {
      if (this.hasCountriesAsOptions) {
        const lang: string = 'en';
        const names = getNames(lang);

        return Object.entries(names).map((pair) => {
          const code: string = pair[0];
          const name: string = pair[1];
          const values = {
            code,
            name,
          };

          return {
            value: values[this.countryValueType],
            text: name,
            ...((this.value === code || this.value === name) && {
              selected: true,
            }),
          };
        });
      }

      return this.options;
    }

    inputEvent(target) {
      this.$emit('input', target.value);
    }
  }
