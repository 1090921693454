
  import { Payment } from '@/models/investments/Investment';
  import { Component, Vue, Prop } from 'vue-property-decorator';
  import moment from 'moment';

  @Component({})
  export default class SubscriptionsCard extends Vue {
    isInactive = true;

    @Prop() modifySub: any;
    @Prop() cancelSub: any;
    @Prop() data: any;
    @Prop() subscription!: [string, { status: string, payment: any}];

    get payment(): Payment {
      return this.subscription[1].payment;
    }

    get statusColor(): any {
      if (this.subscription[1].status === 'active') {
        return 'status__active';
      }

      return 'status__inactive';
    }

    get paymentDate(): any {
      return this.payment.paymentDateTime ? moment((this.payment.paymentDateTime).toDate()).format('DD/MM/YYYY') : '-';
    }

    // When theres a processDate, theres no need to sum a month since that's the exact date of processing the payment
    get nextPaymentDate(): any {
      const momentDate = moment((this.payment.paymentDateTime || this.payment.processDate || this.payment.createdDateTime).toDate());
      const manipulation = !this.payment.paymentDateTime && this.payment.processDate ? momentDate : momentDate.add(1, 'month');
      return manipulation.format('DD/MM/YYYY');
    }

    get paymentStatus(): any {
      if (this.subscription[1].status === 'active') {
        return '';
      }

      return 'payment__inactive';
    }

    get subscriptionDisabled(): boolean {
      if (this.subscription[1].status === 'active') {
        return this.isInactive === false;
      }

      return this.isInactive === true;
    }

    get bonds(): string {
      if (this.payment.providerData.metadata.sharesAmount === 1) {
        return 'Share';
      }

      return 'Shares';
    }
  }
