
  import { Component, Prop, Vue } from 'vue-property-decorator';

  @Component
  export default class Modal extends Vue {
    @Prop({ default: true }) closable!: boolean;

    mounted(): void {
      window.addEventListener('keyup', this.handleEscape);
    }

    beforeDestroy(): void {
      window.removeEventListener('keyup', this.handleEscape);
    }

    handleEscape(event): void {
      // escape key
      if (event.which === 27) {
        this.$emit('close');
      }
    }
  }
