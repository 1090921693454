
  import { Component, Vue } from 'vue-property-decorator';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import to from 'await-to-js';
  import { Action } from 'vuex-class';
  import FormInput from '@/components/common/form-elements/FormInput.vue';
  import { AnswerType, Questionnaire as QuestionnaireType, QuestionnaireQuestion } from '@/models/users/Questionnaire';
  import FormSelect from '@/components/common/form-elements/FormSelect.vue';
  import { bloqifyFirestore, firebase } from '@/boot/firebase';
  import FormEditor from '@/components/common/form-elements/FormEditor.vue';

  type Toaster = { text: string, type: 'info' | 'success' | 'warning' | 'danger', dismissAfter?: number };

  @Component({
    components: {
      FormEditor,
      FormSelect,
      FormInput,
      ValidationObserver,
    },
  })
  export default class Questionnaire extends Vue {
    @Action(addToastMessage) addToastMessage!: (arg0: Toaster) => any;

    loading = false;
    otherChanges = false;
    typeOptions: { value: AnswerType, text: string }[] = [
      { value: 'both', text: 'File and Text' },
      { value: 'file', text: 'File' },
      { value: 'text', text: 'Text' },
    ];
    questions: QuestionnaireType['questions'] = [
      {
        question: {
          en: '',
          nl: '',
        },
        type: 'both',
      },
    ];

    declaration: QuestionnaireType['declaration'] = {
      en: '',
      nl: '',
    };

    async mounted(): Promise<void> {
      const [getQuestionsError, getQuestions] = await to(bloqifyFirestore
        .collection('settings')
        .doc('identification')
        .collection('questionnaireQuestions')
        .orderBy('createdDateTime', 'desc')
        .limit(1)
        .get());

      if (getQuestionsError || !getQuestions || !getQuestions.docs[0]) {
        this.addToastMessage({ dismissAfter: 1000, text: 'error loading questionnaire questions', type: 'danger' });
      } else {
        const q = getQuestions.docs[0].data() as QuestionnaireType;
        this.declaration = q.declaration;
        this.questions = q.questions;
      }
    }

    addQuestion(): void {
      this.questions.push({
        question: {
          en: '',
          nl: '',
        },
        type: 'both',
      });
      this.otherChanges = true;
    }

    deleteQuestion(index: number) {
      this.questions.splice(index, 1);
      this.otherChanges = true;
    }

    async submit(): Promise<void> {
      this.loading = true;
      const toSave: QuestionnaireType = {
        createdDateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        declaration: this.declaration,
        questions: this.questions,
      };
      const [getQuestionsError, getQuestions] = await to(bloqifyFirestore
        .collection('settings')
        .doc('identification')
        .collection('questionnaireQuestions')
        .add(toSave));
      this.loading = false;
      if (getQuestionsError) {
        this.addToastMessage({ text: 'Error saving the questionnaire', type: 'danger' });
        return;
      }
      this.addToastMessage({ text: 'Saved the questionnaire', type: 'success' });
    }
  }
