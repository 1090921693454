
  import { Component, Vue } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { State } from '@/models/State';
  import TracingTable from '@/components/tracing/TracingTable.vue';
  import { GetCollectionParams } from '@/store/actions';

  @Component({
    components: {
      TracingTable,
    },
  })

  export default class Tracing extends Vue {
    @StateClass tracing!: State['tracing'];

    /**
     * On creation.
     */
    created(): void {
      // this.getTracing();
    }
  }
