
  import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { ValidationObserver } from 'vee-validate';
  import { CurrentManager } from '@/models/manager/CurrentManager';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import FormInput from '@/components/common/form-elements/FormInput.vue';

  @Component({
    components: {
      FormInput,
      ValidationObserver,
    },
  })
  export default class TabSecurity extends Vue {
    current: string = '';
    password: string = '';
    confirmation: string = '';

    @Getter getCurrentManager!: CurrentManager;
    @Action changePassword!: Function;
    @Action(addToastMessage) addToastMessage!: Function;
    @StateClass manager!: State['manager'];
    @StateClass securitySettings!: State['securitySettings'];

    @Ref('form') readonly form!: HTMLFormElement;

    @Watch('securitySettings', { deep: true })
    onSecuritySettingsChanged(newState: State['securitySettings'], oldState: State['securitySettings']): void {
      // Error
      if (newState.status && newState.status === DataContainerStatus.Error) {
        this.addToastMessage({
          text: newState.error,
          type: 'danger',
        });
      }

      // Success
      if (newState.status && newState.status === DataContainerStatus.Success) {
        // Reset form to initial state, and prevent
        this.current = '';
        this.password = '';
        this.confirmation = '';

        this.addToastMessage({
          text: 'Your password has been updated.',
          type: 'success',
        });
        this.$nextTick(() => {
          this.form.reset();
        });
      }
    }

    /**
     * Returns whether we're loading data.
     */
    get loading(): boolean {
      return this.manager === null;
    }
  }
