import { Module } from 'vuex';
import to from 'await-to-js';
import { times } from 'lodash';
import ShortUniqueId from 'short-unique-id';
import moment from 'moment';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase, bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { GiftCode, Payment, PaymentProvider, PaymentStatus, PaymentType } from '@/models/investments/Investment';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';
import { multipleTransactionAction } from '../utils/firebase';

const SET_GIFT = 'SET_GIFT';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_GIFT](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createGift(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        insertedSharesAmount,
        inputGiftCode,
      }: {
        amount: number,
        assetId: string,
        investorId: string,
        paymentDateTime: number, // milliseconds
        insertedSharesAmount: number,
        inputGiftCode: string,
      },
    ): Promise<void> {
      commit(SET_GIFT, { status: DataContainerStatus.Processing, operation: 'createGift' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [giftError, giftSuccess] = await to(bloqifyFirestore.collection('gifts').where('code', '==', inputGiftCode).get());
          if (giftError) {
            throw giftError;
          }

          if (!giftSuccess!.empty) {
            throw Error(`Gift code ${inputGiftCode} is already in the database.`);
          }

          const giftCodeRef = bloqifyFirestore.collection('gifts').doc();
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

          const [getInvestorError, investor] = await to(transaction.get(investorRef));
          if (getInvestorError) {
            throw getInvestorError;
          }

          const investorEmail = investor!.get('email') as string;
          const investorName = investor!.get('name') as string;

          const [investmentsError, investments] = await to(
            bloqifyFunctions.httpsCallable('getInvestments')({
              investorId,
              assetId,
            }),
          );
          if (investmentsError) {
            throw investmentsError;
          }

          const { found: foundInvestments, investmentId } = investments!.data;

          const investmentRef = foundInvestments
            ? bloqifyFirestore.collection('investments').doc(investmentId)
            : bloqifyFirestore.collection('investments').doc();

          const paymentRef = investmentRef.collection('payments').doc();

          const dateNow = firebase.firestore.FieldValue.serverTimestamp();
          const paymentDateTimeFirestore = firebase.firestore.Timestamp.fromMillis(paymentDateTime);
          const expirationDate = firebase.firestore.Timestamp.fromDate(moment(paymentDateTime).clone().add(2, 'years').toDate());

          const payment: Payment = {
            investment: investmentRef,
            provider: PaymentProvider.Custom,
            providerData: {
              id: paymentRef.id,
              amount: {
                value: amount.toString(),
                currency: 'EUR',
              },
              status: PaymentStatus.Paid,
              metadata: {
                euroAmount: amount,
                sharesAmount: insertedSharesAmount,
                selectedDividendsFormatYear: ['', 0],
                investmentId: investmentRef.id,
                assetId,
                paymentId: paymentRef.id,
                uid: investorId,
              },
            },
            investor: investorRef,
            asset: assetRef,
            deleted: false,
            // @ts-ignore
            createdDateTime: dateNow,
            // @ts-ignore
            updatedDateTime: dateNow,
            // @ts-ignore
            paymentDateTime: dateNow,
            dividendsFormat: ['', 0],
            trees: [],
            type: PaymentType.GiftPurchase,
            gift: giftCodeRef,
          };

          // Initialize gift code
          transaction.set(
            giftCodeRef,
            {
              amount,
              code: inputGiftCode,
              deleted: false,
              currency: 'EUR',
              treeAmount: insertedSharesAmount,
              paymentDate: paymentDateTimeFirestore,
              expirationDate,
              orderInvestor: investorRef,
              orderInvestment: investmentRef,
              orderPayment: paymentRef,
              asset: assetRef,
              createdDateTime: dateNow,
              updatedDateTime: dateNow,
            },
          );

          if (foundInvestments) {
            // Update investment
            transaction.update(investmentRef, {
              giftSharesTotal: firebase.firestore.FieldValue.increment(insertedSharesAmount),
              giftEuroTotal: firebase.firestore.FieldValue.increment(amount),
              updatedDateTime: dateNow,
              paidPayment: firebase.firestore.FieldValue.increment(1),
            });
          } else {
            transaction.set(investmentRef, {
              giftSharesTotal: insertedSharesAmount,
              giftEuroTotal: amount,
              updatedDateTime: dateNow,
              paidPayment: firebase.firestore.FieldValue.increment(1),
              asset: assetRef,
              investor: investorRef,
              createdDateTime: dateNow,
            });
          }

          transaction.set(paymentRef, payment);
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            {
              paidPayments: firebase.firestore.FieldValue.increment(1),
              giftsBought: firebase.firestore.FieldValue.increment(1),
            },
          );

          const [sendEmailError] = await to(bloqifyFunctions.httpsCallable('giftBought')({
            email: investorEmail,
            investorName,
            code: inputGiftCode,
          }));
          if (sendEmailError) {
            throw sendEmailError;
          }
        }),
      );
      if (transactionError) {
        commit(SET_GIFT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createGift' });
        return;
      }

      commit(SET_GIFT, { status: DataContainerStatus.Success, payload: { code: inputGiftCode }, operation: 'createGift' });
    },
    async redeemGift(
      { commit },
      {
        amount,
        assetId,
        investorId,
        insertedSharesAmount,
        inputGiftCode,
      }: {
        amount: number,
        assetId: string,
        investorId: string,
        insertedSharesAmount: number,
        inputGiftCode: string,
      },
    ): Promise<void> {
      commit(SET_GIFT, { status: DataContainerStatus.Processing, operation: 'createGift' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const [giftError, giftSuccess] = await to(bloqifyFirestore.collection('gifts').where('code', '==', inputGiftCode).get());
          if (giftError) {
            throw giftError;
          }

          if (giftSuccess!.empty) {
            throw Error(`Gift code ${inputGiftCode} is not in the database.`);
          }

          const gift = giftSuccess!.docs[0].data() as GiftCode;

          if (Date.now() > (gift.expirationDate).toDate().valueOf()) {
            throw Error(`Gift code ${inputGiftCode} has expired.`);
          }

          if (gift.amount !== amount || gift.treeAmount !== insertedSharesAmount) {
            throw Error(`Gift code ${inputGiftCode} does not match the amount.`);
          }

          if (gift.claimInvestor || gift.claimInvestment || gift.claimPayment) {
            throw Error(`Gift code ${inputGiftCode} was already claimed.`);
          }

          const giftCodeRef = giftSuccess!.docs[0].ref;
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

          const [investmentError, investments] = await to(
            bloqifyFirestore.collection('investments')
              .where('investor', '==', investorRef)
              .where('asset', '==', assetRef)
              .limit(1)
              .get(),
          );
          if (investmentError) {
            throw investmentError;
          }

          const foundInvestments = !investments?.empty;
          const investmentRef = foundInvestments ? investments!.docs[0].ref : bloqifyFirestore.collection('investments').doc();

          const paymentRef = investmentRef.collection('payments').doc();

          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          // Create trees
          const treesRef = bloqifyFirestore.collection('trees');
          // @ts-ignore
          const treeOrderId = new ShortUniqueId({ length: 4, dictionary: 'number' }).randomUUID();

          const treeDocs = times(insertedSharesAmount, (index: number): firebase.firestore.DocumentReference => {
            // @ts-ignore
            const serial = new ShortUniqueId({ length: 13, dictionary: 'alpha' }).randomUUID();
            const customId = `${treeOrderId}-${index + 1}-${serial}`;
            return treesRef.doc(customId);
          });

          const treesMap = new Map();
          treeDocs.forEach((treeDoc): void => {
            treesMap.set(
              [treeDoc, 'set'],
              {
                asset: assetRef,
                investor: investorRef,
                payment: paymentRef,
                tree: treeDoc,
                createdDateTime: dateNow,
                updatedDateTime: dateNow,
              },
            );
          });

          // eslint-disable-next-line no-useless-catch
          try {
            await multipleTransactionAction(transaction, treesMap, 0, 4);
          } catch (e) {
            throw e;
          }

          const payment: Payment = {
            investment: investmentRef,
            provider: PaymentProvider.Custom,
            providerData: {
              id: paymentRef.id,
              amount: {
                value: amount.toString(),
                currency: 'EUR',
              },
              status: PaymentStatus.Paid,
              metadata: {
                euroAmount: amount,
                sharesAmount: insertedSharesAmount,
                selectedDividendsFormatYear: ['', 0],
                investmentId: investmentRef.id,
                assetId,
                paymentId: paymentRef.id,
                uid: investorId,
              },
            },
            investor: investorRef,
            asset: assetRef,
            deleted: false,
            // @ts-ignore
            createdDateTime: dateNow,
            // @ts-ignore
            updatedDateTime: dateNow,
            // @ts-ignore
            paymentDateTime: dateNow,
            dividendsFormat: ['', 0],
            trees: treeDocs,
            type: PaymentType.GiftRedeem,
            gift: giftCodeRef,
          };

          if (foundInvestments) {
            // Update investment
            transaction.update(investmentRef, {
              receivedSharesTotal: firebase.firestore.FieldValue.increment(insertedSharesAmount),
              receivedEuroTotal: firebase.firestore.FieldValue.increment(amount),
              updatedDateTime: dateNow,
            });
          } else {
            transaction.set(investmentRef, {
              receivedSharesTotal: insertedSharesAmount,
              receivedEuroTotal: amount,
              updatedDateTime: dateNow,
              asset: assetRef,
              investor: investorRef,
              createdDateTime: dateNow,
            });
          }

          transaction.update(
            giftCodeRef,
            {
              claimInvestment: investmentRef,
              claimPayment: paymentRef,
              claimInvestor: investorRef,
              updatedDateTime: dateNow,
            },
          );
          transaction.set(paymentRef, payment);
          transaction.update(
            bloqifyFirestore.collection('settings').doc('counts'),
            {
              paidPayments: firebase.firestore.FieldValue.increment(1),
              giftsClaimed: firebase.firestore.FieldValue.increment(1),
              treesCounter: firebase.firestore.FieldValue.increment(insertedSharesAmount),
            },
          );
        }),
      );
      if (transactionError) {
        commit(SET_GIFT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createGift' });
        return;
      }

      commit(SET_GIFT, { status: DataContainerStatus.Success, payload: { code: inputGiftCode }, operation: 'createGift' });
    },
    /**
     * In the gift REDEEM removal we assume the payment was PAID. In gift PURCHASE it can have any status.
     */
    async deleteGift({ commit }, { investmentId, paymentId, giftId }: { investmentId: string, paymentId: string, giftId }): Promise<void> {
      commit(SET_GIFT, { status: DataContainerStatus.Processing, operation: 'deleteGift' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);
          const giftRef = bloqifyFirestore.collection('gifts').doc(giftId);

          const [sourcePaymentError, sourcePaymentData] = await to(transaction.get(paymentRef));
          if (sourcePaymentError) {
            throw sourcePaymentError;
          }
          if (!sourcePaymentData!.exists) {
            throw Error('Gift payment does not exist.');
          }

          const [giftError, giftDocument] = await to(transaction.get(giftRef));
          if (giftError) {
            throw giftError;
          }
          if (!giftDocument!.exists) {
            throw Error('Gift does not exist.');
          }

          const isRedeem = sourcePaymentData!.get('type') === PaymentType.GiftRedeem;

          // Read logic must be before any write
          if (!isRedeem) {
            const claimPayment = giftDocument!.get('claimPayment') as firebase.firestore.DocumentReference | undefined;
            if (claimPayment) {
              const [claimPaymentError, claimPaymentDocument] = await to(transaction.get(claimPayment));
              if (claimPaymentError) {
                throw claimPaymentError;
              }
              if (!claimPaymentDocument!.exists) {
                throw Error('Claim payment does not exist.');
              }
              if (!claimPaymentDocument!.get('deleted')) {
                throw Error('The claim payment from this gift has not been deleted.');
              }
            }
          }

          const isPaid = sourcePaymentData!.get('providerData.status') === PaymentStatus.Paid;
          const euroAmount = sourcePaymentData!.get('providerData.metadata.euroAmount') as number;
          const sharesAmount = sourcePaymentData!.get('providerData.metadata.sharesAmount') as number;
          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          // Global Logic
          transaction.update(paymentRef, { deleted: true, updatedDateTime: dateNow });

          // Paid status logic
          if (isPaid) {
            // Gift Purchase Logic
            if (!isRedeem) {
              transaction.update(giftRef, { deleted: true, updatedDateTime: dateNow });
              transaction.update(investmentRef, {
                giftSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
                giftEuroTotal: firebase.firestore.FieldValue.increment(-euroAmount),
                updatedDateTime: dateNow,
                paidPayment: firebase.firestore.FieldValue.increment(-1),
              });
              transaction.update(
                bloqifyFirestore.collection('settings').doc('counts'),
                {
                  paidPayments: firebase.firestore.FieldValue.increment(-1),
                  giftsBought: firebase.firestore.FieldValue.increment(-1),
                },
              );

              return;
            }

            // Gift Redeem Logic
            const trees = sourcePaymentData!.get('trees') as firebase.firestore.DocumentReference[];

            const treesMap = new Map();
            trees.forEach((treeDoc): void => {
              treesMap.set(
                [treeDoc, 'update'],
                {
                  deleted: true,
                  updatedDateTime: dateNow,
                },
              );
            });

            // eslint-disable-next-line no-useless-catch
            try {
              await multipleTransactionAction(transaction, treesMap, 1, 3);
            } catch (e) {
              throw e;
            }

            transaction.update(giftRef, { deleted: true, updatedDateTime: dateNow });
            transaction.update(investmentRef, {
              receivedSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
              receivedEuroTotal: firebase.firestore.FieldValue.increment(-euroAmount),
              updatedDateTime: dateNow,
            });
            transaction.update(
              bloqifyFirestore.collection('settings').doc('counts'),
              {
                paidPayments: firebase.firestore.FieldValue.increment(-1),
                giftsClaimed: firebase.firestore.FieldValue.increment(-1),
                treesCounter: firebase.firestore.FieldValue.increment(-sharesAmount),
              },
            );
          }
        }),
      );
      if (transactionError) {
        commit(SET_GIFT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteGift' });
        return;
      }

      commit(SET_GIFT, { status: DataContainerStatus.Success, operation: 'deleteGift' });
    },
  },
  getters: {},
};
