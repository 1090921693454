
  import { Component, Vue } from 'vue-property-decorator';
  import Alerts from '@/components/sandbox/Alerts.vue';
  import Avatars from '@/components/sandbox/Avatars.vue';
  import Badges from '@/components/sandbox/Badges.vue';
  import Breadcrumb from '@/components/sandbox/Breadcrumb.vue';
  import Buttons from '@/components/sandbox/Buttons.vue';
  import ButtonGroup from '@/components/sandbox/ButtonGroup.vue';
  import Cards from '@/components/sandbox/Cards.vue';
  import Charts from '@/components/sandbox/Charts.vue';
  import Dropdowns from '@/components/sandbox/Dropdowns.vue';
  import Forms from '@/components/sandbox/Forms.vue';
  import Icons from '@/components/sandbox/Icons.vue';
  import Lists from '@/components/sandbox/Lists.vue';
  import Loaders from '@/components/sandbox/Loaders.vue';
  import Modals from '@/components/sandbox/Modals.vue';
  import Navs from '@/components/sandbox/Navs.vue';
  import Navbar from '@/components/sandbox/Navbar.vue';
  import PageHeaders from '@/components/sandbox/PageHeaders.vue';
  import Pagination from '@/components/sandbox/Pagination.vue';
  import Popovers from '@/components/sandbox/Popovers.vue';
  import Progress from '@/components/sandbox/Progress.vue';
  import SocialPost from '@/components/sandbox/SocialPost.vue';
  import Tables from '@/components/sandbox/Tables.vue';
  import Tooltips from '@/components/sandbox/Tooltips.vue';
  import Typography from '@/components/sandbox/Typography.vue';
  import Utilities from '@/components/sandbox/Utilities.vue';

  @Component({
    components: {
      Alerts,
      Avatars,
      Badges,
      Breadcrumb,
      Buttons,
      ButtonGroup,
      Cards,
      Charts,
      Dropdowns,
      Forms,
      Icons,
      Lists,
      Loaders,
      Modals,
      Navs,
      Navbar,
      PageHeaders,
      Pagination,
      Popovers,
      Progress,
      SocialPost,
      Tables,
      Tooltips,
      Typography,
      Utilities,
    },
  })

  export default class Sandbox extends Vue {
    showSandbox: boolean = false;

    /**
     * Component mounted.
     */
    mounted(): void {
      const environment: string = process.env.NODE_ENV as string || 'unknown';
      const onCircleCi: boolean = process.env.CIRCLECI !== undefined;

      this.showSandbox = environment !== 'production' || onCircleCi;
    }
  }
