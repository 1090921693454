
  import { Component, Vue } from 'vue-property-decorator';
  // @ts-ignore
  import List from 'list.js/src/index';

  // Make globally available as List()
  // @ts-ignore
  window.List = List;

  @Component
  export default class Lists extends Vue {
    /**
     * Component mounted. Import after mounting to
     * have DOM elements available for jQuery.
     */
    mounted(): void {
      // @ts-ignore
      // eslint-disable-next-line babel/no-unused-expressions
      import('@/../vendor/dashkit/src/assets/js/list');
    }
  }
