
  import { Component, Vue } from 'vue-property-decorator';

  @Component
  export default class Popovers extends Vue {
    /**
     * Component mounted. Import after mounting to
     * have DOM element available for jQuery.
     */
    mounted(): void {
      // @ts-ignore
      // eslint-disable-next-line babel/no-unused-expressions
      import('@/../vendor/dashkit/src/assets/js/popover');
    }
  }
