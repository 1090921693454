import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, firebase } from '@/boot/firebase';
import { Investor } from '@/models/users/User';
import { DataContainerStatus } from '@/models/Common';
import { InvestmentSubscriptionStatus, Payment, PaymentProvider, PaymentStatus, PaymentType } from '@/models/investments/Investment';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';

const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION';

type FormData = {
  modifySubDate: string; // dd-mm-yyyy
  bankAccount: string;
  mandateBankAccount: string;
  sharesAmount: number;
  sharesPrice: number;
}

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_SUBSCRIPTION](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async getSubscription(
      { commit },
      { mandateId, assetId }: { mandateId: string, assetId: string },
    ): Promise<void> {
      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Processing, operation: 'getSubscription' });

      const [subscriptionPaynlError, subscriptionData] = await to(
        bloqifyFunctions.httpsCallable('getSubscriptionFromPaynl')({
          mandateId,
          assetId,
        }),
      );
      if (subscriptionPaynlError) {
        commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Error, payload: subscriptionPaynlError, operation: 'getSubscription' });
        return;
      }

      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Success, payload: subscriptionData, operation: 'getSubscription' });
    },
    async createSubscription(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        insertedSharesAmount,
      }: {
        amount: number,
        assetId: string,
        investorId: string,
        paymentDateTime: string, // dd-mm-yyyy
        insertedSharesAmount: number,
      },
    ): Promise<void> {
      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Processing, operation: 'createSubscription' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

          const [investmentsError, investments] = await to(
            bloqifyFunctions.httpsCallable('getInvestments')({
              investorId,
              assetId,
            }),
          );
          if (investmentsError) {
            throw investmentsError;
          }

          const { found: foundInvestments, investmentId } = investments!.data;

          const investmentRef = foundInvestments
            ? bloqifyFirestore.collection('investments').doc(investmentId)
            : bloqifyFirestore.collection('investments').doc();

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }

          const investor = readInvestorSuccess!.data() as Investor;

          const paymentRef = investmentRef.collection('payments').doc();

          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          const payment: Payment = {
            investment: investmentRef,
            provider: PaymentProvider.PayNL,
            providerData: {
              id: paymentRef.id,
              amount: {
                value: amount.toString(),
                currency: 'EUR',
              },
              status: PaymentStatus.Pending,
              metadata: {
                euroAmount: amount,
                sharesAmount: Number(insertedSharesAmount),
                selectedDividendsFormatYear: ['', 0],
                investmentId: investmentRef.id,
                assetId,
                paymentId: paymentRef.id,
                uid: investorId,
              },
            },
            investor: investorRef,
            asset: assetRef,
            deleted: false,
            // @ts-ignore
            createdDateTime: dateNow,
            // @ts-ignore
            updatedDateTime: dateNow,
            dividendsFormat: ['', 0],
            trees: [],
            processDate: firebase.firestore.Timestamp.fromDate(moment(paymentDateTime, 'DD-MM-YYYY').toDate()),
            type: PaymentType.StartSubscription,
          };

          // We should check if the stored bankaccount has the proper format, if not we'll use the one stored on the profile
          const bankAccountNumber = investor.usedBankAccounts?.length && !investor.usedBankAccounts[0].value.includes('*')
            ? investor.usedBankAccounts[0].value : investor.bankAccount;
          const [subscriptionPaynlError, subscriptionData] = await to(
            bloqifyFunctions.httpsCallable('createPaynlSubscription')({
              investmentId: investmentRef.id,
              orderId: paymentRef.id,
              amount,
              bankAccountName: `${investor.name} ${investor.surname}`,
              bankAccountNumber,
              processDate: paymentDateTime,
              assetId,
            }),
          );
          if (subscriptionPaynlError) {
            throw subscriptionPaynlError;
          }

          const mandateId = subscriptionData!.data;

          // Doc writes first, so if api call fails, we can rollback.
          if (foundInvestments) {
            // Update investment
            transaction.update(investmentRef, {
              updatedDateTime: dateNow,
              [`subscriptions.${mandateId}`]: InvestmentSubscriptionStatus.Active,
            });
          } else {
            transaction.set(investmentRef, {
              updatedDateTime: dateNow,
              asset: assetRef,
              investor: investorRef,
              createdDateTime: dateNow,
              subscriptions: {
                [mandateId]: InvestmentSubscriptionStatus.Active,
              },
            });
          }

          payment.mandateId = mandateId;
          transaction.set(paymentRef, payment);
        }),
      );
      if (transactionError) {
        commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createSubscription' });
        return;
      }

      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Success, payload: { }, operation: 'createSubscription' });
    },
    async updateSubscription(
      { commit },
      { mandateId, formData, investmentId, paymentId }:
        { mandateId: string, formData: FormData, investmentId: string, paymentId: string },
      ): Promise<void> {
      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Processing, operation: 'updateSubscription' });

      const { modifySubDate: processDate, bankAccount, mandateBankAccount, sharesAmount, sharesPrice } = formData;

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
          const paymentRef = investmentRef.collection('payments').doc(paymentId);
          const newPaymentRef = investmentRef.collection('payments').doc();

          const [readPaymentError, readPaymentSuccess] = await to(transaction.get(paymentRef));
          if (readPaymentError || !readPaymentSuccess?.exists) {
            throw readPaymentError || Error('Payment not found.');
          }

          const payment = readPaymentSuccess!.data() as Payment;

          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          const { BigNumber } = await import('bignumber.js');
          const euroAmount = new BigNumber(sharesPrice).times(sharesAmount).toNumber();
          const finalPayment = {
            ...payment,
            provider: PaymentProvider.Custom,
            mandateId,
            // processDate: firebase.firestore.Timestamp.fromDate(moment(processDate, 'DD-MM-YYYY').toDate()),
            providerData: {
              ...payment.providerData,
              amount: {
                ...payment.providerData.amount,
                value: euroAmount.toFixed(2),
              },
              metadata: {
                ...payment.providerData.metadata,
                euroAmount,
                sharesAmount: Number(sharesAmount),
                paymentId: newPaymentRef.id,
              },
              status: PaymentStatus.Pending,
            },
            // @ts-ignore
            createdDateTime: dateNow,
            // @ts-ignore
            updatedDateTime: dateNow,
            trees: [],
            type: PaymentType.ModifiedSubscription,
          };
          delete finalPayment.paymentDateTime;

          transaction.set(
            newPaymentRef,
            finalPayment,
          );

          transaction.update(
            investmentRef,
            {
              updatedDateTime: dateNow,
            },
          );

          const [cancelSubError] = await to(
            bloqifyFunctions.httpsCallable('updateOrDeleteSubscription')({
              mandateId,
              assetId: payment.asset.id,
              formData: {
                amount: euroAmount,
                // processDate,
                ...(bankAccount !== mandateBankAccount && { bankAccount }),
              },
            }),
          );
          if (cancelSubError) {
            throw cancelSubError;
          }
        }),
      );
      if (transactionError) {
        commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateSubscription' });
        return;
      }

      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Success, payload: { }, operation: 'updateSubscription' });
    },
    async cancelSubscription(
      { commit },
      { mandateId, investmentId, assetId }:
        { mandateId: string, investmentId: string, assetId: string },
    ): Promise<void> {
      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Processing, operation: 'cancelSubscription' });

      const [transactionError] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);

          const dateNow = firebase.firestore.FieldValue.serverTimestamp();

          // Doc writes first, so if api call fails, we can rollback.
          transaction.update(investmentRef, {
            [`subscriptions.${mandateId}`]: InvestmentSubscriptionStatus.Cancelled,
            updatedDateTime: dateNow,
          });

          const [cancelSubError] = await to(
            bloqifyFunctions.httpsCallable('updateOrDeleteSubscription')({
              mandateId,
              assetId,
            }),
          );
          if (cancelSubError) {
            throw cancelSubError;
          }
        }),
      );
      if (transactionError) {
        commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Error, payload: transactionError, operation: 'cancelSubscription' });
        return;
      }

      commit(SET_SUBSCRIPTION, { status: DataContainerStatus.Success, payload: { }, operation: 'cancelSubscription' });
    },
  },
  getters: {},
};
