var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"loaders"}},[_vm._m(0),_c('div',{staticClass:"card"},[_vm._m(1),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(2)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Colors ")]),_c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Using Bootstrap's typical naming structure, you can create colored loaders depending on your needs. ")]),_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(4)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Loader helper ")]),_vm._m(5),_c('div',{staticClass:"card"},[_vm._m(6),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(7)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" FontAwesome loader ")]),_vm._m(8),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('font-awesome-icon',{attrs:{"icon":['fa', 'spinner'],"size":"lg","spin":""}})],1),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(9)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header mt-md-6"},[_c('div',{staticClass:"header-body"},[_c('h1',{staticClass:"header-title"},[_vm._v(" Loaders ")]),_c('p',{staticClass:"header-subtitle"},[_vm._v(" A simple yet versatile animated spinner component. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"loader loader-lg d-inline-block"}),_c('div',{staticClass:"loader d-inline-block ml-3"}),_c('div',{staticClass:"loader loader-sm d-inline-block ml-3"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"loader loader-lg\"></div>"),_c('br'),_vm._v(" <div class=\"loader\"></div>"),_c('br'),_vm._v(" <div class=\"loader loader-sm\"></div> ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"loader loader-primary d-inline-block"}),_c('div',{staticClass:"loader loader-secondary d-inline-block ml-3"}),_c('div',{staticClass:"loader loader-success d-inline-block ml-3"}),_c('div',{staticClass:"loader loader-danger d-inline-block ml-3"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"loader loader-primary\"></div>"),_c('br'),_vm._v(" <div class=\"loader loader-secondary\"></div>"),_c('br'),_vm._v(" <div class=\"loader loader-success\"></div>"),_c('br'),_vm._v(" <div class=\"loader loader-error\"></div>"),_c('br')])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Indicate loading state of nearly any component with an "),_c('code',[_vm._v(".is-loading")]),_vm._v(" modifier. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card-body"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" Loading card ")])]),_c('div',{staticClass:"card-body is-loading is-loading-lg"},[_vm._v(" Card content ")])]),_c('div',{staticClass:"text-center"},[_c('a',{staticClass:"btn btn-lg btn-primary is-loading",attrs:{"href":"#!"}},[_vm._v(" Button ")]),_c('a',{staticClass:"btn btn-white is-loading is-loading-sm",attrs:{"href":"#!"}},[_vm._v(" Button ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"card\">"),_c('br'),_vm._v("   <div class=\"card-header\">"),_c('br'),_vm._v("     <h4 class=\"card-header-title\">"),_c('br'),_vm._v("       Loading card"),_c('br'),_vm._v("     </h4>"),_c('br'),_vm._v("   </div>"),_c('br'),_vm._v("   <div class=\"card-body is-loading is-loading-lg\">"),_c('br'),_vm._v("     Card content"),_c('br'),_vm._v("   </div>"),_c('br'),_vm._v(" </div>"),_c('br'),_vm._v("                   "),_c('br'),_vm._v(" <a href=\"#!\" class=\"btn btn-lg btn-primary is-loading\">"),_c('br'),_vm._v("   Button"),_c('br'),_vm._v(" </a>"),_c('br'),_vm._v(" <a href=\"#!\" class=\"btn btn-white is-loading is-loading-sm\">"),_c('br'),_vm._v("   Button"),_c('br'),_vm._v(" </a> ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-4"},[_vm._v(" You can also use the Vue "),_c('a',{attrs:{"href":"https://fontawesome.com/icons?d=gallery","target":"_blank"}},[_vm._v("FontAwesome")]),_vm._v(" component to generate a spinning icon. ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <font-awesome-icon :icon=\"['fa', 'spinner']\" spin />"),_c('br'),_vm._v(" <font-awesome-icon :icon=\"['fa', 'spinner']\" spin v-if=\"loading\" /> ")])
}]

export { render, staticRenderFns }