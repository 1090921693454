
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import moment from 'moment';
  import { ModelSelect } from 'vue-search-select';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import ShortUniqueId from 'short-unique-id';
  import { DataContainerStatus } from '@/models/Common';
  import { State } from '@/models/State';
  import { bloqifyFirestore } from '@/boot/firebase';
  import { convertLocalDateToUTC, timestampToDate } from '@/filters/date';
  import FormInput, { FormIcons } from '@/components/common/form-elements/FormInput.vue';
  import FormDatePicker from '@/components/common/form-elements/FormDatePicker.vue';
  import { isInvestor, User } from '@/models/users/User';
  import { Payment, PaymentType } from '@/models/investments/Investment';
  import { Asset, AssetType } from '@/models/assets/Asset';
  import Modal from '@/components/common/Modal.vue';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

  type SelectOptions = { value: string, text: string };

  @Component({
    components: {
      ModelSelect,
      FormInput,
      FormDatePicker,
      FormInvalidMessage,
      ValidationObserver,
      ValidationProvider,
      Modal,
    },
  })
  export default class CreatePayment extends Vue {
    showModal = false;
    isProcessing = false;
    amount: number | null = null;
    FormIcons = FormIcons;
    dateOfPayment: Date | null = null;
    endDateOfPayment: Date | null = null;
    interestRate: number | null = null;
    inputGiftCode = '';
    selectedAsset: SelectOptions = {
      value: '',
      text: '',
    };
    selectedInvestor: SelectOptions = {
      value: '',
      text: '',
    };
    selectedPaymentType: SelectOptions = {
      value: '',
      text: '',
    };

    availablePaymentTypes = {
      'start-subscription': 'Subscription',
      'one-off': 'One-off',
      'gift-purchase': 'Gift Purchase',
      'gift-redeem': 'Gift Redeem',
    }

    paymentTypes = [
      { value: PaymentType.StartSubscription, text: this.availablePaymentTypes['start-subscription'] },
      { value: PaymentType.OneOff, text: this.availablePaymentTypes['one-off'] },
      { value: PaymentType.GiftPurchase, text: this.availablePaymentTypes['gift-purchase'] },
      { value: PaymentType.GiftRedeem, text: this.availablePaymentTypes['gift-redeem'] },
    ];

    // selectedDividendsFormatYear: [string, number] | null = null;
    loadingPromise: Promise<any> = Promise.resolve();
    selectedSharesAmount = 0;

    @Action(addToastMessage) addToastMessage!: Function;
    @Action resetFilters!: (data: { collection: string }) => void;
    @Action bindFirestoreReferences!: Function;
    @Action unbindFirestoreReferences!: Function;
    @Action createPayment!: (payment: any) => void;
    @Action updatePayment!: (payment: any) => void;
    @Action createGift!: (payment: any) => void;
    @Action createSubscription!: (payment: any) => void;
    @Action redeemGift!: (payment: any) => void;
    @Action updateSubscription!: (payment: any) => void;
    @Action updateGiftPurchase!: (payment: any) => void;

    @StateClass payment!: State['payment'];
    @StateClass gift!: State['gift'];
    @StateClass subscription!: State['subscription'];
    @StateClass identificationSettings!: State['identificationSettings'];
    @StateClass boundAssets!: State['boundAssets'];
    @StateClass boundInvestors!: State['boundInvestors'];
    @StateClass boundInvestment!: State['boundInvestment'];
    @StateClass boundPayment!: State['boundPayment'];

    mounted(): void {
      this.loadingPromise = this.bindFirestoreReferences([
        {
          name: 'boundAssets',
          ref: bloqifyFirestore.collection('assets')
            .where('deleted', '==', false)
            .orderBy('name', 'asc')
            .orderBy('createdDateTime', 'asc'),
        },
        {
          name: 'boundInvestors',
          ref: bloqifyFirestore.collection('investors')
            .orderBy('createdDateTime', 'asc'),
          options: {
            maxRefDepth: 1,
          },
        },
      ]);
    }

    beforeDestroy(): void {
      // Clear filters if we are leaving the 'investments' domain
      if (!this.$route.fullPath.includes('investments')) {
        this.resetFilters({ collection: 'investments' });
      }

      const unbindRefs = [
        { name: 'boundAssets' },
        { name: 'boundInvestors' },
      ];
      if (this.boundPayment) {
        unbindRefs.push({ name: 'boundPayment' });
        unbindRefs.push({ name: 'boundInvestment' });
      }

      this.unbindFirestoreReferences(unbindRefs);
    }

    // @Watch('selectedAsset')
    // onNewSelectedAsset(): void {
    //   this.selectedDividendsFormatYear = null;
    // }

    @Watch('payment.status')
    onNewCreatePaymentStatus(newStatus: DataContainerStatus, oldStatus: DataContainerStatus): void {
      if (newStatus === DataContainerStatus.Processing) {
        this.isProcessing = true;
        return;
      }

      if (newStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: `Payment correctly ${this.investmentId ? 'modified' : 'created'}`,
          type: 'success',
        });

        const { investmentId: newInvestmentId, paymentId: newPaymentId } = this.payment!.payload as any;

        if (!this.investmentId || this.investmentId !== newInvestmentId || this.paymentId !== newPaymentId) {
          this.$router.replace(`/investments/create-modify-payment/${newInvestmentId}/${newPaymentId}`);
        }

        this.showModal = false;
      } else if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.payment!.error?.message,
          type: 'danger',
        });
      }

      this.isProcessing = false;
    }

    @Watch('subscription.status')
    onNewCreateSubscriotionStatus(newStatus: DataContainerStatus): void {
      if (newStatus === DataContainerStatus.Processing) {
        this.isProcessing = true;
        return;
      }

      if (newStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: `Subscription correctly ${this.investmentId ? 'modified' : 'created'}`,
          type: 'success',
        });

        this.showModal = false;
      } else if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.subscription!.error?.message,
          type: 'danger',
        });
      }

      this.isProcessing = false;
    }

    @Watch('gift.status')
    onNewCreateGiftStatus(newStatus: DataContainerStatus): void {
      if (newStatus === DataContainerStatus.Processing) {
        this.isProcessing = true;
        return;
      }

      if (newStatus === DataContainerStatus.Success) {
        this.addToastMessage({
          text: `Gift${this.gift?.payload?.code ? ` with code ${this.gift?.payload?.code} ` : ' '}correctly
           ${this.investmentId ? 'modified' : this.gift?.payload?.claimInvestor ? 'redeemed' : 'created'}`,
          type: 'success',
        });

        this.showModal = false;
      } else if (newStatus === DataContainerStatus.Error) {
        this.addToastMessage({
          text: this.gift!.error?.message,
          type: 'danger',
        });
      }

      this.isProcessing = false;
    }

    @Watch('investmentId', { immediate: true })
    onIdChange(newId: string): void {
      if (newId && this.paymentId) {
        this.bindFirestoreReferences([
          {
            name: 'boundInvestment',
            ref: bloqifyFirestore.collection('investments').doc(newId),
          },
          {
            name: 'boundPayment',
            ref: bloqifyFirestore.collection('investments').doc(newId)
              .collection('payments').doc(this.paymentId),
          },
        ]);
      }
    }

    @Watch('boundPayment', { immediate: true })
    onNewPayment(newPayment: Payment | null): void {
      if (newPayment) {
        this.bindData(newPayment);
        this.selectedSharesAmount = newPayment.providerData.metadata.sharesAmount;
      }
    }

    @Watch('boundInvestment.investor.id', { immediate: true })
    onNewInvestorId(newId: string | undefined): void {
      if (newId) {
        this.selectedInvestor.value = newId;
      }
    }

    /* ONLY COMMENTING BECAUSE WITH COREKEES YOU NEVER KNOW */
    /* @Watch('selectedAssetObject.type')
    onSelectedAssetChange(newValue: string) {
      if (newValue === AssetType.Bamboo) {
        this.selectedPaymentType.value = PaymentType.OneOff;
      }
    } */

    @Watch('boundInvestment.asset.id', { immediate: true })
    onNewAssetId(newId: string | undefined): void {
      if (newId) {
        this.selectedAsset.value = newId;
      }
    }

    /**
    * Generate new gift code if the user wants to create a new gift purchase payment
    */
    @Watch('selectedPaymentType.value')
    onSelectedPaymentTypeChange(newValue: string) {
      if (newValue === PaymentType.GiftPurchase) {
        // @ts-ignore
        this.inputGiftCode = new ShortUniqueId({ length: 13, dictionary: 'alpha' }).randomUUID();
      } else {
        this.inputGiftCode = '';
      }
    }

    get investmentId(): string {
      return this.$route.params.investmentId;
    }

    get paymentId(): string {
      return this.$route.params.paymentId;
    }

    get isModifyPage(): boolean {
      return !!this.investmentId && !!this.paymentId;
    }

    get currentAvailablePaymentOptions(): any {
      return this.paymentTypes.filter(({ value }) => {
        if (this.selectedAssetObject?.checkoutPaymentOptions.subscription && value === 'start-subscription') {
          return true;
        }

        if (this.selectedAssetObject?.checkoutPaymentOptions.gift && (value === 'gift-purchase' || value === 'gift-redeem')) {
          return true;
        }

        if (this.selectedAssetObject?.checkoutPaymentOptions.oneOff && value === 'one-off') {
          return true;
        }

        return false;
      });
    }

    get dateOfPaymentRules(): any {
      /**
       * End Date Time.
       * @return Timestamp
       */
      const selectedAssetEndDateTime = (): number | undefined => {
        if (this.selectedAssetObject?.endDateTime !== undefined) {
          return timestampToDate(this.selectedAssetObject?.endDateTime)?.getTime();
        }
        return 0;
      };
      /**
       * Start Date.
       * @return Timestamp
       */
      const selectedAssetStartDateTime = (): number | undefined => {
        if (this.selectedAssetObject?.startDateTime !== undefined) {
          return timestampToDate(this.selectedAssetObject?.startDateTime)?.getTime();
        }
        return 0;
      };
      const timeStampDateOfPayment = (): number | undefined => this.dateOfPayment?.getTime();

      return {
        required: true,
        aftereqtimestamp: {
          // For subs, date needs to be +1 day or later
          baseDate: !this.isSubscription ? selectedAssetStartDateTime() : moment().add(1, 'days').valueOf(),
          otherDate: timeStampDateOfPayment(),
        },
        beforeeqtimestamp: {
          // For one-off, gift-redeem and gift-purchase, date needs to be today or earlier (can't be in the future)
          baseDate: this.isSubscription ? selectedAssetEndDateTime() : Date.now(),
          otherDate: timeStampDateOfPayment(),
        },
      };
    }

    get isModifiable(): boolean {
      return this.isModifyPage && this.isOneOff;
    }

    get selectedAssetObject(): Asset | undefined {
      return this.boundAssets.find((asset): boolean => asset.id === this.selectedAsset.value);
    }

    get selectedInvestorObject(): User | undefined {
      return this.boundInvestors.find((investor): boolean => investor.id === this.selectedInvestor.value);
    }

    get assetOptions(): SelectOptions[] {
      return this.boundAssets.map((asset): SelectOptions => ({ value: asset.id!, text: asset.name.en }));
    }

    get investorOptions(): SelectOptions[] {
      return this.boundInvestors.filter(isInvestor).map((investor): SelectOptions => ({
        value: investor.id!,
        text: `#${investor.customId} ${investor.surname}`,
      }));
    }

    get isPremiumSelected(): boolean {
      return !!this.selectedAssetObject?.premium;
    }

    /**
     * Generating a select array of options depending on if there are any available slots.
     * This would mean the asset has been already closed.
     */
    // get dividendsReturnsOptions(): [string, number][] {
    //   return (this.selectedAssetObject && this.selectedAssetObject.dividendsFormat.map((dF): [string, number] => dF.contents)) || [];
    // }

    get saveLoading(): boolean {
      return this.payment?.status === DataContainerStatus.Processing || this.gift?.status === DataContainerStatus.Processing;
    }

    get isGift(): boolean {
      return this.selectedPaymentType.value === PaymentType.GiftRedeem || this.selectedPaymentType.value === PaymentType.GiftPurchase;
    }

    /* ONLY COMMENTING BECAUSE WITH COREKEES YOU NEVER KNOW */
    /* get isBambooAsset(): boolean {
      return this.selectedAssetObject?.type === AssetType.Bamboo;
    } */

    get isRedeemGift(): boolean {
      return this.selectedPaymentType.value === PaymentType.GiftRedeem;
    }

    get isSubscription(): boolean {
      return this.selectedPaymentType.value === PaymentType.StartSubscription;
    }

    get isOneOff(): boolean {
      return this.selectedPaymentType.value === PaymentType.OneOff;
    }

    bindData(payment: Payment): void {
      this.amount = payment.providerData.metadata.euroAmount;
      // this.selectedDividendsFormatYear = payment.dividendsFormat;

      const paymentTimestamp = (payment.paymentDateTime || payment.createdDateTime);
      this.dateOfPayment = paymentTimestamp.toDate();

      this.endDateOfPayment = payment.endDateTime?.toDate() || null;
      if (payment.type) {
        this.selectedPaymentType = { value: payment.type, text: this.availablePaymentTypes[payment.type] || payment.type };
      }
      // this.interestRate = payment.dividendsFormat[1] || null;
    }

    // Reseting a selected field
    resetSelect(type: string): void {
      this[type] = {
        value: '',
        text: '',
      };
    }

    submitPayment(): void {
      // TODO: add extra validation before submitting
      let paymentDate: string | number | undefined;
      if (this.dateOfPayment) {
        if (this.selectedPaymentType.value === PaymentType.StartSubscription) {
          paymentDate = moment(this.dateOfPayment).format('DD-MM-YYYY');
        } else {
          paymentDate = convertLocalDateToUTC(this.dateOfPayment, true)!.getTime();
        }
      }

      const payment: { [key: string]: any } = {
        amount: Number(this.amount),
        assetId: this.selectedAsset.value,
        investorId: this.selectedInvestor.value,
        insertedSharesAmount: Number(this.selectedSharesAmount),
        ...paymentDate && { paymentDateTime: paymentDate },
        // The following fields will only be submitted if it is a payment modification
        ...(this.investmentId && this.paymentId && {
          investmentId: this.investmentId,
          paymentId: this.paymentId,
        }),
        ...(this.selectedAssetObject?.premium && this.endDateOfPayment && { endDateTime: convertLocalDateToUTC(this.endDateOfPayment) }),
        type: this.selectedPaymentType.value,
        ...(this.selectedAssetObject?.premium && {
          type: 'loan',
        }),
        ...(this.isGift) && {
          inputGiftCode: this.inputGiftCode,
        },
      };

      // It needs to be checked if this condition is necessary or a premium fund implies non-fixed dividends
      // if (!this.selectedAssetObject?.fixedDividends && !this.selectedAssetObject?.premium) {
      //   payment.dividendsFormat = this.selectedDividendsFormatYear;
      // }

      // if (this.selectedAssetObject?.premium) {
      //   // Naive way to caclulate years
      //   const years = (this.endDateOfPayment as Date).getFullYear() - (this.dateOfPayment as Date).getFullYear();
      //   payment.dividendsFormat = [years.toString(), Number(this.interestRate)];
      // }

      // Update actions
      if (this.investmentId && this.paymentId) {
        switch (this.selectedPaymentType.value) {
          case PaymentType.GiftRedeem:
            this.updateGiftPurchase(payment);
            break;
          case PaymentType.StartSubscription:
            this.updateSubscription(payment);
            break;
          default:
            this.updatePayment(payment);
        }
        return;
      }

      // Create actions
      switch (this.selectedPaymentType.value) {
        case PaymentType.GiftRedeem:
          this.redeemGift(payment);
          break;
        case PaymentType.GiftPurchase:
          this.createGift(payment);
          break;
        case PaymentType.StartSubscription:
          this.createSubscription(payment);
          break;
        default:
          this.createPayment(payment);
      }
    }
  }
