
  import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
  import { Action, State as StateClass } from 'vuex-class';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import { State } from '@/models/State';
  import { DataContainerStatus } from '@/models/Common';
  import { firebase } from '@/boot/firebase';
  import FormInput from '@/components/common/form-elements/FormInput.vue';
  import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';

  @Component({
    components: {
      FormInput,
      ValidationProvider,
      ValidationObserver,
      FormInvalidMessage,
    },
  })
  export default class Login extends Vue {
    email: string = '';
    password: string = '';
    loginError: string | null | undefined = null;
    loginFormType: 'normal' | 'phoneNumber' | 'smsCode' = 'normal';
    phoneNumber: string = '';
    smsCode: string = '';
    calledBy: 'loginStepTwo' | 'enroll' = 'loginStepTwo';

    @Action logOut!: ({ redirect }: { redirect?: string }) => {};
    @Action logIn!: ({ email, password }: { email: string, password: string }) => {};
    @Action loginStepTwo!: ({ appVerifier }: { appVerifier: firebase.auth.RecaptchaVerifier_Instance }) => {};
    @Action loginStepThree!: ({ multiFactorAssertion }: { multiFactorAssertion: firebase.auth.MultiFactorAssertion }) => {};
    @Action enrollSecondFactor!: ({ phoneNumber, appVerifier }:
      { phoneNumber: string, appVerifier: firebase.auth.RecaptchaVerifier_Instance }) => {};
    @Action enrollSecondFactorStepTwo!: ({ multiFactorAssertion }: { multiFactorAssertion: firebase.auth.MultiFactorAssertion }) => {};
    @Action verifyPhoneSMS!: ({ verificationId, verificationCode, calledBy }:
      { verificationId: string, verificationCode: string, calledBy: string }) => {};

    @StateClass currentManager!: State['currentManager'];
    @StateClass login!: State['login'];
    @StateClass verifySMS!: State['verifySMS'];

    @Ref('smsCode') readonly smsCodeRef!: HTMLInputElement | undefined;
    @Ref('smsCodeValidation') readonly smsCodeValidationRef!: any;
    @Ref('phoneNumber') readonly phoneNumberRef!: HTMLInputElement | undefined;
    @Ref('phoneNumberValidation') readonly phoneNumberValidationRef!: any;
    @Ref('form') readonly form!: HTMLFormElement;

    mounted(): void {
      // @ts-ignore
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        'recaptcha-container',
        {
          size: 'invisible',
        },
      );
    }

    /**
     * Watcher that will handle the focus in the different forms
     */
    @Watch('loginFormType')
    async onLoginFormTypeChanged() {
      // Since our targeted components are playing with virtual DOM (v-if / else), Vue hasn't created the reference yet
      // Another way to do this would be using v-show instead (display none)
      await this.$nextTick();
      const ref = this.smsCodeRef || this.phoneNumberRef;
      if (ref) {
        ref.focus();
      }
      const refValidation = this.smsCodeValidationRef || this.phoneNumberValidationRef;
      await this.$nextTick();
      refValidation!.reset();
    }

    /**
     * Handling multifactor sign in
     */
    @Watch('login.status')
    onLoginChanged(status: DataContainerStatus): void {
      this.loginError = null;

      const newLogin = this.login;
      const error = newLogin.error as firebase.auth.Error;
      switch (newLogin.name) {
        case 'login':
          if (error?.code === 'auth/multi-factor-auth-required') {
            this.loginStepTwo({
              // @ts-ignore
              appVerifier: window.recaptchaVerifier,
            });
          } else if (error?.code === 'auth/requires-recent-login') {
            this.loginFormType = 'normal';
          } else if (newLogin.status === DataContainerStatus.Success) {
            this.loginFormType = 'phoneNumber';
          } else if (newLogin.status === DataContainerStatus.Error) {
            // @ts-ignore
            window.recaptchaVerifier.reset();
          }
          break;
        case 'loginStepTwo':
          if (newLogin.status === DataContainerStatus.Success) {
            this.loginFormType = 'smsCode';
            this.calledBy = 'loginStepTwo';
          }

          break;
        case 'loginStepThree':
          if (newLogin.status === DataContainerStatus.Success) {
            this.$router.replace('/dashboard');
          } else if (error?.code === 'auth/invalid-verification-code') {
            // @ts-ignore
            window.recaptchaVerifier.reset();
            this.loginFormType = 'normal';
            this.smsCode = '';
          }

          break;
        case 'enroll':
          if (newLogin.status === DataContainerStatus.Success) {
            this.loginFormType = 'smsCode';
            this.calledBy = 'enroll';
          }

          break;
        case 'enrollStepTwo':
          if (newLogin.status === DataContainerStatus.Success) {
            // @ts-ignore
            window.recaptchaVerifier.reset();
            this.logOut({});
            this.loginFormType = 'normal';
            this.loginError = 'Your two factor authentication has been correctly set up. Please log in.';
          } else if (error?.code === 'auth/invalid-verification-code') {
            // @ts-ignore
            window.recaptchaVerifier.reset();
            this.loginFormType = 'phoneNumber';
          }
          this.smsCode = '';

          break;
        default:

          break;
      }

      if (error) {
        this.loginError = error.message;
      }
    }

    /**
     * Handles the sms verification for the multifactor
     */
    @Watch('verifySMS', { deep: true })
    onVerificationSMSChanged(newVerification: State['verifySMS'], oldVerification: State['verifySMS']): void {
      if (newVerification.status === DataContainerStatus.Success) {
        if (newVerification.payload.calledBy === 'enroll') {
          this.enrollSecondFactorStepTwo({
            multiFactorAssertion: newVerification.payload.multiFactorAssertion,
          });
          return;
        }
        this.loginStepThree({
          multiFactorAssertion: newVerification.payload.multiFactorAssertion,
        });
      } else if (newVerification.status === DataContainerStatus.Error) {
        // @ts-ignore
        window.recaptchaVerifier.reset();
        this.loginError = 'There was a problem with the SMS step, please try again.';
      }
    }

    get loading(): boolean {
      return this.login.status === DataContainerStatus.Processing || this.verifySMS.status === DataContainerStatus.Processing;
    }

    get loginButtonText(): string {
      switch (this.loginFormType) {
        case 'phoneNumber':
          return 'Enroll Phone';
        case 'smsCode':
          return 'Verify SMS';
        default:
          return 'Login';
      }
    }

    get subtitleText(): string {
      switch (this.loginFormType) {
        case 'phoneNumber':
          return 'Enroll phone number for the Two Factor Authentication.';
        case 'smsCode':
          return 'Two Factor Authentication.';
        default:
          return 'Access your Bloqadmin.';
      }
    }

    /**
     * Hidding number as Firebase does when the phone is already enrolled.
     */
    get hiddenPhoneNumber(): string {
      if (!this.phoneNumber) {
        return this.phoneNumber;
      }

      const hiddenLength = this.phoneNumber.substring(1, this.phoneNumber.length - 4).length;
      return `+${'*'.repeat(hiddenLength)}${this.phoneNumber.substring(this.phoneNumber.length - 4, this.phoneNumber.length)}`;
    }

    get sendButtonEnabled(): boolean {
      if (this.loginFormType === 'normal') {
        return !!this.email && !!this.password;
      }

      if (this.loginFormType === 'phoneNumber') {
        return !!this.phoneNumber;
      }

      return !!this.smsCode;
    }

    /**
     * Handles the form submitting flow
     */
    formSubmit(): void {
      if (this.loginFormType === 'normal') {
        this.logIn({ email: this.email, password: this.password });
        return;
      }

      if (this.loginFormType === 'phoneNumber') {
        this.enrollSecondFactor({
          phoneNumber: this.phoneNumber,
          // @ts-ignore
          appVerifier: window.recaptchaVerifier,
        });
        return;
      }

      this.verifyPhoneSMS({
        verificationId: this.login.payload.verificationId,
        verificationCode: this.smsCode,
        calledBy: this.calledBy,
      });
    }
  }
