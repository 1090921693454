var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"utilities"}},[_vm._m(0),_c('h2',{staticClass:"mb-2"},[_vm._v(" Backgrounds ")]),_vm._m(1),_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-body bg-dark"},[_vm._m(2)])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Sizing ")]),_vm._m(3),_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-body bg-dark"},[_c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"vw-100 vh-100\">...</div> ")])])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Borders ")]),_vm._m(4),_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-body bg-dark"},[_c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"border border-2 border-white\">...</div> ")])])]),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Margins ")]),_c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Similar to the default Bootstrap margin utilities, use negative margin utilities to move elements in either direction. These utilities follow Bootstrap's default utilities, including the use of responsive variants. ")]),_c('div',{staticClass:"card"},[_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-body bg-dark"},[_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header mt-md-6"},[_c('div',{staticClass:"header-body"},[_c('h1',{staticClass:"header-title"},[_vm._v(" Utilities ")]),_c('p',{staticClass:"header-subtitle"},[_vm._v(" Please visit the "),_c('a',{attrs:{"target":"_blank","href":"http://getbootstrap.com/docs/4.1/utilities/borders/"}},[_vm._v("official Bootstrap documentation")]),_vm._v(" for a full list of utilities. Additionally, the following utilities have been added: ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"text-muted mb-4"},[_c('li',[_c('code',[_vm._v(".bg-fixed-bottom")]),_vm._v(" to fix the background image at the bottom of the "),_c('code',[_vm._v(".main")]),_vm._v(" container at the "),_c('a',{attrs:{"href":"feed.html"}},[_vm._v("feed.html")]),_vm._v(" page. ")]),_c('li',[_c('code',[_vm._v(".bg-cover")]),_vm._v(" to set the "),_c('code',[_vm._v("background-image")]),_vm._v(" size of an element to "),_c('code',[_vm._v("cover")]),_vm._v(". ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"bg-fixed-bottom\" style=\"background-image: url(assets/img/illustrations/sticky.svg);\">...</div>"),_c('br'),_vm._v(" <div class=\"bg-cover\" style=\"background-image: url(...);\"></div> ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"text-muted mb-4"},[_c('li',[_c('code',[_vm._v(".vw-100")]),_vm._v(" to change the width of an element to "),_c('code',[_vm._v("100vw")]),_vm._v(". ")]),_c('li',[_c('code',[_vm._v(".vh-100")]),_vm._v(" to change the height of an element to "),_c('code',[_vm._v("100vh")]),_vm._v(". ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"text-muted mb-4"},[_c('li',[_c('code',[_vm._v(".border-2(3, 4, 5), .border-top-2(3, 4, 5), .border-right-2(3, 4, 5), .border-bottom-2(3, 4, 5), .border-left-2(3, 4, 5)")]),_vm._v(" to increase the width of a border. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <div class=\"mt--1\">...</div>"),_c('br'),_vm._v(" <div class=\"mr-sm--2\">...</div>"),_c('br'),_vm._v(" <div class=\"mb-md--3\">...</div>"),_c('br'),_vm._v(" <div class=\"ml-lg--4\">...</div> ")])
}]

export { render, staticRenderFns }