
  import { Component, Prop, Vue } from 'vue-property-decorator';
  import { Action } from 'vuex-class';
  // @ts-ignore
  import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import FileSaver from 'file-saver';
  import Modal from '@/components/common/Modal.vue';
  import { firebaseAxiosInstance } from '@/boot/axios';
  import { bloqifyFirestore } from '@/boot/firebase';
  import to from 'await-to-js';
  import { PaymentStatus } from '@/models/investments/Investment';

  @Component({
    components: {
      Modal,
    },
  })
  export default class DownloadPDFModal extends Vue {
    @Prop() user!: any;

    @Action(addToastMessage) addToastMessage!: Function;

    currentYear: number = new Date().getFullYear();
    selectedYear: number = this.currentYear;
    firstPaymentsYear: number | null = null

    selectedLang: 'nl' | 'en' = 'nl';
    availableLangs = ['nl', 'en'];

    // Determines the number of years shown in the tax statement year selector
    yearsToShow: number = 3;
    isProcessing = false;

    async mounted() {
      const [getInvestorError, getInvestorSuccess] = await to(
        bloqifyFirestore.collection('investors').doc(this.user.id).get(),
      );

      if (getInvestorError) {
        this.addToastMessage({
          text: 'Something went wrong while fetching the investor',
          type: 'danger',
        });
      }

      const [getFirstPaymentError, getFirstPaymentSuccess] = await to(
        bloqifyFirestore.collectionGroup('payments')
          .where('investor', '==', getInvestorSuccess?.ref)
          .where('deleted', '==', false)
          .get(),
      );

      if (getFirstPaymentError) {
        this.addToastMessage({
          text: 'Something went wrong while fetching payments',
          type: 'danger',
        });
      }

      const payments = getFirstPaymentSuccess?.docs;

      const firstPayment = payments?.length
        ? payments
          .map((payment) => payment.data())
          .filter(({ type, providerData }): boolean => type !== 'gift-purchase' && providerData.status === PaymentStatus.Paid)
          .sort((a, b): number => (a.paymentDateTime || a.createdDateTime).toMillis() - (b.paymentDateTime || b.createdDateTime).toMillis())[0]
        : null;

      this.firstPaymentsYear = firstPayment?.paymentDateTime?.toDate().getFullYear();
    }

    /**
     * Get the list of years for which the tax statement can be downloaded
     */
    get years(): number[] {
      const currentYear = new Date().getFullYear();

      if (!this.firstPaymentsYear) {
        return [];
      }

      const yearsToShow = currentYear - this.firstPaymentsYear;

      const yearsArray = Array.from({ length: yearsToShow }, (v, i): number => currentYear - i);

      if (yearsArray.length) {
        this.selectedYear = yearsArray[0];
      }

      return yearsArray;
    }

    async downloadPDF() {
      this.isProcessing = true;
      const instance = await firebaseAxiosInstance();

      try {
        const res = await instance.get(
          'generateDividendsPDF',
          {
            params: {
              year: this.selectedYear,
              userId: this.user.id,
              lang: this.selectedLang,
            },
            responseType: 'blob',
          },
        );

        FileSaver.saveAs(res.data, `statement_${this.selectedYear}_${this.user.name}_${this.user.surname}.pdf`);
      } catch (err) {
        // As the error will be of type Blob we need to parse it to text first
        // see https://developer.mozilla.org/en-US/docs/Web/API/Blob/text
        let errorText;

        try {
          errorText = await err.response.data.text();
        } catch (e) {
          errorText = 'There was a problem generating the PDF.';
        }

        this.addToastMessage({
          text: errorText,
          type: 'danger',
        });
      }

      this.isProcessing = false;
    }
  }
