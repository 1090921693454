var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"navbar"}},[_vm._m(0),_vm._m(1),_c('hr',{staticClass:"my-5"}),_c('h2',{staticClass:"mb-2"},[_vm._v(" Vertical ")]),_vm._m(2),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('nav',{staticClass:"navbar navbar-vertical fixed-left navbar-expand-sm navbar-light",staticStyle:{"position":"relative","border-width":"0"}},[_vm._m(3),_c('a',{staticClass:"navbar-brand",attrs:{"href":"index.html"}},[_c('Logo',{staticClass:"navbar-brand-img mx-auto"})],1),_vm._m(4)])]),_c('div',{directives:[{name:"highlightjs",rawName:"v-highlightjs"}],staticClass:"card-footer bg-dark"},[_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"header mt-md-6"},[_c('div',{staticClass:"header-body"},[_c('h1',{staticClass:"header-title"},[_vm._v(" Navbar ")]),_c('p',{staticClass:"header-subtitle"},[_vm._v(" Powerful and responsive navigation header, the navbar. Includes support for branding, navigation, and more, including support for the collapse plugin. Please read the "),_c('a',{attrs:{"target":"_blank","href":"http://getbootstrap.com/docs/4.1/components/navbar/"}},[_vm._v("official Bootstrap documentation")]),_vm._v(" for a full list of options. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('nav',{staticClass:"navbar navbar-expand-xl navbar-light",staticStyle:{"border":"none"}},[_c('div',{staticClass:"container-fluid"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"#!"}},[_vm._v("Navbar")]),_c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item active"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v("Home "),_c('span',{staticClass:"sr-only"},[_vm._v("(current)")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"#!"}},[_vm._v("Link")])]),_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#!","id":"navbarDropdown","role":"button","data-toggle":"dropdown","aria-haspopup":"true","aria-expanded":"false"}},[_vm._v(" Dropdown ")]),_c('div',{staticClass:"dropdown-menu",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#!"}},[_vm._v("Action")]),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#!"}},[_vm._v("Another action")]),_c('div',{staticClass:"dropdown-divider"}),_c('a',{staticClass:"dropdown-item",attrs:{"href":"#!"}},[_vm._v("Something else here")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link disabled",attrs:{"href":"#!"}},[_vm._v("Disabled")])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('p',{staticClass:"text-muted mb-4"},[_vm._v(" Creates a vertical variation of the navbar component. Either the "),_c('code',[_vm._v(".fixed-left")]),_vm._v(" or "),_c('code',[_vm._v(".fixed-right")]),_vm._v(" modifier should be added to position the navbar along the left or right side of the viewport. The markup for everything else remains the same as the default navbar! ")])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarVertical","aria-controls":"navbarVertical","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarVertical"}},[_c('ul',{staticClass:"navbar-nav mb-md-3"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"getting-started.html"}},[_c('i',{staticClass:"fe fe-clipboard"}),_vm._v(" Getting started ")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"components.html"}},[_c('i',{staticClass:"fe fe-book-open"}),_vm._v(" Components "),_c('span',{staticClass:"badge badge-primary ml-auto"},[_vm._v("23")])])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"href":"changelog.html"}},[_c('i',{staticClass:"fe fe-git-branch"}),_vm._v(" Changelog ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('code',{staticClass:"highlight html bg-dark"},[_vm._v(" <nav class=\"navbar navbar-vertical fixed-left navbar-expand-md navbar-light\">"),_c('br'),_vm._v("   ..."),_c('br'),_vm._v(" </nav> ")])
}]

export { render, staticRenderFns }